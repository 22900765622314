import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CContainer,
} from '@coreui/react'
import styled from 'styled-components'
import CardContent from './CardContent';
import ModalContent from '../../../hooks/ModalContent'
import api from '../../../api/api'

export default function TablaArtesRegistradas({
  user,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat,
  idiomas,
}) {
    //Variables
    const navigate = useNavigate();
    const showModal = useRef(); //referencia del hijo

    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    //configuracion de busqueda, filtrado y paginacion
    const [dataConfig, setDataConfig] = useState({
      searchTerm: locationData?.dataConfig?.searchTerm ? locationData?.dataConfig?.searchTerm : "",
      filter: locationData?.dataConfig?.filter ? locationData?.dataConfig?.filter : 0,
      itemsPerPage: locationData?.dataConfig?.itemsPerPage ? locationData?.dataConfig?.itemsPerPage : 6,
      cantRegistros: 0,
      currentPage: 1,
      lastPage: 1,
      minPageNumberLimit: locationData?.dataConfig?.minPageNumberLimit ? locationData?.dataConfig?.minPageNumberLimit : 0,
      maxPageNumberLimit: locationData?.dataConfig?.maxPageNumberLimit ? locationData?.dataConfig?.maxPageNumberLimit : 5,
      //estos valores nunca se actualizan para saber el minimo y maximo
      pageNumberLimit: 5,
      pageMinNumber: 0,

    });
    //con esta variable validamos si el CU se esta abriendo por primera vez
    const [inicio, setInicio] = useState(true);
    const [loadingData, setLoadingData] = useState(true);
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [itemSeleccionado, setItemSeleccionado] = useState(null); //el item que se selecciona y se elimina

    //arreglo que almacena las artes
    const [data, setData] = useState([]);

    //funciones

    //Con esta funcion escuchamos los cambios en los filtros
    const handleChangeFiltros = (e) => {
      //Cuando se coloca algo en el buscador
      if (e.target.name === "search") {
        if (e.target.value === "") {
          setDataConfig({...dataConfig, searchTerm: ""})
        } else {
          setDataConfig({...dataConfig, searchTerm: e.target.value})
        }
      }
    }

    //Con esta funcion obtenemos las artes
    const getData = (inicio = true, page = 1, maxPageNumberLimit=null, minPageNumberLimit=null) => {
      window.scrollTo(0, 0);
      setLoadingData(true)
      let newDataConfig;
      let sr = dataConfig
      // Esta es la variable que almacenara los parametros que enviaremos al endpoint
      let obj = {
        page: page,
        page_size: sr.itemsPerPage,
        indice_idioma: parseInt(language),
      };
      let terms;
      //si hay busqueda le agregamos los parametros de busqueda
      if (sr.searchTerm !=="") {
        terms = {
          ...obj,
          nombre: sr.searchTerm,
        };
      } else {
        terms = obj
      }
      const newConfig = {...config, params: terms}
      api.get(`/get_artes`, newConfig).then(response => {
        newDataConfig = {
          ...dataConfig,
          cantRegistros: response.data[1].total,
          lastPage: response.data[1].last_page,
          indice_idioma: parseInt(language),
          currentPage: page,
          maxPageNumberLimit: parseInt(maxPageNumberLimit)>=0 ? maxPageNumberLimit : dataConfig.maxPageNumberLimit, 
          minPageNumberLimit: parseInt(minPageNumberLimit)>=0 ? minPageNumberLimit : dataConfig.minPageNumberLimit,
        }
        setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
        setLoadingData(false)
        setData(response.data[0])
        if(inicio){
          setInicio(false)
        }
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break;
          case 404:
            newDataConfig = {
              ...dataConfig,
              cantRegistros: 0,
              lastPage: 1,
              currentPage: 1,
              indice_idioma: parseInt(language),
              maxPageNumberLimit: dataConfig.pageNumberLimit, 
              minPageNumberLimit: dataConfig.pageMinNumber,
            }
            setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
            setLoadingData(false)
            setData([])
            if(inicio){
              setInicio(false)
            }         
          break;
          default:
            break;
        }
      })
    }


    //con esto vamos a la vista para editamos un arte
    const goToEditarItem = (item, indice_idioma=1) => {
      let registered = item?.indice_idioma_artes?.length > 0 ? item?.indice_idioma_artes?.includes(indice_idioma) : false
      let dataCache = {
        id_arte: item.id_arte,
        indice_idioma: parseInt(indice_idioma),
        getData: true,
        oldDataCache: {
          dataConfig: dataConfig,
        }
      }
      let ruta = registered ? '/admin-editar-arte' : '/admin-nuevo-arte'
      navigate(ruta, { state: { dataCache: dataCache}})
    }

    //con esto vamos a la vista para crear un arte
    const goToAgregarArte = () => {
      navigate('/admin-nuevo-arte')
    }


    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          deleteItem(itemSeleccionado)
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //confirmamos que quieran eliminar al artista
    const handleDeleteItem = (id) => {
      setItemSeleccionado(id)
      setAcceptAction(1)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_confirm_eliminar_arte ? translation[language]?.msg_confirm_eliminar_arte : "¿Estás seguro de eliminar este Arte?"}`, //mensaje principal
        `${translation[language]?.msg_confirm_eliminar_arte2 ? translation[language]?.msg_confirm_eliminar_arte2 : "Si aceptas, estarás eliminando para todos los idiomas, las piezas de este Arte así como del historial de Propietarios."}`, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
    }

     //Con esto eliminamos un artista
    const deleteItem = (id) => {
      showModal?.current?.content.loading()
      let newObj = {
        artes: [{
          id_arte: id
        }]
      }// esto es lo que enviaremos al endpoint
      const newConfig = {...config, data: newObj} // config es el headers del endpoint (token) y se le añade la data 
      api.delete("/delete_arte", newConfig).then(response => {
        getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        setItemSeleccionado(null)
        showModal?.current?.content?.success(
          null, //accion que tomara 
          `${translation[language]?.msg_eliminado_sastifactoriamente ? translation[language]?.msg_eliminado_sastifactoriamente : "¡Eliminado sastifactoriamente!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );  
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }  

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      let page = (parseInt(locationData?.dataConfig?.indice_idioma) === parseInt(language)) ? locationData?.dataConfig?.currentPage : 1
      getData(true, page)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //con este efecto llamamos a la funcion para obtener la data al momento de cambiar de pagina, de numero de mostrar o filtrado
    useEffect(()=> {
      if(!inicio){
      //Si este parametro no esta vacio significa que el usuario escribio algo, se espera 1 segundo para buscar
        if(dataConfig.searchTerm !==""){
          setLoadingData(true)
          const delayDebounceFn = setTimeout(() => {
            getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
          }, 1000);
          return () => clearTimeout(delayDebounceFn);
        }else{
          getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataConfig.searchTerm, dataConfig.itemsPerPage, dataConfig.filter])

    //con este efecto llamamos a la funcion para obtener la data al momento de cambiar de idioma
    useEffect(()=> {
      if(!inicio){
        getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])


    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CardContent
                      data={data}
                      dataConfig={dataConfig} // configuracion del cu, almacenamos paginacion, filtrado, busqueda, etc
                      getData={getData}
                      loadingData={loadingData}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      goToEditarItem={goToEditarItem}
                      handleDeleteItem={handleDeleteItem}
                      goToAgregarArte={goToAgregarArte}
                      //traducciones
                      translation={translation}
                      language={language}    
                      returnLanguageDateFormat={returnLanguageDateFormat}           
                      idiomas={idiomas}       
                    />                             
                  </CContainer>
                </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`