import React from "react";
import {
  CRow,
  CCol,
  CTooltip,
  CButton
} from '@coreui/react'
import styled from 'styled-components'
import pencilIcon from '../../../icons/pencil.svg'
import borrarIcon from '../../../icons/Icono borrar - dorado.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { globalFunctions } from "../../../hooks/globalFunctions";

export default function ArtListRows({
  item,
  index,
  total,
  goToEditarItem,
  handleDeleteItem,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat,
  idiomas
}) {

    const { 
      returnClassOrMessageLanguage
    } = globalFunctions();

    return (
            <Content>
              <div className={`bg-color d-xl-none`}> 
                  <CRow className="p-2 pl-3">               
                      <CCol className="text-white" xs="12">
                        {item.nombre ? item.nombre : ""}
                      </CCol>
                  </CRow>
              </div> 
              <CRow className="py-2 px-2 px-sm-3 d-flex align-items-center">
                <CCol xl={2} className="border-col">
                  <div className="box big">
                    <LazyLoadImage
                      alt={"img"}
                      effect="blur" 
                      className='foto'
                      placeholderSrc={item?.imagenes.length > 0 ? `${(item.imagenes?.find(item => (item.boo_favorita && item.tipo === 1))?.imagen ? item.imagenes?.find(item => (item.boo_favorita && item.tipo === 1))?.imagen : (item.imagenes?.find(item => (item.tipo === 1))?.imagen ? item.imagenes?.find(item => (item.tipo === 1))?.imagen : "/sin-imagen.png") ) }` : "/sin-imagen.png"}
                      src={item?.imagenes.length > 0 ? `${(item.imagenes?.find(item => (item.boo_favorita && item.tipo === 1))?.imagen ? item.imagenes?.find(item => (item.boo_favorita && item.tipo === 1))?.imagen : (item.imagenes?.find(item => (item.tipo === 1))?.imagen ? item.imagenes?.find(item => (item.tipo === 1))?.imagen : "/sin-imagen.png") ) }` : "/sin-imagen.png"}
                    /> 
                  </div>
                </CCol>
                <CCol xl={10}>
                  <CRow>
                    <div className="col-xl-3-art d-none d-xl-block">
                      <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.nombre ? translation[language]?.nombre : "Nombre"}`}: </span>
                      {item.nombre ? item.nombre : ""}
                    </div>
                    <div className="col-xl-2-art">
                      <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.fecha_creacion ? translation[language]?.fecha_creacion : "Fecha de creación"}`}: </span>
                      {item.fecha_creacion ? returnLanguageDateFormat(item.fecha_creacion, "P") : ""}
                    </div> 
                    <div className="col-xl-2-art ps-xl-0">
                      <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.nombre_coleccion ? translation[language]?.nombre_coleccion : "Nombre de colección"}`}: </span>
                      {item?.serie[0]?.nombre? item?.serie[0]?.nombre : `${translation[language]?.hint_na ? translation[language]?.hint_na : 'NA'}`}
                    </div>             
                    <div className="col-xl-2-art">
                      <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.cantidad_piezas ? translation[language]?.cantidad_piezas : "Cantidad de piezas"}`}: </span>
                      {item.cantidad_piezas ? item.cantidad_piezas : ""}
                    </div>       
                    <div className="col-xl-1-art d-xl-flex justify-content-end">
                       <div className="me-0 mb-2 mb-xl-0 me-xl-1">
                          <span  className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}: </span>
                          <CTooltip content={`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}>
                            <img 
                              className='icon-border' 
                              src={pencilIcon}  
                              alt="icon"
                              width="25px"
                              height="25px"
                              onClick={()=> goToEditarItem(item, language)}
                            />
                          </CTooltip>
                        </div>
                        <div>
                          <span  className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.hint_eliminar ? translation[language]?.hint_eliminar : "Eliminar"}`}: </span>
                          <CTooltip content={`${translation[language]?.hint_eliminar ? translation[language]?.hint_eliminar : "Eliminar"}`}>
                            <img 
                              className='icon-border' 
                              src={borrarIcon}  
                              alt="icon"
                              width="25px"
                              height="25px"
                              onClick={()=> handleDeleteItem(item.id_arte)}
                            />
                          </CTooltip>
                        </div>
                    </div>

                    <CCol xs={12} className="mt-3 text-xl-end">
                      <span  className={`d-inline-flex d-xl-none me-1 mb-2`}>{`${translation[language]?.hint_idiomas ? translation[language]?.hint_idiomas : "Idiomas"}`}: </span>
                      <div className="d-flex justify-content-xl-end">
                        {idiomas.map((idioma) => (
                          <div key={idioma.indice} className="ms-0 mb-2 mb-xl-0 ms-xl-1">
                            <CTooltip content={`${returnClassOrMessageLanguage(idioma.indice, item, true, translation, language)}`}>
                              <CButton 
                                className={`icon-language text-uppercase shadow-none ${returnClassOrMessageLanguage(idioma.indice, item, false, translation, language)}`} 
                                onClick={()=> goToEditarItem(item, idioma.indice)}
                              >
                                {idioma.abreviado}
                              </CButton>
                            </CTooltip>
                          </div>
                        ))}
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              {index+1 !== total && (
                  <hr className="mt-0 mb-0 primary-color"/>
              )}     
            </Content>
    )
}
const Content = styled.div`
`
