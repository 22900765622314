import React, {useState, useEffect, useRef} from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'; // <-- fix import
import {
  CSpinner,
} from '@coreui/react'
import {LoadingScreen, Content} from './hooks/styled'
import { format, parseISO } from "date-fns"
import { es, fr, it, de, pt, ar, hi} from 'date-fns/locale';
//componentes
import Login from "./components/CU0-Login";
import ChangePassword from "./components/CU0-Login/ChangePassword";
import Portal from "./components/CU0-Login/Portal";
import PropietarioRegistro from "./components/CU1-CRUD-Propietarios";
import TablaPropietarios from "./components/CU1-CRUD-Propietarios/TablaPropietarios";
import TablaArtesRegistradas from "./components/CU2-CRUD-Artes/TablaArtesRegistradas";
import CRUDArte from "./components/CU2-CRUD-Artes/CRUDArte";
import CRUDPiezas from "./components/CU2-CRUD-Artes/CRUDPiezas";
import TablaArtistasRegistrados from "./components/CU3-CRUD-Artistas/TablaArtistas";
import CRUDArtista from "./components/CU3-CRUD-Artistas/CRUDArtista";
import RegistroArte from "./components/CU4-RegistrandoArte/RegistroArte";
import VerArte from "./components/CU4-RegistrandoArte/VerArte";
import MisArtes from "./components/CU4.1-MisArtes";
import TransferirArte from "./components/CU4.2-TransferirArte";
import CRUDSeries from "./components/CU5-CRUD-Series";
import ButtonGoUp from './hooks/ButtonGoUp'
import ModalContent from './hooks/ModalContent'
//api
import api from './api/api'
//hooks
import Navbar from './hooks/Navbar';
import { globalFunctions } from "./hooks/globalFunctions";

function App() {
  const navigate = useNavigate();
  const { 
    // goToInicio, 
    returnLanguage, 
    findObjFromArray,
    returnNewObjSort
  } = globalFunctions();

  //sesion
  let r = window.localStorage.getItem("remember_me") ? window.localStorage.getItem("remember_me") : false;
  let u =
      !JSON.parse(r) ? JSON.parse(window.sessionStorage.getItem("user"))
      : JSON.parse(window.localStorage.getItem("user"));
  let ta =
      !JSON.parse(r) ? JSON.parse(window.sessionStorage.getItem("tipoActor"))
      : JSON.parse(window.localStorage.getItem("tipoActor"));

  //variables del ENV
  let va = window.sessionStorage.getItem("variablesEnv") ? window.sessionStorage.getItem("variablesEnv") : null;
  
  //sidebar
  // const [sideBarVisible, setsideBarVisible] = useState(true);
  const [optionSelected, setOptionSelected] = useState(null);

  //variables que almacenan la sesion
  const [user, setUser] = useState(u ? u : null);
  const [tipoActor, setTipoActor] = useState(ta ? ta : null); 

  //arreglo que almacena las variables del env
  const [variablesEnv, setVariablesEnv] = useState([]); 

  //para la traduccion de la app

  const [loading, setLoading] = useState(true);

  //este es el idioma de la app, el que cambia el usuario. Almacena el index del idioma
  const [language, setLanguage] = useState(""); 

  //arreglo que almacena las keys de las traducciones
  const [translation, setTranslation] = useState(null); 

  //esto son los idiomas disponibles para cambiar
  const [idiomas, setIdiomas] = useState(null); 

  //para cambiar de idioma y confirmar que van a descartar los cambios
  const [changes, setChanges] = useState(false); 
  const [lenguageCache, setLenguageCache] = useState(null); 

  //modals
  const showModal = useRef(); //referencia del hijo
  const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal

  //Para borrar las sesiones y variables de estado
  const cleanVariables = () => {
    setUser(null);
    setTipoActor(null);
    setOptionSelected(null)
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("tipoActor");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("tipoActor")
    localStorage.removeItem("remember_me");
  };

  //para cerrar la sesion y redirigir al inicio
  const cerrarSesion = (actor=null) => {
    let ruta = parseInt(actor) === 2 ? "/mis-artes" : "/iniciar-sesion"
    cleanVariables()
    navigate(ruta);
  };

  //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
  const handleAcceptAction = () => {
    switch (acceptAction) {
      case 1:
        changeLanguage(lenguageCache)
        setChanges(false)
        setLenguageCache(null)
        showModal?.current?.resetModal()
        break;
      default:
        showModal?.current?.resetModal()
        break;
    }
  };

  //redireccionamos al dar clic en una opcion
  const toRuta = (ruta, boo_url, id) => {  
    setOptionSelected(id)
    if(boo_url){
      const substring = "arcgis.com";
      if(ruta.includes(substring)){
        window.open(ruta,"_self");
      }else{
        window.open(ruta,"_self");
      }
    }else{
      navigate(ruta);
    }
  }


  //Funcion para obtener las preferencias del usuario
  const getData = async (url) => {
    let result;
    //llamamos al endpoint
    return api.get(`${url}`).then(response => {
      result = {error: false, data: response.data}  
      return result
    }).catch(err => {
      result = {error: true, data: []}
      return result
    })
  }

  //obtenemos la data del sistema
  const getConfigSystem = async () => {
    //obtenemos la variable de estado env 
    let variables = await getData("/get_env_variables")
    //obtenemos los lenguajes disponibles de la app
    let newIdiomas = await getData("/get_idiomas_dominio?boo_activo=true")
    //obtenemos la variable con las keys y sus traducciones
    let newTraslation = await getData("/get_idioma_llaves")
    //el lenguaje del explorador o del Cache
    const storageLang = localStorage.getItem('language') // esto ahora es el indice.
    let findIndexStorageLang = findObjFromArray(newIdiomas.data, "indice", parseInt(storageLang))?.indice ? findObjFromArray(newIdiomas.data, "indice", parseInt(storageLang))?.indice : null // buscamos si el indice del idioma del cache todavia se encuentra como idioma disponible
    let getBrowserLanguage = returnLanguage().toLowerCase() //buscamos el lenguaje por defecto del explorador, devuelve 'es', 'en', etc.
    let findIndexBrowserLanguage = findObjFromArray(newIdiomas.data, "abreviado", getBrowserLanguage)?.indice ? findObjFromArray(newIdiomas.data, "abreviado", getBrowserLanguage)?.indice : null // buscamos el indice del idioma del explorador, si no existe arroja null
    let newLanguage = findIndexStorageLang ? findIndexStorageLang : findIndexBrowserLanguage ? findIndexBrowserLanguage : 1 // Si no consigue el idioma del cache ni del explorador por defecto colocamos 1 que es spanish
    //Si no hay error en alguno de los endpoints guardamos las variables
    if(!variables.error && !newTraslation.error && !newIdiomas.error){
      returnNewObjSort(newIdiomas.data, "indice", false, false)
      window.sessionStorage.setItem("variablesEnv", JSON.stringify(variables.data));
      setVariablesEnv(variables.data)
      setTranslation(newTraslation.data)
      setLanguage(newLanguage)
      setIdiomas(newIdiomas.data)
      setLoading(false)
    }
  }

  //para cambiar el idioma desde el header
  const changeLanguage = (newLanguage) => {
    setLanguage(JSON.parse(JSON.stringify(newLanguage)))
  }


  //con esto atajamos que hara al cambiar el lenguaje
  const hangleChangeLanguage = (newLanguage) => {
    if(changes){
      setLenguageCache(newLanguage)
      setAcceptAction(1)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
      return;
    }
    changeLanguage(newLanguage)
  }

  //con esto vamos a retornar la fecha segun idioma
  const returnLanguageDateFormat = (fecha, formato) => {
    let newFecha;
    let abreviado = findObjFromArray(idiomas, "indice", parseInt(language)) ? findObjFromArray(idiomas, "indice", parseInt(language))?.abreviado : 'es'
    switch (abreviado) {
      // ingles
      case 'en':
        newFecha = format(parseISO(fecha), formato)
        break;
      // frances
      case 'fr':
        newFecha = format(parseISO(fecha), formato, { locale: fr })
        break;
      // italiano
      case 'it':
        newFecha = format(parseISO(fecha), formato, { locale: it })
        break;
      // aleman
      case 'de':
        newFecha = format(parseISO(fecha), formato, { locale: de })
        break;
      // portugues
      case 'pt':
        newFecha = format(parseISO(fecha), formato, { locale: pt })
        break;
      // arabe
      case 'ar':
        newFecha = format(parseISO(fecha), formato, { locale: ar })
        break;
      // hindu
      case 'hi':
        newFecha = format(parseISO(fecha), formato, { locale: hi })
        break;
      // español
      default:
        newFecha = format(parseISO(fecha), formato, { locale: es })
        break;
    }
    return newFecha
  }


  //con este efecto obtenemos las variables primordiales para iniciar la App
  useEffect(()=> {
    getConfigSystem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //con este efecto actualizamos la variable language del localStorage
  useEffect(() => {
    if(!loading){
      localStorage.setItem('language', language)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])


  
  return (
    <> 
      {loading ? (
        <Content>
          <LoadingScreen>
            <div className="feedback-visual">
            <CSpinner/>
              <CSpinner variant="grow"/>
            </div>
          </LoadingScreen>
        </Content>
      ) : (
        <>
          <ButtonGoUp/>

          <ModalContent
            ref={showModal}
            acceptAction={handleAcceptAction}
            // modalContentInside={textdivContent}
          />

          {user && tipoActor && (
            <Navbar
              user={user}
              tipoActor={tipoActor}
              optionSelected={optionSelected}
              cerrarSesion={cerrarSesion}
              toRuta={toRuta}
              //traducciones
              translation={translation}
              language={language}
              idiomas={idiomas}
              changeLanguage={hangleChangeLanguage}
            />
          )}
          <div className="d-flex" id="wrapper">
            <div className={`${(user && tipoActor) ? 'mt-5' : ''}`} id="page-content-wrapper">
              <Routes>
                <Route exact path="/iniciar-sesion" 
                  element={
                  <Login
                    user={user || u}
                    admin={true}
                    tipoActor={tipoActor || ta}
                    setUser={setUser}
                    setTipoActor={setTipoActor}
                    variablesEnv = {variablesEnv || va}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                />  

                <Route exact path="/consultar-artes" 
                  element={
                  <Login
                    user={user || u}
                    admin={false}
                    tipoActor={tipoActor || ta}
                    setUser={setUser}
                    setTipoActor={setTipoActor}
                    variablesEnv = {variablesEnv || va}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                />

                <Route path="/cambiar-contrasena" 
                  element={
                  <ChangePassword
                    admin={true}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                /> 
                
                <Route path="/cambiar-pin" 
                  element={
                  <ChangePassword
                    admin={false}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                /> 

                <Route path="/portal" 
                  element={
                  <Portal
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                /> 

                <Route path="/propietario-registro" 
                  element={
                  <PropietarioRegistro
                    user={user || u}
                    admin={false}
                    registro={true}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                  />
                  }
                /> 

                <Route path="/informacion-personal" 
                  element={
                  <PropietarioRegistro
                    user={user || u}
                    admin={false}
                    registro={false}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                  />
                  }
                /> 

                <Route path="/admin-propietarios" 
                  element={
                  <TablaPropietarios
                    user={user || u}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                /> 

                <Route path="/admin-editar-propietario" 
                  element={
                  <PropietarioRegistro
                    user={user || u}
                    admin={true}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                  />
                  }
                />      

                <Route path="/registro-arte" 
                  element={
                  <RegistroArte
                    user={user || u}
                    variablesEnv = {variablesEnv || va}
                    //traducciones
                    translation={translation}
                    language={language}
                    setLanguage={setLanguage}
                    idiomas={idiomas}
                  />
                  }
                />    

                <Route path="/admin-artes" 
                  element={
                  <TablaArtesRegistradas
                    user={user || u}
                    //traducciones
                    translation={translation}
                    language={language}
                    returnLanguageDateFormat={returnLanguageDateFormat}
                    idiomas={idiomas}
                  />
                  }
                />

                <Route path="/admin-nuevo-arte" 
                  element={
                  <CRUDArte
                    user={user || u}
                    edit={false}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                    changesApp={changes}
                    setChangesApp={setChanges}
                  />
                  }
                />

                <Route path="/admin-editar-arte" 
                  element={
                  <CRUDArte
                    user={user || u}
                    edit={true}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                    changesApp={changes}
                    setChangesApp={setChanges}
                  />
                  }
                />

                <Route path="/admin-administrar-piezas-arte" 
                  element={
                  <CRUDPiezas
                    user={user || u}
                    variablesEnv = {variablesEnv || va}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                />

                <Route path="/admin-artistas" 
                  element={
                  <TablaArtistasRegistrados
                    user={user || u}
                    //traducciones
                    translation={translation}
                    language={language}
                    returnLanguageDateFormat={returnLanguageDateFormat}
                  />
                  }
                />

                <Route path="/admin-nuevo-artista" 
                  element={
                  <CRUDArtista
                    user={user || u}
                    edit={false}
                    variablesEnv = {variablesEnv || va}
                    setChangesApp={setChanges}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                  />
                  }
                />

                <Route path="/admin-editar-artista" 
                  element={
                  <CRUDArtista
                    user={user || u}
                    edit={true}
                    variablesEnv = {variablesEnv || va}
                    setChangesApp={setChanges}
                    //traducciones
                    translation={translation}
                    language={language}
                    idiomas={idiomas}
                  />
                  }
                />

                <Route path="/ver-arte" 
                  element={
                  <VerArte
                    user={user || u}
                    //traducciones
                    translation={translation}
                    language={language}
                    returnLanguageDateFormat={returnLanguageDateFormat}
                  />
                  }
                />

                <Route path="/mis-artes" 
                  element={(!user || !u) ? <Navigate to="/registro-arte"/> 
                  : 
                    <MisArtes
                      user={user || u}
                      //traducciones
                      translation={translation}
                      language={language}
                    />
                  }
                />

                <Route path="/admin-artes-propietario" 
                  element={
                  <MisArtes
                    user={user || u}
                    admin={true}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                />

                <Route path="/transferir-arte" 
                  element={
                  <TransferirArte
                    user={user || u}
                    variablesEnv = {variablesEnv || va}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                />

                <Route path="/admin-series" 
                  element={
                  <CRUDSeries
                    user={user || u}
                    variablesEnv = {variablesEnv || va}
                    //traducciones
                    translation={translation}
                    language={language}
                  />
                  }
                />
              </Routes>
            </div>
          </div> 
        </>
      )}    
    </>
  );
}

export default App;
