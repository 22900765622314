import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CContainer,
  CBadge
} from '@coreui/react'
import styled from 'styled-components'
import CardContent from './CardContent';
import ModalContent from '../../../hooks/ModalContent'
import ModalContentBidi from './ModalContentBidi'
import { globalFunctions } from "../../../hooks/globalFunctions";
import api from '../../../api/api'

export default function CRUDPiezas({
  user,
  variablesEnv,
  //traducciones
  translation={},
  language="",
}) {
    //Variables
    const { 
      validationIsNumberInt,
      validationIsAlphaNumeric
    } = globalFunctions();
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [showTextDiv, setShowTextDiv] = useState(null); //accion de aceptar del modal
    const [changes, setChanges] = useState(false); //guarda si hubo cambios
    const [itemSeleccionado, setItemSeleccionado] = useState(null); //el item que se selecciona
    const navigate = useNavigate();
    const showModal = useRef(); //referencia del hijo

    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null
    let idArte = locationData?.id_arte ? locationData?.id_arte : null

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    //configuracion de busqueda, filtrado y paginacion
    const [dataConfig, setDataConfig] = useState({
      searchTerm: locationData?.dataConfig?.searchTerm ? locationData?.dataConfig?.searchTerm : "",
      filter: locationData?.dataConfig?.filter ? locationData?.dataConfig?.filter : 0,
      itemsPerPage: locationData?.dataConfig?.itemsPerPage ? locationData?.dataConfig?.itemsPerPage : 6,
      cantRegistros: 0,
      currentPage: 1,
      lastPage: 1,
      minPageNumberLimit: locationData?.dataConfig?.minPageNumberLimit ? locationData?.dataConfig?.minPageNumberLimit : 0,
      maxPageNumberLimit: locationData?.dataConfig?.maxPageNumberLimit ? locationData?.dataConfig?.maxPageNumberLimit : 5,
      //estos valores nunca se actualizan para saber el minimo y maximo
      pageNumberLimit: 5,
      pageMinNumber: 0,

    });

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      edit: false,
      id_pieza: null,
      fk_arte: null,
      codigo_pieza: "",
      codigo_invitacion: "",
      numero_pieza: "",
      url: "",
      bidi: null,
      propietario_pieza: [],
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      codigo_pieza: false,
      codigo_invitacion: false,
      numero_pieza: false,
      url: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      codigo_pieza: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      codigo_invitacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      numero_pieza: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      url: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });

    //con esta variable validamos si el CU se esta abriendo por primera vez
    const [inicio, setInicio] = useState(true);
    const [loadingData, setLoadingData] = useState(true);

    //arreglo que almacena los data
    const [data, setData] = useState([]);

    //funciones

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          goBack()
          break;
        case 2:
          deletePieza(itemSeleccionado)
          break;
        case 3:
          setForm(null)
          showModal?.current?.resetModal()
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    // con esto colocamos contenido propio en el modal
    const textdivContent = () => {
      let elements = null
      switch (showTextDiv) {
          case 1:
            elements = (
              <ModalContentBidi
                item={itemSeleccionado} 
                onClose={() => {
                  setItemSeleccionado(null)
                  showModal?.current?.resetModal()
                }}
                //traducciones
                translation={translation}
                language={language}              
              />
            )
            break;
          default:
            break;
      }
      return elements
    };

    //Para tomar accion luego de darle clic a ver BIDI
    const handleClickShowBidi = (item) => {
      setItemSeleccionado(item)
      setShowTextDiv(1)
      showModal?.current?.content?.ownContent("md")
    }

    //Con esto enviaremos de regreso al usuario al dar clic en back
    const handleBack = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        goBack()
      }
    }

    //con esto volvemos atras
    const goBack = () => {
      let dataCache = {
        id_arte: locationData?.id_arte ? locationData?.id_arte : null,
        getData: locationData?.getData ? locationData?.getData : false,
        indice_idioma: locationData?.indice_idioma ? locationData?.indice_idioma : 1,
        oldDataCache: locationData?.oldDataCache ? locationData?.oldDataCache : null
      }
      navigate('/admin-editar-arte', { state: { dataCache: dataCache}})
    }

    //Con esta funcion escuchamos los cambios en los filtros
    const handleChangeFiltros = (e) => {
      //Cuando se coloca algo en el buscador
      if (e.target.name === "search") {
        if (e.target.value === "") {
          setDataConfig({...dataConfig, searchTerm: ""})
        } else {
          setDataConfig({...dataConfig, searchTerm: e.target.value})
        }
      }
    }

    //Con esta funcion obtenemos los data
    const getData = (inicio = true, page = 1, maxPageNumberLimit=null, minPageNumberLimit=null) => {
      window.scrollTo(0, 0);
      setLoadingData(true)
      let newDataConfig;
      let sr = dataConfig
      // Esta es la variable que almacenara los parametros que enviaremos al endpoint
      let obj = {
        fk_arte: idArte,
        page: page,
        page_size: sr.itemsPerPage,
      };
      let terms;
      //si hay busqueda le agregamos los parametros de busqueda
      if (sr.searchTerm !=="") {
        terms = {
          ...obj,
          codigo_pieza: sr.searchTerm,
        };
      } else {
        terms = obj
      }
      const newConfig = {...config, params: terms}
      api.get(`/get_piezas`, newConfig).then(response => {
        newDataConfig = {
          ...dataConfig,
          cantRegistros: response.data[1].total,
          lastPage: response.data[1].last_page,
          currentPage: page,
          maxPageNumberLimit: parseInt(maxPageNumberLimit)>=0 ? maxPageNumberLimit : dataConfig.maxPageNumberLimit, 
          minPageNumberLimit: parseInt(minPageNumberLimit)>=0 ? minPageNumberLimit : dataConfig.minPageNumberLimit,
        }
        setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
        setLoadingData(false)
        setData(response.data[0])
        locationData.total_piezas = response.data[1].total
        if(inicio){
          setInicio(false)
        }
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          case 404:
            locationData.total_piezas = 0
            newDataConfig = {
              ...dataConfig,
              cantRegistros: 0,
              lastPage: 1,
              currentPage: 1,
              maxPageNumberLimit: dataConfig.pageNumberLimit, 
              minPageNumberLimit: dataConfig.pageMinNumber,
            }
            setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
            setLoadingData(false)
            setData([])
            if(inicio){
              setInicio(false)
            }         
          break;
          default:
            break;
        }
      })
    }

    const returnLeftColumnContent = () => {
      return(
        <>
          {`${translation[language]?.piezas_artes ? translation[language]?.piezas_artes : "Piezas de Arte"}`}
          <CBadge className={`ms-2 rounded-0 badge-border2 font-size-11`}>
            {locationData?.total_piezas ? locationData?.total_piezas : 0}
          </CBadge>
        </>
      )
    }

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value } = variable;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChanges(true)
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    //funcion que valida los campos del formulario
    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(formFields.edit){
        if(!formFields['url']) {
          errorsObj['url'] = true
          errorsObjText['url'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
          validState = false
        }
      }


      if(!formFields['codigo_pieza']) {
        errorsObj['codigo_pieza'] = true
        errorsObjText['codigo_pieza'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsAlphaNumeric(formFields['codigo_pieza'])){
        errorsObj['codigo_pieza'] = true
        errorsObjText['codigo_pieza'] = translation[language]?.campo_alfanum ? translation[language]?.campo_alfanum : "Campo alfanumérico"
        validState = false            
      }

      if(!formFields['codigo_invitacion']) {
        errorsObj['codigo_invitacion'] = true
        errorsObjText['codigo_invitacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsAlphaNumeric(formFields['codigo_invitacion'])){
        errorsObj['codigo_invitacion'] = true
        errorsObjText['codigo_invitacion'] = translation[language]?.campo_alfanum ? translation[language]?.campo_alfanum : "Campo alfanumérico"
        validState = false            
      }

      if(!formFields['numero_pieza']) {
        errorsObj['numero_pieza'] = true
        errorsObjText['numero_pieza'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['numero_pieza'])){
        errorsObj['numero_pieza'] = true
        errorsObjText['numero_pieza'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //Funcion para generar una contrasena automaticamente
    const generarCodigo = () => {
      updateErrors("codigo_invitacion");
      let newCod = `${(Math.floor(100000 + Math.random() * 900000))}`;
      let fieldsObj = { ...formFields, codigo_invitacion: newCod};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
    };

    //con esta funcion 
    const putPieza = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')

      let newObj = {
        piezas: [{
          id_pieza: formFields.id_pieza ? formFields.id_pieza : null,
          fk_arte: idArte,
          codigo_pieza: formFields.codigo_pieza ? formFields.codigo_pieza : null,
          codigo_invitacion: formFields.codigo_invitacion ? formFields.codigo_invitacion : null,
          numero_pieza: formFields.numero_pieza ? parseInt(formFields.numero_pieza) : null,
          url: formFields.url ? formFields.url : variablesEnv?.REACT_APP_REGISTRO_ARTE_URL,
          bidi: !formFields.bidi ? `${variablesEnv?.REACT_APP_ARTE_URL}?codigo_pieza=${formFields.codigo_pieza}` : null,
        }]        
      }
      
      api.put("/put_pieza", newObj, config).then(response => {
        setForm(null)
        getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber) 
        showModal?.current?.content?.success(
          null, //accion que tomara 
          `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 411:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_cod_pieza_registrado ? translation[language]?.msg_error_cod_pieza_registrado : "El código de pieza ya se encuentra registrado. Por favor, ingresa uno diferente."}`, // mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break;
          case 412:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_cod_inv_registrado ? translation[language]?.msg_error_cod_inv_registrado : "El código de invitación ya se encuentra registrado. Por favor, ingresa uno diferente."}`, // mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );   
            break;
          case 413:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_pieza_registrado ? translation[language]?.msg_error_pieza_registrado : "El número de pieza ya se encuentra registrado. Por favor, ingresa uno diferente."}`, // mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );  
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //Con esto eliminamos una pieza
    const deletePieza = (id) => {
      showModal?.current?.content.loading()
      let newObj = {
        piezas: [{
        id_pieza: id
        }]
      }// esto es lo que enviaremos al endpoint
      const newConfig = {...config, data: newObj} // config es el headers del endpoint (token) y se le añade la data 
      api.delete("/delete_pieza", newConfig).then(response => {
        setForm(null)
        getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        showModal?.current?.content?.success(
          null, //accion que tomara 
          `${translation[language]?.msg_eliminado_sastifactoriamente ? translation[language]?.msg_eliminado_sastifactoriamente : "¡Eliminado sastifactoriamente!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );  
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 409:
              showModal?.current?.content?.info(
                null, //accion que tomara 
                `${translation[language]?.msg_error_eliminar_pieza ? translation[language]?.msg_error_eliminar_pieza : "No es posible eliminar la pieza, porque le pertenece a un propietario."}`, // mensaje principal
                null, //mensaje secundario
                false, //indica si hay o no boton de cancelar
                `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
                `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
              );  
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //confirmamos que quieran eliminar la pieza
    const handleDeletePieza = (id) => {
      setItemSeleccionado(id)
      setAcceptAction(2)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_eliminar_pieza ? translation[language]?.msg_eliminar_pieza : "¿Estás seguro de eliminar esta pieza?"}`, //mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      ); 
    }


    //con esto enviamos al usuario a la vista de CU4.2 
    const handleGoToTransferirArte = (obj) => {
      let propietario = obj?.propietario_pieza.find( newItem => newItem.vigencia) ? obj?.propietario_pieza.find( newItem => newItem.vigencia) : null
      if(propietario){
        let dataCache = {
          admin: true,
          from: '/admin-administrar-piezas-arte',
          action: 1,
          id_propietario_pieza: propietario.id_propietario_pieza,
          fk_propietario: propietario.fk_propietario,
          oldDataCache: locationData ? locationData : null
        }
        navigate('/transferir-arte', { state: { dataCache: dataCache}})
      }
    }


    //Con esta función preguntamos al usuario si esta seguro de cancelar los cambios.
    const discardChanges = () => {
      if(changes){
        setAcceptAction(3)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
        return;
      }else{
        setForm(null)
        showModal?.current?.resetModal()
      }
    }


    //con esto seteamos el formulario y lo limpiamos. Si item no es null significa que estamos editando una pieza
    const setForm = (item=null) => {
      //limpiamos el form
      setFormFields({
        ...formFields,
        edit: item ? true : false,
        id_pieza: item?.id_pieza ? item?.id_pieza : null,
        fk_arte: item?.fk_arte ? item?.fk_arte : null,
        codigo_pieza: item?.codigo_pieza ? item?.codigo_pieza : "",
        codigo_invitacion: item?.codigo_invitacion ? item?.codigo_invitacion : "",
        numero_pieza: item?.numero_pieza ? item?.numero_pieza : "",
        url: item?.url ? item?.url : "",
        bidi: item?.bidi ? item?.bidi : null,
        propietario_pieza: item?.propietario_pieza ? item?.propietario_pieza : [],
      })
      //limpiamos los errores
      setFormErrors({
        ...formErrors,
        codigo_pieza: false,
        codigo_invitacion: false,
        numero_pieza: false,
        url: false,
      })
      //limpiamos el texto de los errores
      setFormErrorsText({
        ...formErrorsText,
        codigo_pieza: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        codigo_invitacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        numero_pieza: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        url: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      })
      //limpiamos el changes
      setChanges(false)
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      if (!validateFields()) {
        return       
      }
      //si no hay errores
      putPieza()
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
        return;
      }
      if(!idArte){
        showModal?.current?.content?.info(
          5, //accion que tomara 
          `${translation[language]?.msg_seleccione_arte_administrar ? translation[language]?.msg_seleccione_arte_administrar : "Seleccione un Arte para administrar piezas"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
        return;
      }
      let page = locationData?.dataConfig?.currentPage ? locationData?.dataConfig?.currentPage : 1
      getData(true, page)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //con este efecto llamamos a la funcion para obtener la data al momento de cambiar de pagina, de numero de mostrar o filtrado
    useEffect(()=> {
      if(!inicio){
      //Si este parametro no esta vacio significa que el usuario escribio algo, se espera 1 segundo para buscar
        if(dataConfig.searchTerm !==""){
          setLoadingData(true)
          const delayDebounceFn = setTimeout(() => {
            getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
          }, 1000);
          return () => clearTimeout(delayDebounceFn);
        }else{
          getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataConfig.searchTerm, dataConfig.itemsPerPage, dataConfig.filter])

    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CardContent
                      data={data}
                      dataConfig={dataConfig} // configuracion del cu, almacenamos paginacion, filtrado, busqueda, etc
                      getData={getData}
                      loadingData={loadingData}
                      discardChanges={discardChanges}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      handleGoToTransferirArte={handleGoToTransferirArte}
                      handleBack={handleBack}
                      returnLeftColumnContent={returnLeftColumnContent}
                      formFields={formFields}
                      formErrors={formErrors}
                      formErrorsText={formErrorsText}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      generarCodigo={generarCodigo}
                      locationData={locationData}
                      handleDeletePieza={handleDeletePieza}
                      handleClickShowBidi={handleClickShowBidi}
                      setForm={setForm}
                      changes={changes}
                      //traducciones
                      translation={translation}
                      language={language}   
                    />                             
                  </CContainer>
                </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`