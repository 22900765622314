import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CSpinner,
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
} from '../../hooks/styled'
import Header from "../../hooks/Header";
import ArtListRows from './ArtListRows';
import infoIcon from '../../icons/Icon ionic-md-information-circle-outline.svg'

export default function CardContent({
  admin,
  loadingData,
  goToVerArte,
  locationData,
  handleBack,
  handleSelectItem,
  itemSeleccionado,
  data,
  //traducciones
  translation={},
  language="",
}) {

    //con esto retornamos las filas
    const RenderData = (registro) => {
      return (
        <>
          {registro.map((item, i) => {
            return (
              <ArtListRows
                key={i}
                item={item}
                index={i}
                goToVerArte={goToVerArte}
                locationData={locationData}
                handleSelectItem={handleSelectItem}
                itemSeleccionado={itemSeleccionado}
                //traducciones
                translation={translation}
                language={language}             
              />
            );
          })}
        </>
      );
    };

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-2 px-sm-4">
                    <Header
                      title={`${!admin ? `${translation[language]?.mis_artes ? translation[language]?.mis_artes : "Mis Artes"}` : `${translation[language]?.artes_propietario ? translation[language]?.artes_propietario : "Artes del propietario"}`}`}
                      subtitle={`${!admin ? "" : `${locationData?.propietario?.nombre ? locationData?.propietario?.nombre : ''} ${locationData?.propietario?.apellidos ? locationData?.propietario?.apellidos : ''}`}`}
                      isBack={locationData ? true : false}
                      handleBack={handleBack}
                    />
                    {(!loadingData) ?  
                    (
                      <>
                        {locationData?.selection && (
                          <CRow>
                            <CCol lg={12}>
                              <div 
                                  className="d-flex primary-text-color font-size-12" 
                                >
                                <img
                                  src={infoIcon}
                                  alt="pin"
                                  width="16"
                                  height="16"
                                  className="me-2 mt-1"
                                />
                                  
                                  {translation[language]?.hint_mis_artes ? translation[language]?.hint_mis_artes : 'Selecciona la pieza de Arte a transferir.'}
                              </div> 
                            </CCol> 
                          </CRow>
                        )}
                        <CRow className="d-flex justify-content-center pt-4 pb-2 font-size-12">
                          <CCol lg={8} xxl={8}>

                            <CRow className="mb-0">
                              {!locationData?.selection && (
                                <CCol md="12" className="px-4 mb-4 fw-bold">
                                  {translation[language]?.artes_actuales ? translation[language]?.artes_actuales : 'Artes actuales'}
                                </CCol>                               
                              )}
                              {data.actuales.length > 0 ? (
                                <>
                                  {RenderData(data.actuales)}  
                                </>
                              ) : (
                                <CCol md="12" className="px-4 mb-4">
                                  <div 
                                      className="d-flex primary-text-color font-size-12" 
                                    >
                                    <img
                                      src={infoIcon}
                                      alt="pin"
                                      width="16"
                                      height="16"
                                      className="me-2 mt-1"
                                    />
                                      {translation[language]?.hint_sin_artes ? translation[language]?.hint_sin_artes : 'Sin artes actuales.'}
                                  </div>
                                </CCol>
                              )}     
                            </CRow> 

                            {!locationData?.selection && (
                              <>
                                <hr className="px-4 mt-2 opacity-100"/>
                                <CRow className="mb-0">
                                  <CCol md="12" className="px-4 mt-2 mb-4 fw-bold">
                                    {translation[language]?.artes_anteriores ? translation[language]?.artes_anteriores : 'Artes anteriores'}
                                  </CCol>
                                  {data.antiguas.length > 0 ? (
                                    <>
                                      {RenderData(data.antiguas)}  
                                    </>
                                  ) : (
                                    <CCol md="12" className="px-4 mb-4">
                                      <div 
                                          className="d-flex primary-text-color font-size-12" 
                                        >
                                        <img
                                          src={infoIcon}
                                          alt="pin"
                                          width="16"
                                          height="16"
                                          className="me-2 mt-1"
                                        />
                                          {translation[language]?.hint_artes_anteriores ? translation[language]?.hint_artes_anteriores : 'Sin artes anteriores.'}
                                      </div>
                                    </CCol>
                                  )}     
                                </CRow> 
                              </>
                            )}
                          </CCol>
                        </CRow>                       
                      </>
                    ) 
                    : 
                    (
                      <>
                        <CRow>                         
                          <CCol xs={12} className={`d-flex align-items-center mb-5 mt-3 ${loadingData ? "justify-content-center" : ""}`}>
                            <div className="text-center">
                              <CSpinner size="sm"/>
                                <CSpinner size="sm" variant="grow"/>
                                <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                            </div>                      
                          </CCol>
                        </CRow>
                      </>
                    )}
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
`
