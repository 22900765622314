import React from "react";
import {
  CRow,
  CCol,
  CTooltip
} from '@coreui/react'
import styled from 'styled-components'
import pencilIcon from '../../icons/pencil.svg'
import EyeIcon from "../../icons/icon-feather-eye.svg";
import EyeOffIcon from "../../icons/icon-feather-eye-off.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function SerieListRows({
  item,
  index,
  total,
  handleEditarItem,
  handleClickShowImg,
  //traducciones
  translation={},
  language="", 
}) {

    return (
            <Content>
              <div className={`bg-color d-lg-none`}> 
                  <CRow className="p-2 pl-3">               
                      <CCol className="text-white" xs="12">
                        {item.nombre ? item.nombre : ""}
                      </CCol>
                  </CRow>
              </div> 
              <CRow className="py-2 px-2 px-sm-3 d-flex align-items-center">
                <CCol lg={2} className="border-lg-col">
                  <div className="box big">
                    <LazyLoadImage
                      alt={"img"}
                      effect="blur" 
                      className='foto'
                      placeholderSrc={item?.imagen ?  item?.imagen : "/sin-imagen.png"}
                      src={item?.imagen ?  item?.imagen : "/sin-imagen.png"}
                    /> 
                  </div>       
                  <div className="text-lg-end my-2">
                    <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.hint_ver_imagen ? translation[language]?.hint_ver_imagen : "Ver imagen"}`}: </span>
                    <CTooltip content={`${item?.imagen ? `${translation[language]?.hint_ver_imagen ? translation[language]?.hint_ver_imagen : "Ver imagen"}` : `${translation[language]?.hint_sin_imagen ? translation[language]?.hint_sin_imagen : "No hay imagen"}`}`}>
                      <img 
                        className='icon-border border-0 ms-0' 
                        src={item?.imagen ? EyeIcon : EyeOffIcon}  
                        alt="icon"
                        width="28px"
                        height="28px"
                        onClick={()=> item?.imagen ? handleClickShowImg(item) : false}
                      />
                    </CTooltip>   
                  </div>        
                </CCol>
                <CCol lg={2} className="d-none d-lg-block">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.nombre ? translation[language]?.nombre : "Nombre"}`}: </span>
                  {item.nombre ? item.nombre : ""}
                </CCol>
                <CCol lg={7}>
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.descripcion ? translation[language]?.descripcion : "Descripción"}`}: </span>
                  {item.descripcion ? item.descripcion : ""}
                </CCol>      
                <CCol lg={1} className="text-lg-end">
                  <span  className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}: </span>
                  <CTooltip content={`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}>
                    <img 
                      className='icon-border' 
                      src={pencilIcon}  
                      alt="icon"
                      width="25px"
                      height="25px"
                      onClick={()=> {
                        window.scrollTo(0, 0);
                        handleEditarItem(item)
                      }}
                    />
                  </CTooltip>
                </CCol>
              </CRow>
              {index+1 !== total && (
                  <hr className="mt-0 mb-0 primary-color"/>
              )}     
            </Content>
    )
}
const Content = styled.div`
`
