import React from "react";
import {
  CCol,
  CTooltip,
} from '@coreui/react'
import {
  FormCheck
} from '../../hooks/styled'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { globalFunctions } from "../../hooks/globalFunctions";
import EyeIcon from "../../icons/icon-feather-eye.svg";

export default function ArtListRows({
  item,
  goToVerArte,
  locationData,
  handleSelectItem,
  itemSeleccionado,
  //traducciones
  translation={},
  language="",
}) {

    const { 
      truncateText
    } = globalFunctions();

    return (
            <Col sm={6} md={4} className={`mb-4 px-4 h-100`}>
              <div className="border border-color">
                <div className={`${item.imagen_arte.length > 0 ? 'box2 big2' : 'box4 big4'}`}>
                  <LazyLoadImage
                    alt={"pic"}
                    effect="blur" 
                    className='foto'
                    src={item.imagen_arte.length > 0 ? item.imagen_arte : "/sin-imagen.png"}
                    placeholderSrc={item.imagen_arte.length > 0  ? item.imagen_arte : "/sin-imagen.png"}
                  /> 
                </div>
              </div>
                <div className={`d-flex align-items-center justify-content-end ${locationData?.selection ? '' : ''}`}>
                    <div className="">
                      <CTooltip content={`${translation[language]?.hint_ver_artes ? translation[language]?.hint_ver_artes : 'Ver'}`}>
                        <img 
                          className={`ms-0 icon-border border-0 mt-1 ${locationData?.selection ? 'mt-0' : 'mt-0'}`}
                          src={EyeIcon}  
                          alt="icon"
                          width="28px"
                          height="28px"
                          onClick={()=> goToVerArte(item)}
                        />
                      </CTooltip>
                    </div>
                    {locationData?.selection && (
                      <div>
                        <FormCheck 
                          className="" 
                          label=""
                          name="select"
                          checked={itemSeleccionado?.id_pieza === item.id_pieza}
                          onChange={(e) => handleSelectItem(e, item)}
                        /> 
                      </div>

                    )}
                </div>
              <div 
                className="py-2"
              >
                <div className="fw-bold">{item.nombre_arte ? item.nombre_arte : ""} - {item.codigo_pieza ? item.codigo_pieza : ""}</div>
                <div className="text-justify">
                  {item.descripcion_corta_arte ? truncateText(item.descripcion_corta_arte, 90) : `${translation[language]?.sin_descripcion ? translation[language]?.sin_descripcion : 'Sin descripción.'}`}
                </div>
              </div>
            </Col>
    )
}

const Col = styled(CCol)`
  .bg-custom{
    border-top: 1px solid var(--primary-color);
    background-color: #F7F5EF;
  }
`
