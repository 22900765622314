import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CContainer,
} from '@coreui/react'
import styled from 'styled-components'
import useStateRef from 'react-usestateref'
import CardContent from './CardContent';
import ModalContent from '../../../hooks/ModalContent'
import api from '../../../api/api'

export default function TablaArtistas({
  user,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat,
}) {
    const navigate = useNavigate();
    //Variables
    const showModal = useRef(); //referencia del hijo

    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    //con esto sabremos cuando esten cargados todos los endpoints
    const [dominiosLoad, setdominiosLoad, dominiosLoadRef] = useStateRef({
      get_artistas: false,
      get_pais_dominio: false,
    })

    //configuracion de busqueda, filtrado y paginacion
    const [dataConfig, setDataConfig] = useState({
      searchTerm: locationData?.dataConfig?.searchTerm ? locationData?.dataConfig?.searchTerm : "",
      filter: locationData?.dataConfig?.filter ? locationData?.dataConfig?.filter : 0,
      itemsPerPage: locationData?.dataConfig?.itemsPerPage ? locationData?.dataConfig?.itemsPerPage : 6,
      cantRegistros: 0,
      currentPage: 1,
      lastPage: 1,
      minPageNumberLimit: locationData?.dataConfig?.minPageNumberLimit ? locationData?.dataConfig?.minPageNumberLimit : 0,
      maxPageNumberLimit: locationData?.dataConfig?.maxPageNumberLimit ? locationData?.dataConfig?.maxPageNumberLimit : 5,
      //estos valores nunca se actualizan para saber el minimo y maximo
      pageNumberLimit: 5,
      pageMinNumber: 0,

    });
    //con esta variable validamos si el CU se esta abriendo por primera vez
    const [inicio, setInicio] = useState(true);
    const [loadingData, setLoadingData] = useState(true);
    const [itemsSelected, setItemsSelected] = useState([]);
    const [paises, setPaises] = useState([]); //lista de paises
    const [changes, setChanges] = useState(false); //si hubo cambios
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [itemSeleccionado, setItemSeleccionado] = useState(null); //el item que se selecciona y se elimina

    //arreglo que almacena la data
    const [data, setData] = useState([]);

    //funciones

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          handleClickSelect(true)
          break;
        case 2:
          deleteItem(itemSeleccionado)
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //Con esta funcion escuchamos los cambios en los filtros
    const handleChangeFiltros = (e) => {
      //Cuando se coloca algo en el buscador
      if (e.target.name === "search") {
        if (e.target.value === "") {
          setDataConfig({...dataConfig, searchTerm: ""})
        } else {
          setDataConfig({...dataConfig, searchTerm: e.target.value})
        }
      }
    }

    //Con esta funcion obtenemos la data
    const getData = (inicio = true, page = 1, maxPageNumberLimit=null, minPageNumberLimit=null) => {
      window.scrollTo(0, 0);
      setLoadingData(true)
      let newDataConfig;
      let sr = dataConfig
      // Esta es la variable que almacenara los parametros que enviaremos al endpoint
      let obj = {
        page: page,
        page_size: sr.itemsPerPage,
        indice_idioma: parseInt(language),
      };
      let terms;
      //si hay busqueda le agregamos los parametros de busqueda
      if (sr.searchTerm !=="") {
        terms = {
          ...obj,
          nombre_apellido: sr.searchTerm,
          seudonimo: sr.searchTerm,
        };
      } else {
        terms = obj
      }
      const newConfig = {...config, params: terms}
      api.get(`/get_artistas`, newConfig).then(response => {
        newDataConfig = {
          ...dataConfig,
          cantRegistros: response.data[1].total,
          lastPage: response.data[1].last_page,
          currentPage: page,
          maxPageNumberLimit: parseInt(maxPageNumberLimit)>=0 ? maxPageNumberLimit : dataConfig.maxPageNumberLimit, 
          minPageNumberLimit: parseInt(minPageNumberLimit)>=0 ? minPageNumberLimit : dataConfig.minPageNumberLimit,
        }
        let newObj = response.data[0].map((d) => {
          return {
            ...d, 
            selected: false,
          }
        });

        setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
        setData(newObj)
        if(inicio){
          setInicio(false)
          setdominiosLoad({...dominiosLoadRef.current, get_artistas: true})
        }else{
          setLoadingData(false)
        }
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          case 404:
            newDataConfig = {
              ...dataConfig,
              cantRegistros: 0,
              lastPage: 1,
              currentPage: 1,
              maxPageNumberLimit: dataConfig.pageNumberLimit, 
              minPageNumberLimit: dataConfig.pageMinNumber,
            }
            setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
            setData([])
            if(inicio){
              setInicio(false)
              setdominiosLoad({...dominiosLoadRef.current, get_artistas: true})
            }else{
              setLoadingData(false)            
            } 
          break;
          default:
            break;
        }
      })
    }

    //para editar el item seleccionado
    const goToEditarItem = (item) => {
      let dataCache = {
        id_artista: item.id_artista,
        oldDataCache: {
          dataConfig: dataConfig,
        }
      }
      navigate('/admin-editar-artista', { state: { dataCache: dataCache}})
    }

    //con esto vamos a la vista para crear un artista
    const goToAgregarItem = () => {
      navigate('/admin-nuevo-artista')
    }

    //confirmamos que quieran eliminar al artista
    const handleDeleteItem = (id) => {
      setItemSeleccionado(id)
      setAcceptAction(2)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_confirm_eliminar_artista ? translation[language]?.msg_confirm_eliminar_artista : "¿Estás seguro de eliminar este artista?"}`, //mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
    }

     //Con esto eliminamos un artista
     const deleteItem = (id) => {
      showModal?.current?.content.loading()
      let newObj = {
        artistas: [{
          id_artista: id
        }]
      }// esto es lo que enviaremos al endpoint
      const newConfig = {...config, data: newObj} // config es el headers del endpoint (token) y se le añade la data 
      api.delete("/delete_artista", newConfig).then(response => {
        getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        showModal?.current?.content?.success(
          null, //accion que tomara 
          `${translation[language]?.msg_eliminado_sastifactoriamente ? translation[language]?.msg_eliminado_sastifactoriamente : "¡Eliminado sastifactoriamente!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );    
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 411:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_eliminar_artista ? translation[language]?.msg_error_eliminar_artista : "No es posible eliminar el Artista porque es autor de algún Arte."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }   

    //CU3 X CU2

    //Con esta funcion seleccionamos o desmarcamos un artista
    const handleSelectItem = (e, obj) => {
      let newObj = null
      let indice = itemsSelected.length > 0 ? itemsSelected.findIndex( s => s.id_artista===obj.id_artista) : -1
      if(indice >=0){
        newObj = itemsSelected.filter(item => item.id_artista !== obj.id_artista)
      }else{
        let newArray = {
          id_artista: obj.id_artista,
          id_artista_creador_arte: null,
          nombre_apellido: obj.nombre_apellido,
        }
        newObj = [...itemsSelected, newArray]
      }
      setChanges(true)
      setItemsSelected(newObj)
    }

    //Con esta funcion nos vamos de regregreso al CU con la vista seleccionada
    const handleClickSelect = (isBack=false) => {
      let dataCache = {
        ...locationData?.oldDataCache,
        loadData: true,
        changes: changes ? changes : locationData?.oldDataCache?.changes,
        artistas_seleccionados: isBack ? null : itemsSelected
      }
      navigate(locationData?.from, { state: { dataCache: dataCache}})
    }

    //Con esta función preguntamos al usuario si esta seguro de cancelar los cambios.
    const handleBack = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        handleClickSelect(true)
      }
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      let page = locationData?.dataConfig?.currentPage ? locationData?.dataConfig?.currentPage : 1
      //si esto es mayor a 0 viene con artistas seleccionados
      if(locationData?.oldDataCache?.formFields?.artistas?.length > 0){
        setItemsSelected(locationData?.oldDataCache?.formFields?.artistas)
      }
      getData(true, page)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //con este efecto llamamos a la funcion para obtener la data al momento de cambiar de pagina, de numero de mostrar o filtrado
    useEffect(()=> {
      if(!inicio){
      //Si este parametro no esta vacio significa que el usuario escribio algo, se espera 1 segundo para buscar
        if(dataConfig.searchTerm !==""){
          setLoadingData(true)
          const delayDebounceFn = setTimeout(() => {
            getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
          }, 1000);
          return () => clearTimeout(delayDebounceFn);
        }else{
          getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataConfig.searchTerm, dataConfig.itemsPerPage, dataConfig.filter])

    //efecto para obtener los paises
    useEffect(() => {
      api.get("/get_pais_dominio").then(response => {
          setPaises(response.data)   
          setdominiosLoad({...dominiosLoadRef.current, get_pais_dominio: true})
      }).catch(err => {
      })
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    //con este efecto iniciamos el CU cuando todo esta cargado
    useEffect(() => {
      if (Object.values(dominiosLoadRef.current).every(value => value === true)) {
          setLoadingData(false)
      }
    },[dominiosLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CardContent
                      data={data}
                      dataConfig={dataConfig} // configuracion del cu, almacenamos paginacion, filtrado, busqueda, etc
                      getData={getData}
                      loadingData={loadingData}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      goToEditarItem={goToEditarItem}
                      goToAgregarItem={goToAgregarItem}
                      locationData={locationData}
                      handleSelectItem={handleSelectItem}
                      handleClickSelect={handleClickSelect}
                      paises={paises}
                      itemsSelected={itemsSelected}
                      handleBack={handleBack}
                      handleDeleteItem={handleDeleteItem}
                      //traducciones
                      translation={translation}
                      language={language}         
                      returnLanguageDateFormat={returnLanguageDateFormat}               
                    />                             
                  </CContainer>
                </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`