import React from "react";
import { 
    CContainer, 
    CRow, 
    CCol, 
} from '@coreui/react';
import styled from "styled-components";
import closeIcon from '../icons/Icon material-close.svg'
import infoIcon from '../icons/Icon ionic-md-information-circle-outline.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ModalContentBidi({
    onClose,
    modalName,
    styles,
    img,
    infoMsg,
    textDesc,
    desc,
}) {
    
    return( 
        <Container className="py-3 px-4 font-size-13">
            <CRow className="pb-3">
                <CCol xs={10}>
                    <span>{modalName}</span>
                </CCol>
                <CCol xs={2} className="text-end">
                    <img 
                    height="16" 
                    width="16" 
                    src={closeIcon} 
                    onClick={onClose}
                    className="cursor-pointer"
                    alt="close"
                    /> 
                </CCol>
            </CRow>
            <hr className="mt-1"/>   
            <CRow className="pb-3">
                {infoMsg && (
                    <CCol xs={12} className="font-size-12 mb-3">
                        <div>
                            <img
                            src={infoIcon}
                            alt=""
                            width="18"
                            />
                            <span className="ms-2 text-justify">{infoMsg}</span>
                        </div>
                    </CCol>
                )}
                <CCol xs={12} className="text-center">
                    <div className="image-container">
                        <LazyLoadImage
                            alt={"img"}
                            effect="blur" 
                            className={`foto ${styles ? styles : ''}`}
                            src={img ? `${img}` : "/sin-imagen.png"}
                            placeholderSrc={img ? `${img}` : "/sin-imagen.png"}
                        />
                    </div>
                    <div className="img-text py-1 mt-3 font-size-12">
                        {textDesc ? `${textDesc}:` : ""} {desc ? desc : ''}
                    </div>
                </CCol>
            </CRow>
        </Container> 
            
   ) 

} 

const Container = styled(CContainer)`
  .img-text{
    border-top: 1px solid var(--primary-color);
    background-color: #F7F5EF;
    margin: auto;
  }
  .imagen{
    width: 100% !important;
    background: white;
    overflow: hidden;
  }
  .imagen img{
    width: 100% !important;
  }
  .h-320{
    max-height: 320px !important;
  }
`