import React, { forwardRef, useState, useImperativeHandle } from 'react'
import {useNavigate} from "react-router-dom";
import {CSpinner} from '@coreui/react'
import infoIcon from '../icons/Icon ionic-md-information-circle-outline.svg'
import successIcon from '../icons/Icon awesome-check-circle.svg'
import ModalHook from './Modal';
import { globalFunctions } from "./globalFunctions";

const ModalContent = forwardRef((props, ref) => {
    //history para redireccionar a otro componente
    const navigate = useNavigate();

    const { goToInicio } = globalFunctions();

    //sesion
    let r = window.localStorage.getItem("remember_me") ? window.localStorage.getItem("remember_me") : false;
    let user =
        !JSON.parse(r) ? JSON.parse(window.sessionStorage.getItem("user"))
        : JSON.parse(window.localStorage.getItem("user"));

    //Variable de modales
    const [modal, setModal] = useState({
        show: false, // utilizado para abrir el modal
        iconVisible: false, // indica si va a desaparecer el icono del modal
        icon: null, // aqui colocamos el icono del modal
        modalSize: "md", //tamaño del modal, por defecto sera mediano
        hasTitle: false, // indica si el modal tiene titulo
        modalContentTitle: "", // titulo del modal (si hasTitle = true)
        modalContent: "", //mensaje del modal
        modalContentSecondary: null, // mensaje secundario del modal
        goTo: null, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
        hasButtons: true, // indica si el modal va a tener ambos botones
        cancelButton: false, // indica si el modal tendra boton de cancelar
        acceptText: "Aceptar", // es el texto del boton aceptar (si hasAcceptText = true)
        hasAcceptButton: false, // indica si el boton aceptar estara en el modal
        cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
        hasCancelText: "Cancelar", // string, Texto del boton cancelar,
        contentp: false, // indica si el modal tendra contenido propio
        showTextDiv: 0, // indica que contenido mostral cuando haya contenido propio
        msg: "Loading...", // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
    })

    //Con esto volvemos los valores del modal por defecto
    const resetModal = () => {
        setModal({
            show: false, // utilizado para abrir el modal
            iconVisible: false, // indica si va a desaparecer el icono del modal
            icon: null, // aqui colocamos el icono del modal
            modalSize: "md", //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: "", //mensaje del modal
            modalContentSecondary: null, // mensaje secundario del modal
            goTo: null, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: true, // indica si el modal va a tener ambos botones
            cancelButton: false, // indica si el modal tendra boton de cancelar
            acceptText: "Aceptar", // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: false, // indica si el boton aceptar estara en el modal
            cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: "Cancelar", // string, Texto del boton cancelar,
            contentp: false, // indica si el modal tendra contenido propio
            showTextDiv: 0, // indica que contenido mostral cuando haya contenido propio
            msg: "Loading...", // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
    }

    //Lanzamientos de mensajes del modal
    const showModalContent = {
      //Error de base de datos, si goTo es null cerramos el modal o redireccionamos al usuario si se coloca un numero
      errorDataBase: (actionGoTo=null, modalContent="Error al conectar con la base de datos.", acceptText="Aceptar") => {
        setModal({
            show: true, // utilizado para abrir el modal
            iconVisible: true, // indica si va a desaparecer el icono del modal
            icon: infoIcon, // aqui colocamos el icono del modal
            modalSize: null, //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: modalContent ? modalContent : "Error al conectar con la base de datos.", //mensaje del modal
            modalContentSecondary: null, // mensaje secundario del modal
            goTo: actionGoTo, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: true, // indica si el modal va a tener ambos botones
            cancelButton: false, // indica si el modal tendra boton de cancelar
            acceptText: acceptText ? acceptText : "Aceptar", // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: true, // indica si el boton aceptar estara en el modal
            cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: "Cancelar", // string, Texto del boton cancelar,
            contentp: false, // indica si el modal tendra contenido propio
            showTextDiv: 0, // indica que contenido mostral cuando haya contenido propio
            msg: null, // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
      },
      
      //Mensaje de error de autenticazion
      authentication: (actionGoTo=1, modalContent="Autorización requerida, por favor inicie sesión.", acceptText="Aceptar") => {
        setModal({
            show: true, // utilizado para abrir el modal
            iconVisible: true, // indica si va a desaparecer el icono del modal
            icon: infoIcon, // aqui colocamos el icono del modal
            modalSize: null, //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: modalContent ? modalContent : "Autorización requerida, por favor inicie sesión.", //mensaje del modal
            modalContentSecondary: null, // mensaje secundario del modal
            goTo: actionGoTo ? actionGoTo : 1, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: true, // indica si el modal va a tener ambos botones
            cancelButton: false, // indica si el modal tendra boton de cancelar
            acceptText: acceptText ? acceptText : "Aceptar", // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: true, // indica si el boton aceptar estara en el modal
            cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: "Cancelar", // string, Texto del boton cancelar,
            contentp: false, // indica si el modal tendra contenido propio
            showTextDiv: 0, // indica que contenido mostral cuando haya contenido propio
            msg: null, // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
      },

      info: (actionGoTo, modalContent, contentSecondary=null, cancelButton=false, acceptText="Aceptar", cancelText="Cancelar") => {
        setModal({
            show: true, // utilizado para abrir el modal
            iconVisible: true, // indica si va a desaparecer el icono del modal
            icon: infoIcon, // aqui colocamos el icono del modal
            modalSize: null, //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: modalContent, //mensaje del modal
            modalContentSecondary: contentSecondary ? contentSecondary : null, // mensaje secundario del modal
            goTo: actionGoTo, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: true, // indica si el modal va a tener ambos botones
            cancelButton: cancelButton, // indica si el modal tendra boton de cancelar
            acceptText: acceptText ? acceptText : "Aceptar", // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: true, // indica si el boton aceptar estara en el modal
            cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: cancelText ? cancelText : "Cancelar", // string, Texto del boton cancelar,
            contentp: false, // indica si el modal tendra contenido propio
            showTextDiv: 0, // indica que contenido mostral cuando haya contenido propio
            msg: null, // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
      },

      success: (actionGoTo, modalContent, contentSecondary=null, cancelButton=false, acceptText="Aceptar", cancelText="Cancelar", cancelAction=null) => {
        setModal({
            show: true, // utilizado para abrir el modal
            iconVisible: true, // indica si va a desaparecer el icono del modal
            icon: successIcon, // aqui colocamos el icono del modal
            modalSize: null, //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: modalContent, //mensaje del modal
            modalContentSecondary: contentSecondary ? contentSecondary : null, // mensaje secundario del modal
            goTo: actionGoTo, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: true, // indica si el modal va a tener ambos botones
            cancelButton: cancelButton, // indica si el modal tendra boton de cancelar
            acceptText: acceptText ? acceptText : "Aceptar", // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: true, // indica si el boton aceptar estara en el modal
            cancelAction: cancelAction ? cancelAction : null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: cancelText ? cancelText : "Cancelar", // string, Texto del boton cancelar,
            contentp: false, // indica si el modal tendra contenido propio
            showTextDiv: 0, // indica que contenido mostral cuando haya contenido propio
            msg: null, // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
      },

      //Contenido propio
      ownContent: (modalSize="lg") => {
        setModal({
            show: true, // utilizado para abrir el modal
            iconVisible: false, // indica si va a desaparecer el icono del modal
            icon: null, // aqui colocamos el icono del modal
            modalSize: modalSize ? modalSize : "lg", //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: "", //mensaje del modal
            modalContentSecondary: null, // mensaje secundario del modal
            goTo: null, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: false, // indica si el modal va a tener ambos botones
            cancelButton: false, // indica si el modal tendra boton de cancelar
            acceptText: null, // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: false, // indica si el boton aceptar estara en el modal
            cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: "Cancel", // string, Texto del boton cancelar,
            contentp: true, // indica si el modal tendra contenido propio
            showTextDiv: 2, // indica que contenido mostral cuando haya contenido propio
            msg: null, // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
      },
      //Cargando
      loading: (msg="Cargando...") => {
        setModal({
            show: true, // utilizado para abrir el modal
            iconVisible: false, // indica si va a desaparecer el icono del modal
            icon: null, // aqui colocamos el icono del modal
            modalSize: null, //tamaño del modal, por defecto sera mediano
            hasTitle: false, // indica si el modal tiene titulo
            modalContentTitle: "", // titulo del modal (si hasTitle = true)
            modalContent: "", //mensaje del modal
            modalContentSecondary: null, // mensaje secundario del modal
            goTo: null, // number, indica a donde va a ir el usuario al dar clic en aceptar. Si es null cierra el modal
            hasButtons: false, // indica si el modal va a tener ambos botones
            cancelButton: false, // indica si el modal tendra boton de cancelar
            acceptText: null, // es el texto del boton aceptar (si hasAcceptText = true)
            hasAcceptButton: false, // indica si el boton aceptar estara en el modal
            cancelAction: null, // number, indica a donde va a ir el usuario al dar clic en cancelar. Si es null cierra el modal
            hasCancelText: "Cancel", // string, Texto del boton cancelar,
            contentp: true, // indica si el modal tendra contenido propio
            showTextDiv: 1, // indica que contenido mostral cuando haya contenido propio
            msg: msg ? msg : "Cargando...", // Muestra el mensaje que mostraremos en el modal cuando se este esperando por una accion
        })
      },
    } 

    //Maneja los eventos del botón de confirmacion del modal
    const handleAcceptButton = () => {
      switch (modal.goTo) { 
        case 1:
          navigate('/iniciar-sesion')
          break;
        case 2:
          goToInicio(navigate, user)
          break;
        case 3:
          props.acceptAction()
          break;
        case 4:
          showModalContent.ownContent()
          break;
        case 5:
          navigate('/admin-artes')
          break;
        case 6:
          navigate('/admin-artistas')
          break;
        case 7:
          navigate('/registro-arte')
          break;
        case 8:
          navigate('/mis-artes')
          break;
        default:
          resetModal()
          break;
      }
    };

    //Maneja los eventos del botón negacion del modal
    const handleCancelButton = () => {
      switch (modal.cancelAction) {
        case 1:
          showModalContent.ownContent()
          break;
        case 2:
          showModalContent.ownContent("md")
          break;
        case 3:
          navigate('/admin-artes')
          break;
        case 4:
          props.cancelButtonAction()
          break;
        default:
          resetModal();
          break;
      }
    };

    //retornamos el componente que va dentro del modal si hay contenido propio
    const textdivContent = () => {
      let elements = null
      switch (modal.showTextDiv) {
          case 1:
            elements = (
              <div className="text-center py-4">
                <CSpinner size="sm"/>
                  <CSpinner size="sm" variant="grow"/>
                  <div>{modal.msg}</div>
              </div>
              
            )
            break; 
          case 2:
            elements = (
               <>{props.modalContentInside()}</> 
            )
            break;     
          default:
            break;
      }
      return elements
    };

    //con esto redireccionamos todo
    useImperativeHandle(ref, () => ({
    //funciones que seran llamadas en el padre
        content: showModalContent,
        resetModal: resetModal,
    }));

  return (
          <ModalHook
            show={modal.show}
            onClose={resetModal}
            size={modal.modalSize} 
            hasTitle={modal.hasTitle}
            contentTitle={modal.modalContentTitle}
            content={modal.modalContent}
            contentSecondary={modal.modalContentSecondary}
            buttons={modal.hasButtons}
            leftButton={modal.cancelButton}
            leftButtonHandle={handleCancelButton}
            leftButtonName={modal.hasCancelText}
            iconVisible={modal.iconVisible}
            icon={modal.icon}
            rightButtonName={modal.acceptText}
            rightButtonHandle={handleAcceptButton} 
            //Si el modal es con contenido propio
            contentp={modal.contentp}
            textdiv={textdivContent()}
          />
  )
})
export default ModalContent