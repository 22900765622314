import React, {useState} from 'react'
import {
  CForm,
  CInputGroup,
  CFormLabel,
  CFormFeedback,
  CRow,
  CCol,
} from '@coreui/react'
import styled from 'styled-components'
import {
  CInputGroupImg, 
  CInput, 
  InputGroupText, 
  Button,
} from '../../../hooks/styled'
//icons
import LockIcon from '../../../icons/Icon awesome-lock.svg';
import EyeIcon from "../../../icons/icon-feather-eye.svg";
import EyeOffIcon from "../../../icons/icon-feather-eye-off.svg";
import logoGold from "../../../icons/Icon-logo-dorado.svg";
export default function ChangePassword({
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  handleSubmit,
  admin,
  //traducciones
  translation={},
  language="",
}) {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    return (
              <ContainerLogin className={`user-container`}>
                <CRow className="">
                  <ColLeft md={12} className="border-colLeftLogin px-4 py-4"> 
                    <div className="text-center">
                      <img
                        src={logoGold}
                        alt="correo"
                        width="80"
                        height="80"
                      />
                    </div>
                    <CForm 
                      className="mt-4 px-3"
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <CFormLabel className="title mb-4">{translation[language]?.titulo_cambio_contrasena ? translation[language]?.titulo_cambio_contrasena : "Cambio de contraseña"}</CFormLabel>

                      <CFormLabel 
                        className="d-block font-size-default primary-text-color" 
                      >
                        {translation[language]?.ingresar_clave ? translation[language]?.ingresar_clave : "Ingresa la nueva clave"}
                      </CFormLabel>
                      <CInputGroup className={`${formErrors.password ? 'mb-0' : 'mb-4'}`}>
                        <InputGroupText className={`n-bg-gray ${formErrors.password ? 'invalid-border-color' : 'border-color-gray'}`}>
                          <img
                            src={LockIcon}
                            alt="password"
                            width="18"
                            height="18"
                          />
                        </InputGroupText>
                        <CInput
                          type={showPassword ? "text" : "password"}
                          className={`border-start-0 border-end-0 ${formErrors.password ? 'background-image-none' : ''}`}
                          name="password"
                          value={formFields.password}
                          invalid={formErrors.password}
                          onChange={handleChange}
                          onInput={!admin ? (e) => e.target.value = e.target.value.slice(0, 6) : false}
                        />
                        <CInputGroupImg
                          className={`${formErrors.password ? 'invalid-border-color' : 'border-color-gray'}`}
                        >
                          <img 
                              src={!showPassword ? EyeOffIcon : EyeIcon}
                              alt="eyes" 
                              width="20"
                              height="20"
                              onClick={() => setShowPassword(!showPassword)}
                              className="cursor-pointer"
                          /> 
                        </CInputGroupImg>
                      </CInputGroup>
                      {formErrors.password && (
                        <CFormFeedback invalid className="d-block mb-4">{formErrorsText.password}</CFormFeedback> 
                      )}

                      <CFormLabel 
                        className="font-size-default primary-text-color" 
                      >
                        {translation[language]?.confirmar_clave ? translation[language]?.confirmar_clave : "Confirma la nueva clave"}
                      </CFormLabel>
                      <CInputGroup>
                          <InputGroupText className={`n-bg-gray ${formErrors.confirmPassword ? 'invalid-border-color' : 'border-color-gray'}`}>
                            <img
                              src={LockIcon}
                              alt="confirmPassword"
                              width="18"
                              height="18"
                            />
                          </InputGroupText>
                        <CInput
                          type={showPassword2 ? "text" : "password"}
                          className={`border-start-0 border-end-0 ${formErrors.confirmPassword ? 'background-image-none' : ''}`}
                          name="confirmPassword"
                          value={formFields.confirmPassword}
                          invalid={formErrors.confirmPassword}
                          onChange={handleChange}
                          onInput={!admin ? (e) => e.target.value = e.target.value.slice(0, 6) : false}
                        />
                        <CInputGroupImg
                          className={`${formErrors.confirmPassword ? 'invalid-border-color' : 'border-color-gray'}`}
                        >
                          <img 
                              src={!showPassword2 ? EyeOffIcon : EyeIcon}
                              alt="eyes" 
                              width="20"
                              height="20"
                              onClick={() => setShowPassword2(!showPassword2)}
                              className="cursor-pointer"
                          /> 
                        </CInputGroupImg>
                      </CInputGroup>
                      {formErrors.confirmPassword && (
                        <CFormFeedback invalid className="d-block">{formErrorsText.confirmPassword}</CFormFeedback> 
                      )}
                      <div className="mt-5">
                        <Button 
                          type="submit"
                        >
                          {translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}
                        </Button>                    
                      </div>
                    </CForm>
                  </ColLeft>
                </CRow>
              </ContainerLogin>
    )
}

const ContainerLogin = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;  
`;

const ColLeft = styled(CCol)`
  background-color: #ffffff;
  border-radius: 0rem;
  border: 1px solid #B2A169;
`