import React from "react";
import { 
    CContainer, 
    CRow, 
    CCol, 
} from '@coreui/react';
import styled from "styled-components";
import closeIcon from '../../../icons/Icon material-close.svg'
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ModalContentBidi({
    item, 
    onClose,
    //traducciones
    translation={},
    language="",    
}) {
    
    return( 
        <Container className="py-3 px-4 font-size-13">
            <CRow className="pb-3">
                <CCol xs={10}>
                    <span>{`${translation[language]?.titulo_codigo_bidi ? translation[language]?.titulo_codigo_bidi : "Código BIDI"}`}</span>
                </CCol>
                <CCol xs={2} className="text-end">
                    <img 
                    height="16" 
                    width="16" 
                    src={closeIcon} 
                    onClick={onClose}
                    className="cursor-pointer"
                    alt="close"
                    /> 
                </CCol>
            </CRow>
            <hr className="mt-1"/>   
            <CRow className="pb-3">
                <CCol xs={12} className="font-size-12 mb-3">
                    <div>
                        <img
                        src={infoIcon}
                        alt=""
                        width="18"
                        />
                        <span className="ms-2 text-justify"></span>
                        {`${translation[language]?.hint_escanear_bidi ? translation[language]?.hint_escanear_bidi : "Cuando se active la cámara, apunta con el teléfono hacia esta pantalla para escanear el código."}`}
                    </div>
                </CCol>
                <CCol xs={12} className="text-center">
                    <div className="bidi">
                        <LazyLoadImage
                            alt={"bidi"}
                            effect="blur" 
                            className='foto'
                            src={item?.bidi ? `${item?.bidi}` : "/sin-imagen.png"}
                            placeholderSrc={item?.bidi ? `${item?.bidi}` : "/sin-imagen.png"}
                        />
                    </div>
                    <div className="bidi-code py-1 font-size-12">
                        {`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}: {item?.codigo_pieza ? item?.codigo_pieza : ''}
                    </div>
                </CCol>
            </CRow>
        </Container> 
            
   ) 

} 

const Container = styled(CContainer)`
  .bidi-code{
    max-width: 250px;
    border-top: 1px solid var(--primary-color);
    background-color: #F7F5EF;
    margin: auto;
  }
  .bidi{
    height: 260px !important;
    width: 260px !important;
    margin: auto !important;
    background: white;
    overflow: hidden;
  }
  .bidi img{
    height: 260px !important;
    width: 260px !important;
  }
`