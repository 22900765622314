import React , { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import {
  CContainer,
} from '@coreui/react'
import styled from 'styled-components'
import CardContent from './CardContent';
import ModalContent from '../../hooks/ModalContent'
import ModalCropper from '../../hooks/ModalCropper'
import ModalContentImg from '../../hooks/ModalContentImg'
import api from '../../api/api'

export default function CRUDSeries({
  user,
  //traducciones
  translation={},
  language="",
}) {
    //Variables
    // const navigate = useNavigate();

    //modal
    const showModal = useRef(); //referencia del hijo
    const inputFile = useRef(null);  // input
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [showTextDiv, setShowTextDiv] = useState(null); //accion de aceptar del modal
    
    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    //variable que nos indica si hubo cambios
    const [changes, setChanges] = useState(false);
  
    const [itemSeleccionado, setItemSeleccionado] = useState(null); //el item que se selecciona

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      edit: false,
      id_serie: null,
      nombre: "",
      descripcion: "",
      imagen: null,
    });


    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      nombre: false,
      descripcion: false,
      imagen: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      nombre: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      descripcion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      imagen: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });

    //esta variable se utiliza para agregar una nueva imagen
    const [newImage, setNewImage] = useState(null)

    //esta variable se utiliza para desplegar el modal del cropper
    const [showModalCropper, setShowModalCropper] = useState(false);

    //configuracion de busqueda, filtrado y paginacion
    const [dataConfig, setDataConfig] = useState({
      searchTerm: locationData?.dataConfig?.searchTerm ? locationData?.dataConfig?.searchTerm : "",
      filter: locationData?.dataConfig?.filter ? locationData?.dataConfig?.filter : 0,
      itemsPerPage: locationData?.dataConfig?.itemsPerPage ? locationData?.dataConfig?.itemsPerPage : 6,
      cantRegistros: 0,
      currentPage: 1,
      lastPage: 1,
      minPageNumberLimit: locationData?.dataConfig?.minPageNumberLimit ? locationData?.dataConfig?.minPageNumberLimit : 0,
      maxPageNumberLimit: locationData?.dataConfig?.maxPageNumberLimit ? locationData?.dataConfig?.maxPageNumberLimit : 5,
      //estos valores nunca se actualizan para saber el minimo y maximo
      pageNumberLimit: 5,
      pageMinNumber: 0,

    });
    //con esta variable validamos si el CU se esta abriendo por primera vez
    const [inicio, setInicio] = useState(true);
    const [loadingData, setLoadingData] = useState(true);

    //arreglo que almacena las artes
    const [data, setData] = useState([]);

    //funciones

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          setForm(null)
          showModal?.current?.resetModal()
          break;
        case 2:
          deletePicture()
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value } = variable;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChanges(true)
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    //funcion que valida los campos del formulario
    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(!formFields['nombre']) {
        errorsObj['nombre'] = true
        errorsObjText['nombre'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['descripcion']) {
        errorsObj['descripcion'] = true
        errorsObjText['descripcion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //con esto seteamos el formulario y lo limpiamos. Si item no es null significa que estamos editando una serie
    const setForm = (item=null) => {
      //limpiamos el form
      setFormFields({
        ...formFields,
        edit: item ? true : false,
        id_serie: item?.id_serie ? item.id_serie : null,
        nombre: item?.nombre ? item.nombre : "",
        descripcion: item?.descripcion ? item.descripcion : "",
        imagen: item?.imagen ? item.imagen : null,
      })
      //limpiamos los errores
      setFormErrors({
        ...formErrors,
        nombre: false,
        descripcion: false,
        imagen: false,
      })
      //limpiamos el texto de los errores
      setFormErrorsText({
        ...formErrorsText,
        nombre: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        descripcion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        imagen: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      })
      //limpiamos el changes
      setChanges(false)
    }

    //con esto agregamos una foto y llamamos al modal cropper para ajustar la imagen
    const handleChangeImage = (e, variable) => {
      inputFile.current.click();   
      if (e !== undefined) {   
        if (e.target.files !== undefined) {  
          const file = e.target.files[0];  
          var reader = new FileReader();
          let base64 = null; 
          if(file !== undefined) {  
            reader.readAsDataURL(file); 
            reader.onload = function(event) {   
              base64 = event.target.result;
              let newObj = {
                url: base64,
                variable: variable,
              }
              setNewImage(JSON.parse(JSON.stringify(newObj)))   
              if(base64) {
                setShowModalCropper(true); 
              }
            };  
          } 
        }
      }  
      setTimeout(() => {
        inputFile.current.value = "";
      }, 1500);
    }

    //luego de ajustar la foto con el cropper, con esto agregamos la foto a la variable de estado que almacena los datos de la serie.
    const onSetPhoto = (obj) => {
      const {url, variable} = obj   
      let fieldsObj = { ...formFields, [variable]: url};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setNewImage(null)
      setChanges(true)
    };

    //Con esta función preguntamos al usuario si esta seguro de cancelar los cambios.
    const discardChanges = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : ""}` //texto de boton de cancelar
        );
        return;
      }else{
        setForm(null)
        showModal?.current?.resetModal()
      }
    }

    // con esto colocamos contenido propio en el modal
    const textdivContent = () => {
      let elements = null
      switch (showTextDiv) {
          case 1:
            elements = (
              <ModalContentImg 
                modalName={`${translation[language]?.imagen ? translation[language]?.imagen : "Imagen"}`}
                img={itemSeleccionado.imagen}
                styles="h-320"
                desc={itemSeleccionado.nombre}
                onClose={() => {
                  setItemSeleccionado(null)
                  showModal?.current?.resetModal()
                }}
              />
            )
            break;
          default:
            break;
      }
      return elements
    };

    //Para tomar accion luego de darle clic a ver imagen
    const handleClickShowImg = (item) => {
      setItemSeleccionado(item)
      setShowTextDiv(1)
      showModal?.current?.content?.ownContent("md")
    }

    //con esto preguntamos si esta seguro de eliminar la imagen
    const handleDeletePicture = () => {
      setAcceptAction(2)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_eliminar_imagen ? translation[language]?.msg_eliminar_imagen : "¿Estás seguro de eliminar esta imagen?"}`, // mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
    }

    //con esto eliminamos la imagen de la variable
    const deletePicture = () => {
      let fieldsObj = { ...formFields, imagen: null};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChanges(true)
      showModal?.current?.resetModal()
    }

    //Con esta funcion escuchamos los cambios en el buscador
    const handleChangeFiltros = (e) => {
      //Cuando se coloca algo en el buscador
      if (e.target.name === "search") {
        if (e.target.value === "") {
          setDataConfig({...dataConfig, searchTerm: ""})
        } else {
          setDataConfig({...dataConfig, searchTerm: e.target.value})
        }
      }
    }

    //Con esta funcion obtenemos las series
    const getData = (inicio = true, page = 1, maxPageNumberLimit=null, minPageNumberLimit=null) => {
      window.scrollTo(0, 0);
      setLoadingData(true)
      let newDataConfig;
      let sr = dataConfig
      // Esta es la variable que almacenara los parametros que enviaremos al endpoint
      let obj = {
        page: page,
        page_size: sr.itemsPerPage,
      };
      let terms;
      //si hay busqueda le agregamos los parametros de busqueda
      if (sr.searchTerm !=="") {
        terms = {
          ...obj,
          nombre: sr.searchTerm,
        };
      } else {
        terms = obj
      }
      const newConfig = {...config, params: terms}
      api.get(`/get_series`, newConfig).then(response => {
        newDataConfig = {
          ...dataConfig,
          cantRegistros: response.data[1].total,
          lastPage: response.data[1].last_page,
          currentPage: page,
          maxPageNumberLimit: parseInt(maxPageNumberLimit)>=0 ? maxPageNumberLimit : dataConfig.maxPageNumberLimit, 
          minPageNumberLimit: parseInt(minPageNumberLimit)>=0 ? minPageNumberLimit : dataConfig.minPageNumberLimit,
        }
        setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
        setLoadingData(false)
        setData(response.data[0])
        if(inicio){
          setInicio(false)
        }
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          case 404:
            newDataConfig = {
              ...dataConfig,
              cantRegistros: 0,
              lastPage: 1,
              currentPage: 1,
              maxPageNumberLimit: dataConfig.pageNumberLimit, 
              minPageNumberLimit: dataConfig.pageMinNumber,
            }
            setDataConfig(JSON.parse(JSON.stringify(newDataConfig)))
            setLoadingData(false)
            setData([])
            if(inicio){
              setInicio(false)
            }         
          break;
          default:
            break;
        }
      })
    }

    //con esta funcion ingresamos o actualizamos una serie
    const putSerie = () =>{
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')

      let newObj = {
        series: [{
          id_serie: formFields.id_serie ? formFields.id_serie : null,
          nombre: formFields.nombre ? formFields.nombre : null,
          descripcion: formFields.descripcion ? formFields.descripcion : null,
          imagen: formFields.imagen ? formFields.imagen : null,
        }]        
      }
      
      api.put("/put_serie", newObj, config).then(response => {
        setForm(null)
        getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)  
        showModal?.current?.content?.success(
          null, //accion que tomara 
          `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 409:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_nombre_coleccion ? translation[language]?.msg_error_nombre_coleccion : "El nombre de colección ya se encuentra registrado. Por favor, ingresa uno diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );   
            break;
          case 415:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );     
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => { 
      e.preventDefault()
      if(changes){
        if (!validateFields()) {
          return       
        }
        //si no hay errores
        putSerie()
      }else{
        setForm(null)
      }
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
        return;
      }
      let page = locationData?.dataConfig?.currentPage ? locationData?.dataConfig?.currentPage : 1
      getData(true, page)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //con este efecto llamamos a la funcion para obtener la data al momento de cambiar de pagina, de numero de mostrar o filtrado
    useEffect(()=> {
      if(!inicio){
      //Si este parametro no esta vacio significa que el usuario escribio algo, se espera 1 segundo para buscar
        if(dataConfig.searchTerm !==""){
          setLoadingData(true)
          const delayDebounceFn = setTimeout(() => {
            getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
          }, 1000);
          return () => clearTimeout(delayDebounceFn);
        }else{
          getData(false, 1, dataConfig.pageNumberLimit, dataConfig.pageMinNumber)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataConfig.searchTerm, dataConfig.itemsPerPage, dataConfig.filter])

    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])    

    
    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                modalContentInside={textdivContent}
              />
              <ModalCropper 
                configImg={newImage} 
                aspectRatio={16/9}  
                onSetPhoto={onSetPhoto} 
                show={showModalCropper} 
                setShow={setShowModalCropper}
                //traducciones
                translation={translation}
                language={language} 
              />
              <Screen>
                <CContainer className='px-4'> 
                  <CardContent
                    data={data}
                    dataConfig={dataConfig} // configuracion del cu, almacenamos paginacion, filtrado, busqueda, etc
                    getData={getData}
                    loadingData={loadingData}
                    setDataConfig={setDataConfig}
                    handleChangeFiltros={handleChangeFiltros}
                    setForm={setForm}
                    discardChanges={discardChanges}
                    formFields={formFields}
                    formErrors={formErrors}
                    formErrorsText={formErrorsText}
                    handleChange={handleChange}
                    changes={changes}
                    handleSubmit={handleSubmit}
                    handleChangeImage={handleChangeImage}
                    inputFile={inputFile}
                    handleClickShowImg={handleClickShowImg}
                    handleDeletePicture={handleDeletePicture}
                    translation={translation}
                    language={language}
                  />                             
                </CContainer>
              </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`