import React, {useState, useRef} from "react";
import {
  CContainer,
  CFormLabel,
  CTooltip
} from '@coreui/react'
import {
  CInput, 
} from './styled'
import styled from "styled-components";
import { Slide } from 'react-slideshow-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-slideshow-image/dist/styles.css'
import borrarIcon from '../icons/Icono borrar - dorado.svg'
import camaraIcon from '../icons/Icono camara - dorado.svg'
import favOnIcon from '../icons/Icono favorito - on.svg'
import favOffIcon from '../icons/Icono favorito - off.svg'

function Carousel({
  imagenes,
  deletePicture,
  handleChangeImage,
  typeImage,
  propertyName,
  changePictureFav,
  styles,
  responsive=false,
  hiddenContent=false,
  //traducciones
  translation={},
  language="",
}) {

    const [booFav, set_booFav] = useState((imagenes.length > 0) ? (imagenes?.find(item => item.boo_favorita)?.index ? imagenes?.find(item => item.boo_favorita)?.index : null) : null);
    const inputFile = useRef(null);  // input
    //para cambiar la foto favorita
    const changeFav = (index) => {
      let selectedIndex = (booFav === index) ? null : index
      let allFalse = selectedIndex ? false : true
      changePictureFav(index, typeImage, propertyName, allFalse)
      set_booFav(selectedIndex)
    }

    const buttonStyle = {
        width: "30px",
        height: "30px",
        background: 'var(--primary-color)',
        border: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const properties = {
        prevArrow: <button type="button" style={{ ...buttonStyle }}><svg width="18" height="18" viewBox="0 0 24 24" fill="#fff" style={{marginRight: '-4px'}}><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path></svg></button>,
        nextArrow: <button type="button" style={{ ...buttonStyle }}><svg width="18" height="18" viewBox="0 0 24 24" fill="#fff" style={{marginRight: '-8px'}}><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></button>
    }

    const responsiveSettings = [
      {
          breakpoint: 800,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3
          }
      },
      {
          breakpoint: 500,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      }
    ];

    return (
            <Content>
              {imagenes.filter(fadeImage => !fadeImage.delete).length > 0 ? (
                <Slide 
                  indicators={true} 
                  infinite={false}
                  autoplay={false}
                  responsive={responsive ? responsiveSettings : []}
                  {...properties}
                >
                  {imagenes.filter(fadeImage => !fadeImage.delete).map((fadeImage, index) => (
                    <div key={index}>
                      <div className="each-fade" key={index}>
                        <div className={`image-container`}>
                          <LazyLoadImage
                            alt={`pic${index}`}
                            effect="blur" 
                            className={`${styles ? styles : ''}`}
                            src={fadeImage.imagen}
                            placeholderSrc={fadeImage.imagen}
                          />
                        </div>
                      </div>
                      {!hiddenContent && (
                        <>
                          <CTooltip content={`${translation[language]?.hint_boton_eliminar_imagen ? translation[language]?.hint_boton_eliminar_imagen : "Eliminar"}`}>
                            <img 
                              className='icon-border ms-0 mt-2' 
                              src={borrarIcon}  
                              alt="icon"
                              width="28px"
                              height="28px"
                              onClick={()=> deletePicture(fadeImage.index, "imagenes")}
                            />
                          </CTooltip>
                          {typeImage === 1 && (
                            <CTooltip content={`${translation[language]?.hint_favorita ? translation[language]?.hint_favorita : "Favorita"}`}>
                              <img 
                                className='icon-border ms-2 mt-2' 
                                src={fadeImage.index === booFav ? favOnIcon : favOffIcon}  
                                alt="icon"
                                width="28px"
                                height="28px"
                                onClick={()=> changeFav(fadeImage.index)}
                              />
                            </CTooltip>                            
                          )}
                        </>   
                      )}             
                    </div>
                  ))}
                </Slide>   
              ) : (
                <>
                <div className={`noPic ${styles ? styles : ''}`}>
                    <img 
                      src={'sin-imagen.png'}  
                      alt="pic"
                    />                   
                </div>
                </>
              )} 
              {!hiddenContent && (
                <CContainer className="bg-custom py-3 d-flex align-items-center justify-content-center">
                  <div className="image-upload">
                    <CFormLabel className="mt-0 mb-0" htmlFor={`file-input${typeImage}`}>
                      <CTooltip content={`${translation[language]?.hint_seleccione_imagen ? translation[language]?.hint_seleccione_imagen : "Seleccione la imagen a insertar"}`}>                
                        <img 
                          className='ms-0 cursor-pointer' 
                          src={camaraIcon}  
                          alt="icon"
                          width="25px"
                          height="25px"
                        /> 
                      </CTooltip>
                    </CFormLabel>
                    <CInput 
                      className="d-none" 
                      id={`file-input${typeImage}`}
                      name={`file-input${typeImage}`}
                      type="file" 
                      accept="image/*"
                      onChange={(e) => handleChangeImage(e, propertyName, typeImage, inputFile)} 
                      ref={inputFile}
                    />
                  </div>
                </CContainer>   
              )}          
            </Content>    
    );
}
const Content = styled.div`
  .lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
  }
  .image-container{
    text-align: center;
  }
`
export default Carousel
