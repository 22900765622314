import React , { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  CContainer,
  CForm
} from '@coreui/react'
import styled from 'styled-components'
import FormularioArte from './FormularioArte';
import ModalContent from '../../../hooks/ModalContent'
import ModalContentHelp from './ModalContentHelp'
import { globalFunctions } from "../../../hooks/globalFunctions";
import api from '../../../api/api'
import { sendEmail } from '../../../hooks/sendEmail';
import SysmodelersIcon from '../../../icons/Logo-Original.svg'

export default function RegistroArtes({
  user,
  variablesEnv,
  //traducciones
  translation={},
  language="",
  setLanguage,
  idiomas=[],
}) {

    //Variables
    const navigate = useNavigate();
    const showModal = useRef(); //referencia del hijo
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [showTextDiv, setShowTextDiv] = useState(null); //accion de que modal mostrar
    const [array, setArray] = useState([]); //este es el arreglo que enviaremos entre las vistas

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      email: user?.email ? user?.email : "",
      codigo_pieza: "",
      codigo_invitacion: "",
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      email: false,
      codigo_pieza: false,
      codigo_invitacion: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      email: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      codigo_pieza: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      codigo_invitacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });

    const { 
      validationEmailAddress,
      validationIsAlphaNumeric
    } = globalFunctions();

    //funciones
    

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value } = variable;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    //funcion que se utiliza para validar los campos
    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(!formFields['email']) {
        errorsObj['email'] = true
        errorsObjText['email'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationEmailAddress(formFields['email'])){
        errorsObj['email'] = true
        errorsObjText['email'] = translation[language]?.correo_invalido ? translation[language]?.correo_invalido : "Correo Inválido"
        validState = false            
      }

      if(!formFields['codigo_pieza']) {
        errorsObj['codigo_pieza'] = true
        errorsObjText['codigo_pieza'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsAlphaNumeric(formFields['codigo_pieza'])){
        errorsObj['codigo_pieza'] = true
        errorsObjText['codigo_pieza'] = translation[language]?.campo_alfanum ? translation[language]?.campo_alfanum : "Campo alfanumérico"
        validState = false            
      }

      if(!formFields['codigo_invitacion']) {
        errorsObj['codigo_invitacion'] = true
        errorsObjText['codigo_invitacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsAlphaNumeric(formFields['codigo_invitacion'])){
        errorsObj['codigo_invitacion'] = true
        errorsObjText['codigo_invitacion'] = translation[language]?.campo_alfanum ? translation[language]?.campo_alfanum : "Campo alfanumérico"
        validState = false            
      }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          navigate('/consultar-artes', { state: { dataCache: array}})
          break;
        case 2:
          navigate('/propietario-registro', { state: { dataCache: array}})
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    // con esto colocamos contenido propio en el modal
    const textdivContent = () => {
      let elements = null
      switch (showTextDiv) {
          case 1:
            elements = (
              <ModalContentHelp
                translation={translation}
                language={language}
                onClose={() => {
                  setShowTextDiv(null)
                  showModal?.current?.resetModal()
                }}
              />
            )
            break;
          default:
            break;
      }
      return elements
    };

    //para enviar el correo
    const postEmail = async (obj) => {
      const email = [obj.email];
      const AppName  = variablesEnv?.REACT_APP_NAME
      const logo  = variablesEnv?.REACT_APP_LOGO_URL
      const subject = `${translation[language]?.titulo_correo ? translation[language]?.titulo_correo : "Código de acceso"}`;
      const nombre = obj.nombre ? (obj.nombre?.split(' ')[0] ? obj.nombre?.split(' ')[0] : obj.nombre) : obj.email?.split('@')[0] ? obj.email?.split('@')[0] : obj.email;
      const saludo = `${translation[language]?.msg_correo_saludo ? translation[language]?.msg_correo_saludo : 'Hola,'} ${nombre}`
      const origin = "login2";
      let mensaje = `
        <div style="white-space: normal;color:#B2A169;font-size:12pt;padding: 5px 1.5rem 0px 1.5rem;">    
          <p style="color:#B2A169; margin-bottom: 0px!important"> ${translation[language]?.msg_correo_part1 ? translation[language]?.msg_correo_part1 : "Para continuar con el registro o consultar tus Artes, la app te solicitará ingresar el siguiente"}</p>
          <p style="color:#B2A169; margin-bottom: 0px!important; margin-top: 0px!important"><b>${translation[language]?.msg_correo_part2 ? translation[language]?.msg_correo_part2 : "código de seguridad:"} {} </b></p>
          <p style="color:#B2A169;margin-bottom: 0px!important; margin-top: 3rem !important">${translation[language]?.msg_correo_part3 ? translation[language]?.msg_correo_part3 : "¡Hasta pronto!"}</p>
          <p style="color:#B2A169; margin-top: 0px!important;">${AppName}</p>
        </div>
      `
      //Función que envía el correo
      let result = await sendEmail(email, subject, saludo, mensaje, origin, null, logo)
      if(result.response){
        setArray(obj)
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_codigo_correo ? translation[language]?.msg_codigo_correo : "Para continuar, ubica el código de seguridad que recibiste en tu correo electrónico."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
      }
    }

    //con esta funcion registramos una pieza de un arte a un propietario
    const postRegistroArte = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...');
      let newObj = {
        email: formFields.email ? formFields.email : "",
        codigo_pieza: formFields.codigo_pieza ? formFields.codigo_pieza : "",
        codigo_invitacion: formFields.codigo_invitacion ? formFields.codigo_invitacion : "",
        indice_idioma: parseInt(language),
      }

      api.post("/post_registro_arte", newObj).then(response => {
        let newObj2 = {
          ...newObj,
          fk_pieza: response?.data?.fk_pieza ? response?.data?.fk_pieza : null,
          fk_propietario: response?.data?.fk_propietario ? response?.data?.fk_propietario : null,
          id_propietario_pieza: response?.data?.id_propietario_pieza ? response?.data?.id_propietario_pieza : null,
          nombre: response?.data?.nombre ? response?.data?.nombre  : null,
          nombre_arte: response?.data?.nombre_arte ? response?.data?.nombre_arte  : null,
          registro_arte: response?.data?.registro_arte,  
        }
        if(user){
          let newObj3 = {
            ...newObj2, 
            registro: true, 
            from: "/registro-arte",        
          }
          navigate('/ver-arte', { state: { dataCache: newObj3}})
        }else{
          postEmail(newObj2)
        }        
      })
      .catch(error => {
        let r = error?.response?.data
        let newObj2
        switch (error?.response?.status) {
          case 404:
            newObj2 = {
              ...newObj,
              fk_pieza: r?.fk_pieza ? r?.fk_pieza : null,
              fk_propietario: r?.fk_propietario ? r?.fk_propietario : null,
              id_propietario_pieza: r?.id_propietario_pieza ? r?.id_propietario_pieza : null,
              nombre: r?.nombre ? r?.nombre  : null,
              nombre_arte: r?.nombre_arte ? r?.nombre_arte  : null,
              registro_arte: true,  
            }
            setArray(newObj2)
            setAcceptAction(2)
            showModal?.current?.content?.info(
              3, //accion que tomara 
              `${translation[language]?.msg_continuar_registro ? translation[language]?.msg_continuar_registro : "Para continuar con el registro de tu Arte, ingresa la información que te solicitará la aplicación."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_ok ? translation[language]?.boton_ok : "Ok"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`, //texto de boton de cancelar
            );
            break
          case 406:
            newObj2 = {
              ...newObj,
              nombre: r?.nombre_propietario ? r?.nombre_propietario   : "",
              apellidos: r?.apellidos_propietario ? r?.apellidos_propietario   : "",
              cod_celular: (r.celular && r?.celular.split('-')[0]) ? r?.celular.split('-')[0].replace("+", "") : "",
              celular: (r.celular && r?.celular.split('-')[1]) ? r?.celular.split('-')[1] : "",
              transicion: true,
            }
            setArray(newObj2)
            setAcceptAction(2)
            showModal?.current?.content?.info(
              3, //accion que tomara 
              `${translation[language]?.msg_continuar_registro ? translation[language]?.msg_continuar_registro : "Para continuar con el registro de tu Arte, ingresa la información que te solicitará la aplicación."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_ok ? translation[language]?.boton_ok : "Ok"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          case 411:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_codigo_pieza ? translation[language]?.msg_error_codigo_pieza : "Código de pieza no encontrado. Por favor, ingresa uno diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          case 412: 
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_inv ? translation[language]?.msg_error_inv : "Código de invitación no encontrado. Por favor, ingresa uno diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          case 413:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_pieza ? translation[language]?.msg_error_pieza : "El código de invitación o el código de pieza no coinciden con la misma pieza."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );  
            break;
          case 414:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_existe_email ? translation[language]?.msg_existe_email : "La dirección de correo electrónico introducida pertenece a un administrador.  Por favor, ingresa una diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          case 416:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_existe_pieza ? translation[language]?.msg_existe_pieza : "La pieza ya está registrada y le pertenece a otro Propietario."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break;            
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      if (!validateFields()) {
        return       
      }
      postRegistroArte()
    }

    //con esto abrimos el modal
    const openModalHelp = () =>{
      setShowTextDiv(1)
      showModal?.current?.content?.ownContent("xl")
    }

    //para cambiar el idioma desde el form 
    const changeLanguage = (language) => {
      setLanguage(JSON.parse(JSON.stringify(language)))
    }

    //Validacion
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(user && user?.grupo_actor !== 2){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_propietarios ? translation[language]?.msg_funcion_solo_propietarios : "Función disponible solo para Propietarios."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 


    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])     


    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CForm 
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <FormularioArte
                        formFields={formFields}
                        formErrors={formErrors}
                        formErrorsText={formErrorsText}
                        handleChange={handleChange}
                        user={user}
                        openModalHelp={openModalHelp}
                        translation={translation}
                        language={language}
                        changeLanguage={changeLanguage}
                        idiomas={idiomas}
                      />
                    </CForm>                              
                  </CContainer>  
                </Screen> 
                <div className="d-flex logoContent2 gray-color-text font-size-11">
                  by  <img src={SysmodelersIcon} width="110" alt="sysIcon" className="ms-2"/>
                </div>               
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`