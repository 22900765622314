import React, { useState } from "react";
import {
  CContainer,
  CNavbarNav,
  CNavbar,
  CNavbarBrand,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavLink,
  CNavbarToggler,
  CCollapse,
} from "@coreui/react";
import styled from "styled-components";
//hooks
import { globalFunctions } from "../hooks/globalFunctions";
//icons
import userIcon from '../icons/Icon awesome-user-circle.svg'
import userBlancoIcon from '../icons/Iconuser-blanco.svg'
import logoDorado from '../icons/logo-texto-dorado2.svg'
import languageIcon from '../icons/Icon material-language.svg'
import languageBlancoIcon from '../icons/Icon language - blanco.svg'

export default function Navbar({
  tipoActor,
  user,
  optionSelected,
  cerrarSesion,
  toRuta,
  language,
  idiomas,
  translation,
  changeLanguage
}) {

    const [visible, setVisible] = useState(false);
    const { findObjFromArray, propertyTypeOf } = globalFunctions();

    return (
            <div className="container-fluid h-100">
              <div className="row h-100">
                  <Aside className="col-12 p-0 navbar-light fixed-top">
                    <Nav expand="xl" className="shadow pb-0 align-items-start">
                      <CContainer fluid className="py-0 py-xl-2 px-0 px-xl-4">
                        <div className="d-flex align-items-center mb-3 mb-xl-0">
                          <CNavbarBrand href="#" className="d-block pe-0 ps-4 ps-xl-0">
                            <img
                              src={logoDorado}
                              alt="logo"
                              height="17px"
                            />
                          </CNavbarBrand>   
                        </div>

                      <div className="pe-3 ps-3 mb-3 mb-xl-0">
                      <NavbarToggler 
                        className="p-2 p-xl-0 shadow-none" 
                        onClick={() => setVisible(!visible)} 
                      />
                      </div>
                      <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav className="my-0 bg-nav-blue">
                          {tipoActor.map((at, i) => (
                              <CNavLink 
                                className={`px-3 px-xl-2 text-color-p link-nav mx-0 mx-xl-3 cursor-pointer ${(optionSelected === at.id_acceso || (!optionSelected && at.opcion === "Mis Artes") || (!optionSelected && at.opcion === "Artes")) ? 'option-selected' : ' nav-hover'}`} 
                                key={i}
                                // href={() => false}
                                onClick={() => {
                                  toRuta(at.url_acceso, at.boo_url_absoluta, at.id_acceso)
                                  setVisible(false)
                                }}
                              >
                                {/* {at[findObjFromArray(idiomas, "indice", parseInt(language))?.indice ? `idioma_opcion_${language}` : `idioma_opcion_1`]} */}
                                {at[propertyTypeOf(tipoActor.length === 0 ? 'undefined' : tipoActor[0][`idioma_opcion_${language}`]) === 'Undefined' ? `idioma_opcion_1` : `idioma_opcion_${language}`]}
                              </CNavLink>
                          ))}
                        </CNavbarNav>
                        <CNavbarNav className="my-0 ms-auto d-flex justify-content-end bg-nav-blue">
                          <div className="px-3 px-xl-0 mx-0 mx-xl-3 my-0 d-block d-xl-flex align-items-center">
                            <CDropdown variant="nav-item" popper={false}>
                              <CDropdownToggle className="text-color mt-1 d-flex align-items-center">
                              {/* <img src={userIcon} alt="" style={{padding:"0px",height:"25px", width:"25px", borderRadius:"50%", objectFit:"cover", marginRight:"10px"}}></img> */}
                              <img 
                                className='d-none d-xl-inline-flex me-2 cursor-pointer' 
                                src={languageIcon}  
                                alt="icon"
                                width="20"
                                height="20"
                              />   
                              <img 
                                className='d-inline-flex d-xl-none me-2 cursor-pointer' 
                                src={languageBlancoIcon}  
                                alt="icon"
                                width="20"
                                height="20"
                              />   
                              <span className="text-color-p">{findObjFromArray(idiomas, "indice", parseInt(language))?.idioma}</span>
                              </CDropdownToggle>
                              <CDropdownMenu className="pe-0 ps-0 mb-3 border-0 shadow-sm">
                                {idiomas.map((idioma) => (
                                  <CDropdownItem 
                                    key={idioma.indice}
                                    className="cursor-pointer primary-color" 
                                    // href={() => false}
                                    onClick={()=> changeLanguage(idioma.indice)}
                                  >
                                    {idioma.idioma}
                                  </CDropdownItem>
                                ))}
                              </CDropdownMenu>
                            </CDropdown>
                            <div className="d-none d-xl-flex px-2 text-color-p mt-2">|</div> 
                            <CDropdown variant="nav-item" popper={false}>
                              <CDropdownToggle className="text-color mt-1 d-flex align-items-center">
                              {/* <img src={userIcon} alt="" style={{padding:"0px",height:"25px", width:"25px", borderRadius:"50%", objectFit:"cover", marginRight:"10px"}}></img> */}
                              <img 
                                className='d-none d-xl-inline-flex me-2 cursor-pointer' 
                                src={userIcon}  
                                alt="icon"
                                width="25"
                                height="25"
                              />   
                              <img 
                                className='d-inline-flex d-xl-none me-2 cursor-pointer' 
                                src={userBlancoIcon}  
                                alt="icon"
                                width="25"
                                height="25"
                              />                              
                              <div className="text-color-p text-ellipsis mw-144">{user?.nombre ? user?.nombre : user?.email?.split('@')[0]}</div>
                              </CDropdownToggle>
                              <CDropdownMenu className="pe-0 ps-0 mb-3 border-0 shadow-sm">
                                <CDropdownItem 
                                  className="cursor-pointer primary-color d-flex align-items-center" 
                                  onClick={() => cerrarSesion(user?.grupo_actor)} 
                                  style={{paddingLeft:"6px"}}
                                >
                                    <div 
                                      className='logoutIcon me-2 cursor-pointer' 
                                    /> 
                                    {`${translation[language]?.boton_cerrar_sesion ? translation[language]?.boton_cerrar_sesion : "Cerrar sesión"}`}
                                </CDropdownItem>
                              </CDropdownMenu>
                            </CDropdown>
                          </div> 
                        </CNavbarNav>
                      </CCollapse>
                      </CContainer>    
                    </Nav>
                  </Aside>
              </div>
            </div>
    );
}

const Aside = styled.div`
  background-color: #2a4a86;
  .text-color-p{
    font-size: var(--font-size);
    color: rgb(255, 255, 255)!important;
    @media (min-width: 992px) {
      color: var(--primary-color) !important;
    }
  }
  .dropdown-toggle::after {
    font-size: var(--font-size);
    color: rgb(255, 255, 255)!important;
    @media (min-width: 992px) {
      color: var(--primary-color) !important;
    }
  }
  
  .option-selected{
    border-bottom: 2px solid #BEB083;
    @media (max-width: 991px) {
      color: white !important;
      background-color: #BEB083; 
      border-bottom: none;
    }
  }

  .home-color{
    background-color: #8E7E4B;
  }

  .nav-hover{
    background-image: linear-gradient(#BEB083 0 0);
    background-position: 0 100%;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size .25s,
      background-position 0s .25s;
    @media (max-width: 991px) {
      background-image: none;
    }
    :hover, :focus{
      background-position: 0% 100%;
      background-size: 100% 2px;
      @media (max-width: 991px) {
        background-image: none;
        color: white;
        background-color: #BEB083; 
        border-bottom: none;
      }
    }
  }

  .text-color2{
    color: var(--primary-color) !important;
    @media (max-width: 991px) {
      color: white !important;
    }
  }
  .mw-144{
    max-width: 144px;
  }

  .navbar-toggler{
    border: none !important;
  }
`
const Nav = styled(CNavbar)`
  background-color: white;
  z-index: 1030;
  font-size: var(--font-size);
  color:white;
  .link-nav{
    display:inline-flex;
    align-items: center;
  }
  .bg-nav-blue{
    @media (max-width: 991px) {
      background-color: var(--primary-color); 
    }
  }
`

const NavbarToggler = styled(CNavbarToggler)`
  border: none !important;
  .navbar-toggler-icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23B2A169' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-menu'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E") !important;
  }
`

