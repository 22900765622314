import React from "react";
import { 
    CContainer, 
    CRow, 
    CCol, 
} from '@coreui/react';
import styled from "styled-components";
import Header from "../../../hooks/Header";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ModalContentHelp({
  onClose,
  styles,
  //traducciones
  translation={},
  language="",
}) {
    
    return( 
        <Container className="pb-3 px-4 font-size-13">
            <Header
                title={`${translation[language]?.titulo_ayuda ? translation[language]?.titulo_ayuda : "Ayuda"}`}
                isBack={true}
                handleBack={onClose}
                isLogo={true}
                changeOrderLogo={false}
            />    
            <CRow className="pb-3">
                <CCol lg={6} className="text-center">
                  <div className="image-container">
                    <LazyLoadImage
                      alt={"img"}
                      effect="blur" 
                      className={`foto ${styles ? styles : ''}`}
                      src={`${translation[language]?.imagen_ayuda_izq ? translation[language]?.imagen_ayuda_izq : "/help1.jpg"}`}
                      placeholderSrc={`${translation[language]?.imagen_ayuda_izq ? translation[language]?.imagen_ayuda_izq : "/help1.jpg"}`}
                    />
                  </div>
                </CCol>
                <CCol lg={6} className="text-center border-color-top border-color-left">
                  <div className="image-container">
                    <LazyLoadImage
                      alt={"img"}
                      effect="blur" 
                      className={`foto ${styles ? styles : ''}`}
                      src={`${translation[language]?.imagen_ayuda_der ? translation[language]?.imagen_ayuda_der : "/help2.jpg"}`}
                      placeholderSrc={`${translation[language]?.imagen_ayuda_der ? translation[language]?.imagen_ayuda_der : "/help2.jpg"}`}
                    />
                  </div>
                </CCol>
            </CRow>
        </Container> 
            
   ) 

} 

const Container = styled(CContainer)`
  .border-color-left{
    @media (min-width: 992px) {
      border-left: 1px solid var(--primary-color);
      borter-top: none;
    }
  }
  .border-color-top{
    @media (max-width: 992px) {
      border-left: none;
      border-top: 1px solid var(--primary-color);
    }
  }
  .imagen{
    width: 100% !important;
    background: white;
    overflow: hidden;
  }
  .imagen img{
    width: 100% !important;
  }
  .h-320{
    max-height: 320px !important;
  }
`