import React, {useState} from "react";
import {
  CCardBody,
  CRow,
  CCol,
  CCardHeader,
  CBadge,
  CCollapse,
  CContainer,
  CFormLabel
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card2,
  Button 
} from '../../../hooks/styled'
import UltimasPiezasAgregadasRows from "./UltimasPiezasAgregadasRows";
import arrowDownIcon from "../../../icons/Icono flechita abajo - dorada.svg";
import arrowUpIcon from "../../../icons/Icono flechita arriba - dorada.svg";
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'

export default function UltimasPiezasAgregadas({
  goCRUDPiezas,
  piezasArte,
  handleClickShowBidi,
  //traducciones
  translation={},
  language="",
}) {

    const [display, setDisplay] = useState(false); 

    //con esto retornamos las filas
    const RenderData = (registro) => {
      return (
        <>
          {registro.map((item, i) => {
            return (
              <UltimasPiezasAgregadasRows
                key={i}
                item={item}
                total={registro.length}
                index={i}
                handleClickShowBidi={handleClickShowBidi}
                //traducciones
                translation={translation}
                language={language}     
              />
            );
          })}
        </>
      );
    };

    return (
            <Content>
              <Card2 className="border-0 pt-4">
                <CCardHeader className="border-bottom-0 bg-custom rounded-0 font-size-11">
                  <CRow className="py-0 d-flex align-items-center">
                    <CCol sm={6} className="d-flex align-items-center mb-3 mb-sm-0">
                      {`${translation[language]?.piezas_artes ? translation[language]?.piezas_artes : "Piezas de Arte"}`}
                      <CBadge className={`ms-2 rounded-0 badge-border2 font-size-11`}>
                        {piezasArte?.total}
                      </CBadge>
                    </CCol>
                    <CCol sm="6" className="d-flex align-items-center justify-content-sm-end">
                      <Button 
                        type="button"
                        className="font-size-11" 
                        onClick={() => goCRUDPiezas()}
                      >
                        
                        {`${translation[language]?.agregar_ver_piezas ? translation[language]?.agregar_ver_piezas : "Agregar / ver piezas"}`}
                      </Button>
                      <img 
                          src={display ? arrowUpIcon : arrowDownIcon } 
                          width={16} 
                          height={16}
                          className="ms-3 cursor-pointer"
                          onClick={() => setDisplay(!display)}
                          alt="arrow"
                      /> 
                    </CCol>
                  </CRow>
                </CCardHeader>
              </Card2>   
              <CCollapse visible={display}>
                <div className="text-end pt-2">
                  <CFormLabel 
                    className="font-size-default primary-text-color font-size-12" 
                  >                   
                    {`${translation[language]?.ultimas_piezas_agregadas ? translation[language]?.ultimas_piezas_agregadas : "Últimas 5 piezas agregadas"}`}
                  </CFormLabel>
                </div>
                <Card2 className="border-0 pt-1 pb-2">
                  <CCardHeader className="border border-bottom-0 border-color bg-custom rounded-0 font-size-12 d-none d-lg-block">
                    <CRow className="py-2">
                      <CCol lg={2} className="">{`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}</CCol>
                      <CCol lg={2} className="px-0">{`${translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : "Código de invitación"}`}</CCol>
                      <CCol lg={1} className="px-0">{`${translation[language]?.numero_pieza ? translation[language]?.numero_pieza : "Número de pieza"}`}</CCol>
                      <CCol lg={3}>{`${translation[language]?.url ? translation[language]?.url : "URL"}`}</CCol>
                      <CCol lg={1}>{`${translation[language]?.bidi ? translation[language]?.bidi : "BIDI"}`}</CCol>
                      <CCol lg={3}>{`${translation[language]?.propietario ? translation[language]?.propietario : "Propietario"}`}</CCol>
                    </CRow>
                  </CCardHeader>                  
                  <CCardBody className="border-top-0 py-0 px-0 border border-color font-size-12">
                    {piezasArte?.piezas?.length > 0 ?  
                    (
                      <>{RenderData(piezasArte?.piezas)}</>
                    ) 
                    : 
                    (
                        <CContainer className="text-start ps-4 mb-1">  
                          <CRow>               
                            <CCol xs={12} className={`d-flex align-items-center mb-3 mt-3`}>
                              <>
                                <div className="me-2">
                                  <img
                                    src={infoIcon}
                                    alt=""
                                    width="18"
                                  />
                                </div>
                                <span 
                                    className={`font-size-12`}
                                >                         
                                  {`${translation[language]?.hint_sin_piezas ? translation[language]?.hint_sin_piezas : "El arte aún no tiene piezas creadas."}`}
                                </span>     
                              </>                              
                            </CCol>
                          </CRow>                              
                        </CContainer>
                    )
                    }
                  </CCardBody>
                </Card2>
              </CCollapse>          
            </Content>
    )
}
const Content = styled.div`
`
