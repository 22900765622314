
// load theme styles with webpack

import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CFormFeedback,
  CSpinner,
  CTooltip,
  CButton
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  CInput, 
  Button, 
  ButtonVariant,
  FormTextarea,
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import Carousel from "../../../hooks/Carousel";
import CustomInputDate from '../../../hooks/CustomInputDate'
import UltimasPiezasAgregadas from "./UltimasPiezasAgregadas";
import Select from '../../../hooks/CustomSelect'
import { globalFunctions } from "../../../hooks/globalFunctions";
import closeWhiteIcon from '../../../icons/Icono cerrar - blanco.svg'
import 'react-slideshow-image/dist/styles.css'
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/beagle.css'
// ES module
// import Editor from 'react-medium-editor';

export default function CardContent({
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  count,
  handleChangeImage,
  deletePicture,
  handleBack,
  goCRUDPiezas,
  goCRUDArtistas,
  loadingData,
  edit,
  piezasArte,
  handleClickShowBidi,
  changePictureFav,
  handleDeleteArtist,
  series,
  indiceIdiomaForm,
  handleChangeIndiceIdiomaForm,
  // inicio,
  // openModalBuscarArte,
  //traducciones
  translation={},
  language="",
  idiomas=[],
}) {

    const { 
      findObjFromArray,
      returnClassOrMessageLanguage
    } = globalFunctions();

    //cosas personalizadas para el estilo del select
    const customStylesSelect = {
      fontSize: "12pt",
    }

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-4 pb-4">
                    <Header
                      title={`${edit ? `${translation[language]?.editar_arte ? translation[language]?.editar_arte : 'Editar Arte'}` : `${translation[language]?.nuevo_arte ? translation[language]?.nuevo_arte : 'Nuevo Arte'}`}`}
                      isBack={true}
                      handleBack={handleBack}
                    />  
                    {loadingData ? (
                      <CRow>
                        <CCol xs={12} className={`d-flex align-items-center mb-5 mt-3 justify-content-center`}>
                          <div className="text-center">
                            <CSpinner size="sm"/>
                              <CSpinner size="sm" variant="grow"/>
                              <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                          </div>                       
                        </CCol>
                      </CRow>
                    ) : (
                      <>
                        <CRow className="d-flex font-size-12">
                          <CCol md="6">
                            <Carousel
                              key={count}
                              imagenes={formFields?.imagenes?.filter(img => img.tipo === 1)}
                              deletePicture={deletePicture}
                              typeImage={1}
                              propertyName={"imagenes"}
                              handleChangeImage={handleChangeImage}
                              changePictureFav={changePictureFav}
                              styles={"h-400px"}
                              translation={translation}
                              language={language}
                            />
                          </CCol>
                          <CCol md={6}>
                            <CRow className="mb-0">
                              <CCol xs={12} className="text-xl-end">
                                <span  className={`d-inline-flex d-xl-none me-1 mb-2`}>{`${translation[language]?.hint_idiomas ? translation[language]?.hint_idiomas : "Idiomas"}`}: </span>
                                <div className="d-flex justify-content-xl-end">
                                  {idiomas.map((idioma) => (
                                    <div key={idioma.indice} className="ms-0 mb-2 mb-xl-0 ms-xl-1">
                                      <CTooltip content={`${returnClassOrMessageLanguage(idioma.indice, formFields, true, translation, indiceIdiomaForm, true)}`}>
                                        <CButton 
                                          className={`icon-language text-uppercase shadow-none ${returnClassOrMessageLanguage(idioma.indice, formFields, false, translation, indiceIdiomaForm, true)}`} 
                                          onClick={()=> handleChangeIndiceIdiomaForm(idioma.indice)}
                                        >
                                          {idioma.abreviado}
                                        </CButton>
                                      </CTooltip>
                                    </div>
                                  ))}
                                </div>
                              </CCol>
                              {/* {(!inicio && !formFields.id_arte) && (
                                <CCol md="12" className="text-end my-0">
                                  <CFormLabel
                                    onClick={()=> openModalBuscarArte()} 
                                    className="cursor-pointer d-block mt-0 font-size-default primary-text-color font-size-12 text-underline" 
                                  >          
                                    {translation[language]?.label_arte_existe ? translation[language]?.label_arte_existe : '¿El Arte existe en otro idioma?'}
                                  </CFormLabel>
                                </CCol>
                              )} */}

                              <CCol md={7} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >          
                                  {translation[language]?.titulo ? translation[language]?.titulo : 'Título'}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="nombre"
                                  value={formFields.nombre}
                                  invalid={formErrors.nombre}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.nombre}</CFormFeedback> 
                              </CCol>

                              <CCol md={5} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                  
                                  {translation[language]?.fecha_creacion ? translation[language]?.fecha_creacion : 'Fecha de creación'}
                                </CFormLabel>
                                <CustomInputDate
                                  nombre="fecha_creacion"
                                  error={formErrors.fecha_creacion}
                                  handleChange={handleChange}
                                  valor={formFields.fecha_creacion}
                                  //traducciones
                                  language={findObjFromArray(idiomas, "indice", parseInt(language)) ? findObjFromArray(idiomas, "indice", parseInt(language))?.abreviado : 'es'}
                                  placeholderText={translation[language]?.etiqueta_formato_fecha ? translation[language]?.etiqueta_formato_fecha : "dd/mm/aaaa"}
                                />                     
                                {formErrors.fecha_creacion && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.fecha_creacion}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={7} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {translation[language]?.coleccion ? translation[language]?.coleccion : 'Colección'}
                                </CFormLabel>
                                <Select 
                                  options={series}
                                  nombre="fk_serie"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.fk_serie} 
                                  error={formErrors.fk_serie} 
                                  styles={customStylesSelect}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.fk_serie && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.fk_serie}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={5} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >     
                                  {translation[language]?.numero_piezas ? translation[language]?.numero_piezas : 'Número total de piezas'}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="cantidad_piezas"
                                  value={formFields.cantidad_piezas}
                                  invalid={formErrors.cantidad_piezas}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.cantidad_piezas}</CFormFeedback>
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                         
                                  {translation[language]?.numero_edicion ? translation[language]?.numero_edicion : 'Número de edición'}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="numero_edicion"
                                  value={formFields.numero_edicion}
                                  invalid={formErrors.numero_edicion}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.numero_edicion}</CFormFeedback>
                              </CCol>

                              <CCol md={8} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                                  
                                  {translation[language]?.lugar_creacion ? translation[language]?.lugar_creacion : 'Lugar de creación'}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="lugar_creacion"
                                  value={formFields.lugar_creacion}
                                  invalid={formErrors.lugar_creacion}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.lugar_creacion}</CFormFeedback>
                              </CCol>

                              {formFields.fk_serie && (
                                <CCol md={12} className="mt-3">
                                  <CFormLabel 
                                    className="d-block font-size-default primary-text-color font-size-12" 
                                  >                                  
                                    {translation[language]?.descripcion_coleccion ? translation[language]?.descripcion_coleccion : 'Descripción de la colección'}
                                  </CFormLabel>
                                  <FormTextarea 
                                    rows="1"
                                    className="font-size-12"
                                    name="descripcion_serie"
                                    defaultValue={formFields.fk_serie ? findObjFromArray(series, "value", formFields.fk_serie)?.descripcion : ''}
                                    disabled={true}
                                  />       
                                </CCol> 
                              )}

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                           
                                  {translation[language]?.descripcion_larga ? translation[language]?.descripcion_larga : 'Descripción larga'}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="1"
                                  className="font-size-12"
                                  name="descripcion_arte"
                                  value={formFields.descripcion_arte}
                                  invalid={formErrors.descripcion_arte}
                                  onChange={handleChange}
                                />       
                                {/* <Editor
                                  disableEditing={true}
                                  tag="pre"
                                  // text={this.state.text}
                                  // onChange={this.handleChange}
                                  options={{toolbar: {align: 'center', buttons: ['bold', 'italic', 'underline', 'orderedlist', 'unorderedlist']}}}
                                />                                                                                */}
                                <CFormFeedback invalid>{formErrorsText.descripcion_arte}</CFormFeedback> 
                              </CCol>   

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                            
                                  {translation[language]?.descripcion_corta ? translation[language]?.descripcion_corta : 'Descripción corta'}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="1"
                                  className="font-size-12"
                                  name="descripcion_corta"
                                  value={formFields.descripcion_corta}
                                  invalid={formErrors.descripcion_corta}
                                  onChange={handleChange}
                                />       
                                {/* <Editor
                                  disableEditing={true}
                                  tag="pre"
                                  // text={this.state.text}
                                  // onChange={this.handleChange}
                                  options={{toolbar: {align: 'center', buttons: ['bold', 'italic', 'underline', 'orderedlist', 'unorderedlist']}}}
                                />                                                                                */}
                                <CFormFeedback invalid>{formErrorsText.descripcion_corta}</CFormFeedback> 
                              </CCol>                          
                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                            
                                  {translation[language]?.especificaciones_tecnicas ? translation[language]?.especificaciones_tecnicas : 'Especificaciones técnicas'}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="2"
                                  name="especificacion_tecnica"
                                  value={formFields.especificacion_tecnica}
                                  invalid={formErrors.especificacion_tecnica}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />                    
                                <CFormFeedback invalid>{formErrorsText.especificacion_tecnica}</CFormFeedback> 
                              </CCol>
                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                               
                                  {translation[language]?.conservacion_pieza ? translation[language]?.conservacion_pieza : 'Conservación de la pieza'}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="2"
                                  name="conservacion"
                                  value={formFields.conservacion}
                                  invalid={formErrors.conservacion}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />                    
                                <CFormFeedback invalid>{formErrorsText.conservacion}</CFormFeedback> 
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol md={12} className="mt-3 d-lg-flex align-items-center">
                            <CFormLabel 
                              className="my-0 me-3 mb-2 mb-lg-0 font-size-default primary-text-color font-size-12" 
                            >                           
                              {translation[language]?.artistas_involucrados ? translation[language]?.artistas_involucrados : 'Artistas involucrados en la creación'}
                            </CFormLabel> 
                            <CTooltip content={`${translation[language]?.hint_boton_buscar_artistas ? translation[language]?.hint_boton_buscar_artistas : 'Haga clic para seleccionar el o los artistas'}`}>
                              <Button 
                                type="button"
                                className="font-size-12" 
                                onClick={goCRUDArtistas}
                              >                              
                                {translation[language]?.boton_buscar_artista ? translation[language]?.boton_buscar_artista : 'Buscar artistas'}
                              </Button> 
                            </CTooltip>                       
                          </CCol>
                          <CCol md={12} className="d-flex flex-wrap mt-3">
                            {formFields?.artistas?.map((item, i) => {
                              return (
                                <div key={i} className="container-name me-3 text-center mb-3">
                                  <div className="d-flex justify-content-end mp">
                                    <div className="equis">
                                      <img
                                        height="10"
                                        width="10"
                                        src={closeWhiteIcon}
                                        className="cursor-pointer"
                                        onClick={()=> handleDeleteArtist(item)}
                                        alt="x"
                                      /> 
                                    </div>
                                  </div>
                                  <div className="mx-2">{item.nombre_apellido ? item.nombre_apellido : ''}</div>
                                </div>
                              );
                            })}                            
                          </CCol>
                          <CCol md={12} className="my-3">
                            <CFormLabel 
                              className="d-block font-size-default primary-text-color font-size-12" 
                            >                         
                              {translation[language]?.proceso_elaboracion ? translation[language]?.proceso_elaboracion : 'Proceso de elaboración'}
                            </CFormLabel>
                            <FormTextarea 
                              rows="1"
                              className="font-size-12"
                              name="proceso_elaboracion"
                              value={formFields.proceso_elaboracion}
                              invalid={formErrors.proceso_elaboracion}
                              onChange={handleChange}
                            />                    
                            <CFormFeedback invalid>{formErrorsText.proceso_elaboracion}</CFormFeedback> 
                          </CCol>
                          <CCol md="12">
                            <Carousel
                              key={count}
                              imagenes={formFields?.imagenes?.filter(img => img.tipo === 2)}
                              deletePicture={deletePicture}
                              typeImage={2}
                              propertyName={"imagenes"}
                              handleChangeImage={handleChangeImage}
                              responsive={true}
                              styles={"h-200px"}
                              translation={translation}
                              language={language}
                            />
                          </CCol>
                          <CCol md={12} className="my-3">
                            <CFormLabel 
                              className="d-block font-size-default primary-text-color font-size-12" 
                            >                           
                              {translation[language]?.esquema_montaje ? translation[language]?.esquema_montaje : 'Esquema de montaje'}
                            </CFormLabel>
                            <FormTextarea 
                              rows="1"
                              className="font-size-12"
                              name="esquema_montaje"
                              value={formFields.esquema_montaje}
                              invalid={formErrors.esquema_montaje}
                              onChange={handleChange}
                            />                    
                            <CFormFeedback invalid>{formErrorsText.esquema_montaje}</CFormFeedback> 
                          </CCol>
                          <CCol md="12" className="mb-3 mb-md-0">
                            <Carousel
                              key={count}
                              imagenes={formFields?.imagenes?.filter(img => img.tipo === 3)}
                              deletePicture={deletePicture}
                              typeImage={3}
                              propertyName={"imagenes"}
                              handleChangeImage={handleChangeImage}
                              styles={"h-200px"}
                              responsive={true}
                              translation={translation}
                              language={language}
                            />
                          </CCol>
                        </CRow>
                        {(formFields.id_arte && edit) && (
                          <UltimasPiezasAgregadas
                            piezasArte={piezasArte}
                            goCRUDPiezas={goCRUDPiezas}
                            handleClickShowBidi={handleClickShowBidi}
                            //traducciones
                            translation={translation}
                            language={language}                           
                          />
                        )}
                        <div className="text-end">
                          <hr className='mb-0 primary-color'/>
                          <div className="mt-4 ">
                            <ButtonVariant
                              type="button"
                              className="me-3"
                              onClick={handleBack}
                            >
                              {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                            </ButtonVariant>  
                            <Button 
                              type="submit"
                            >
                              {`${translation[language]?.boton_guardar ? translation[language]?.boton_guardar : "Guardar"}`}
                            </Button>                    
                          </div>                                   
                        </div>
                      </>
                    )} 
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
.bg-custom{
  border-top: 1px solid var(--primary-color);
  background-color: #F7F5EF;
}
.form-control:disabled {
  background-color: #F7F5EF;
  border-color: var(--primary-color);
  opacity: 1;
}
.container-name{
  min-width: 180px;
  max-width: 250px;
  border: 1px solid var(--primary-color);
}
.equis{
  width: 22px;
  height: 20px;
  background: var(--primary-color);
  text-align: center;
  align-items: center;
  display: flex;
}
.equis img {
  margin: auto;
}
.mp{
  margin-top: -1px;
  margin-right: -1px;
}
`
