import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CCardHeader,
  CSpinner,
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  Card2,
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import Pagination from "../../../hooks/Paginacion";
import ClientListRows from './ClientListRows';
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'

export default function CardContent({
  data,
  dataConfig,
  getData,
  loadingData,
  setDataConfig,
  filtros,
  handleChangeFiltros,
  goToEditarPropietario,
  handleGoToTransferirArte,
  goToVerArtesPropietario,
  //traducciones
  translation={},
  language="",
}) {

    //con esto retornamos las filas
    const RenderData = (registro) => {
      return (
        <>
          {registro.map((item, i) => {
            return (
              <ClientListRows
                key={i}
                item={item}
                total={registro.length}
                index={i}
                goToEditarPropietario={goToEditarPropietario}
                handleGoToTransferirArte={handleGoToTransferirArte}
                goToVerArtesPropietario={goToVerArtesPropietario}
                //traducciones
                translation={translation}
                language={language}  
              />
            );
          })}
        </>
      );
    };

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-2 px-sm-4">
                    <Header
                      title={`${translation[language]?.titulo_administrar_propietarios ? translation[language]?.titulo_administrar_propietarios : "Administrador de Propietarios"}`}
                      isBack={false}
                      isSearchAndFilter={true}
                      isFilter={true}
                      filtros={filtros}
                      dataConfig={dataConfig}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      placeholderSearch={`${translation[language]?.search_propietarios ? translation[language]?.search_propietarios : "Buscar Propietario"}`}
                      showValuesText={`${translation[language]?.mostrar ? translation[language]?.mostrar : "Mostrar"}`}
                      filterByText={`${translation[language]?.filtrar_por ? translation[language]?.filtrar_por : "Filtrar por"}`}
                      selectText={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                      noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                    /> 
                    <Card2 className="border-0 pb-4">
                      <CCardHeader className="border-bottom-0 bg-color rounded-0 font-size-12 text-white d-none d-lg-block">
                        <CRow className="py-2">
                          <CCol lg={3}>{`${translation[language]?.propietario ? translation[language]?.propietario : "Propietario"}`}</CCol>
                          <CCol lg={4}>{`${translation[language]?.correo ? translation[language]?.correo : "Correo"}`}</CCol>
                          <CCol lg={2}>{`${translation[language]?.telefono ? translation[language]?.telefono : "Teléfono"}`}</CCol>
                          <CCol lg={2}>{`${translation[language]?.celular ? translation[language]?.celular : "Celular"}`}</CCol>
                          <CCol lg={1}></CCol>
                        </CRow>
                      </CCardHeader>
                      <CCardBody className="py-0 px-0 border border-color font-size-12">
                        {data.length > 0 && !loadingData ?  
                        (
                          <>{RenderData(data)}</>
                        ) 
                        : 
                        (
                            <CContainer className="text-start even ps-4 mb-5">  
                              <CRow>               
                                <CCol xs={12} className={`d-flex align-items-center mb-3 mt-3 ${loadingData && "justify-content-center"}`}>
                                  {loadingData ? 
                                    (
                                      <div className="text-center">
                                        <CSpinner size="sm"/>
                                          <CSpinner size="sm" variant="grow"/>
                                          <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                                      </div>
                                    )
                                  :
                                    (
                                      <>
                                        <div className="me-2">
                                          <img
                                            src={infoIcon}
                                            alt=""
                                            width="18"
                                          />
                                        </div>
                                        <span 
                                            className={`font-size-14`}
                                        >
                                          {dataConfig?.searchTerm === "" ? `${translation[language]?.msg_error_propietarios ? translation[language]?.msg_error_propietarios : 'No existen Propietarios con el filtro solicitado.'}` : `${translation[language]?.msg_error_resultados ? translation[language]?.msg_error_resultados : 'No se encontraron resultados para la búsqueda.'}`}
                                        </span>     
                                      </>
                                    )
                                  }                              
                                </CCol>
                              </CRow>                              
                            </CContainer>
                        )
                        }
                      </CCardBody>
                    </Card2>
                    {(data.length > 0 && !loadingData) && (
                      <Pagination
                        getData={getData} // funcion para actualizar la data
                        dataConfig={dataConfig} // data que almacena toda la configuracion de busqueda, paginacion y filtrado
                        setDataConfig={setDataConfig}
                        ifGetData={true} // indica si obtenemos la data con endpoint
                        //traducciones
                        translation={translation}
                        language={language}                        
                      />
                    )}
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
`
