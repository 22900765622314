import React, {useState, useRef, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import ChangePassword from "./ChangePassword";
import ModalContent from '../../../hooks/ModalContent'
import api from '../../../api/api'

export default function ChangePasswordIndex({
  admin,
  //traducciones
  translation={},
  language="",
}) {
    const showModal = useRef(); //referencia del hijo
    const navigate = useNavigate();

    //variable que almacena el token
    const [token, setToken] = useState("")

    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      password: "",
      confirmPassword: "",
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      password: false,
      confirmPassword: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      password: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      confirmPassword: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      const { name, value } = e.target;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(!formFields['password']) {
        errorsObj['password'] = true
        errorsObjText['password'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['confirmPassword']) {
        errorsObj['confirmPassword'] = true
        errorsObjText['confirmPassword'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(formFields['confirmPassword'] !== formFields['password']){
        errorsObj['confirmPassword'] = true
        errorsObjText['confirmPassword'] = translation[language]?.contrasenas_invalidas ? translation[language]?.contrasenas_invalidas : "Las contraseñas no coinciden. Inténtalo de nuevo."
        validState = false       
      }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          let ruta = admin ? '/iniciar-sesion' : '/consultar-artes'
          navigate(ruta)
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      if (!validateFields()) {
        return       
      }
      changePassword()
    }

    //para cambiar la contraseña
    const changePassword = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
      let tok = "Token " + token
      api.put("/put_user",{
        clave: formFields.password
      },{
          headers:{
              Authorization: tok
          }
      }).then(response => {
        setAcceptAction(1)
        showModal?.current?.content?.success(
          3, //accion que tomara 
          `${translation[language]?.msg_cambio_clave ? translation[language]?.msg_cambio_clave : "¡Tu clave se cambió con éxito!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
      })
      .catch(error => {
        setAcceptAction(1)
        switch (error?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              3, //accion que tomara 
              `${translation[language]?.msg_token_invalido ? translation[language]?.msg_token_invalido : "Token inválido, por favor inténtelo nuevamente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          default:
            showModal?.current?.content?.errorDataBase(
              3, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //con esto validamos que hay un token en la ruta
    useEffect(()=> {
      const query = new URLSearchParams(window.location.search);
      const tok = query.get("token");
      if(tok){
        setToken(tok)
        const cleanURL = window.location.href.split("?")[0]
        window.history.pushState('object',document.title, cleanURL)
      }else{
        navigate('/iniciar-sesion')
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 

    return (
            <Content> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
              <ChangePassword
                formFields={formFields}
                formErrors={formErrors}
                formErrorsText={formErrorsText}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                admin={admin}
                //traducciones
                translation={translation}
                language={language}  
              />
            </Content>
    )
}


const Content = styled.div`
  min-height: 100vh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  padding: 7%; 
`