import React, {useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import {format, parse} from "date-fns"
import styled from 'styled-components'
import "react-datepicker/dist/react-datepicker.css";
//importamos de date-fns los idiomas, por defecto el ingles es el idioma principal NO SE DEBE IMPORTAR
import { fr, es, it, pt, de, ar, hi } from "date-fns/locale";
import calendarIcon from '../icons/Icon ionic-ios-calendar.svg'
//registramos cada uno de los idiomas que vamos a tener, por defecto el ingles es el idioma principal NO SE DEBE IMPORTAR
registerLocale('es', es) // español
registerLocale('fr', fr) // frances
registerLocale('it', it) // italiano
registerLocale('pt', pt) // portugues
registerLocale('de', de) // aleman
registerLocale('ar', ar) // arabe
registerLocale('hi', hi) // hindu

export default function CustomInputDate({
  valor,
  error,
  nombre,
  handleChange,
  disabled=false,
  placeholderText="dd/mm/aaaa",
  language="es"
}) {

    // const [startDate, setStartDate] = useState(valor ? parse(valor, "yyyy-MM-dd", new Date()) : null);
    const [open, setOpen] = useState(false);

    const onChange = (date) => {
      let newValue = date ? format(date, "yyyy-MM-dd", { 
        awareOfUnicodeTokens: true }) : ""
      let newObj = {target: {name: nombre, value: newValue ? newValue : ""}}
      // setStartDate(date)
      handleChange(newObj)
      setOpen(false)
    }

    return (
            <Content className="d-flex">
              <DatePicker
                selected={valor ? parse(valor, "yyyy-MM-dd", new Date()) : null}
                onChange={(date) => disabled ? false : onChange(date)}
                locale={language}
                shouldCloseOnSelect={true}
                dateFormat="P"
                inputFormat="P"
                className={`w-100 my-input-date form-control form-control-sm font-size-12 ${error ? 'is-invalid' : ''}`}
                open={open}
                onClickOutside={() => setOpen(!open)}
                onInputClick={() => setOpen(!open)}
                placeholderText={placeholderText}
                readOnly={disabled}
                disabled={disabled}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <img
                src={calendarIcon}
                alt="icon"
                onClick={() => disabled ? false : setOpen(true)}
                className="new-icon cursor-pointer"
              /> 
            </Content>    
    );
}

const Content = styled.div`
  .my-input-date {
    background-color: #fff;
    border-color: var(--primary-color);
    color: var(--primary-color) !important;
    box-shadow: none !important;
    font-size: var(--font-size);
    border-radius: 0rem;
  }
  .my-input-date:focus {   
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
  .my-input-date::placeholder {
    color: var(--primary-color) !important;
    opacity: 1 !important;
  }
  .my-input-date:-ms-input-placeholder, .my-input-date::-ms-input-placeholder {
    color: var(--primary-color) !important;
  }
  .new-icon{
    margin-left: -27px;
    padding-right: 8px;
    z-index: 20;
    width: 24px;
  }
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: var(--primary-color);
    color: #fff;
  }
  .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
      background-color: #a99966;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: var(--primary-color);
    color: #fff;
  }
  .react-datepicker-popper {
    z-index: 1050;
  }

  .react-datepicker__month-select, 
  .react-datepicker__month-select:active, 
  .react-datepicker__month-select:focus, 
  .react-datepicker__month-select:focus-within, 
  .react-datepicker__month-select:target, 
  .react-datepicker__month-select:hover, 
  .react-datepicker__month-select:visited, 
  .react-datepicker__month-select:focus-visible,
  .react-datepicker__year-select,
  .react-datepicker__year-select:active, 
  .react-datepicker__year-select:focus, 
  .react-datepicker__year-select:focus-within, 
  .react-datepicker__year-select:target, 
  .react-datepicker__year-select:hover, 
  .react-datepicker__year-select:visited, 
  .react-datepicker__year-select:focus-visible
  {
    box-shadow: none !important;
    border-radius: 0rem !important;
    border: 1px solid var(--primary-color) !important;
    outline: none !important;
    background-color: #fff !important;
    color: var(--primary-color) !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;
    appearance: none!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    background-image: url("data:image/svg+xml,
    <svg fill='rgba(178, 161, 105)' width='18' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/>
    </svg>")!important;
    background-repeat: no-repeat!important;
    background-position-x: 95%!important;
    padding-right: 2rem!important;
    padding-left: 0.3rem!important;
  }  
`