import React, {useEffect, useState, useRef} from "react";
import { CModal, CModalBody, CRow, CCol } from '@coreui/react';
import {
  ButtonVariant, 
} from './styled'
import Cropper from "react-cropper";
import closeIcon from '../icons/Icon material-close.svg'
import styled from "styled-components";
// const defaultSrc = "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";  

export default function ModalCropper({
  configImg, 
  onSetPhoto, 
  show, 
  setShow,
  //traducciones
  translation={},
  language="",
}) {
        
    // url: La foto 
    // onSetPhoto: funcion en la que retorno la foto
    // show: abre o cierra la modal 
    // setShow: asigna valor a la varibale 
    // aspectRatio: Dimenciones de la foto 
    // 2/3 Para formato vertica 
    // 1/1 Para formato cuadrado
    // 4/3 Para formato horizontal corto
    // 16/9 Para formato horizontal largo

    const inputFile = useRef(null);

    useEffect(()=> {
      if(show) {
          setImage(configImg?.url);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[show])
      
    const [image, setImage] = useState(null);
    const [cropper, setCropper] = useState();


    const getCropData = () => {
      if (typeof cropper !== "undefined") {
        let newObj = {
          url: cropper.getCroppedCanvas().toDataURL(),
          variable: configImg?.variable,
          type: configImg?.type,
        }
        onSetPhoto(newObj);
        setShow(false); 
      }
    }; 

    const handleChangeImage = e => {   
      inputFile.current.click();  
      if (e !== undefined) {   
        if (e.target.files !== undefined) {  
          const file = e.target.files[0];  
          var reader = new FileReader();
          let base64 = null;  
          if(file !== undefined) {  
            reader.readAsDataURL(file); 
            reader.onload = function(event) {   
              base64 = event.target.result;  
              setImage(base64); 
            };  
          } 
        }
      }   
      setTimeout(() => {
        inputFile.current.value = "";
      }, 1500);
    } 


    return(
        <Contenedor 
          alignment="center" 
          visible={show}
          keyboard={false}
        >
          <CModalBody>
            <div> 
              <CRow className="pb-3">
                <CCol xs={10}>
                    <span className="font-weight-bold">{`${translation[language]?.imagen ? translation[language]?.imagen : "Imagen"}`}</span>
                </CCol>
                <CCol xs={2} className="text-end">
                    <img 
                    height="16" 
                    width="16" 
                    src={closeIcon} 
                    onClick={() => setShow(false)}
                    className="cursor-pointer"
                    alt={`close`}
                    /> 
                </CCol>
                <CCol xs={12}>
                  <hr className='mt-3 mb-0 primary-color'/>
                </CCol>           
              </CRow>
              <div className="contenedorPhoto"> 
                <Cropper
                  style={{ height: "400px", maxWidth: 470 }} 
                  initialAspectRatio={1} 
                  src={image} 
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                  setCropper(instance);
                  }}
                  guides={false}
                /> 
              </div>
            </div> 
            <CRow className="mt-2">
              <CCol md={6} className="mb-2 mb-md-0"> 
                <ButtonVariant
                    color={'success'}
                    className="w-100 shadow-none"
                    variant={'outline'}
                    onClick={handleChangeImage}
                >
                  {`${translation[language]?.boton_cambiar ? translation[language]?.boton_cambiar : "Cambiar"}`}
                </ButtonVariant> 
              </CCol>
              <CCol md={6}> 
                  <ButtonVariant
                      color={'success'}
                      className="w-100 shadow-none"
                      variant={'outline'}
                      onClick={getCropData}
                  >
                    {`${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`}
                  </ButtonVariant>                  
              </CCol>
            </CRow>  
            <input id="photo" ref={inputFile} name="photo" type="file" accept="image/*"  style={{display:"none"}} onChange={handleChangeImage}/>  
          </CModalBody>              
      </Contenedor>
    )
}

const Contenedor = styled(CModal)` 
  .box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  .img-preview {
    overflow: hidden;
  } 
  .container-color{
    background-color: #91D1B0;
    color: #009549;
    font-size: 22px; 
  }
`