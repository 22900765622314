import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation} from 'react-router-dom';
import {
  CContainer,
} from '@coreui/react'
import styled from 'styled-components'
import FormularioArte from './FormularioArte';
import ModalContent from '../../../hooks/ModalContent'
import api from '../../../api/api'
import {parse} from "date-fns"

export default function VerArte({
  user,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat
}) {
    //Variables

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    const navigate = useNavigate();
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null

    const [paises, setPaises] = useState([]); //lista de paises
    const [propietariosPiezas, setPropietariosPiezas] = useState([]); //lista de propietarios
    const [infoPieza, setInfoPieza] = useState([]); //lista de propietarios

    const showModal = useRef(); //referencia del hijo
    const [loadingData, setLoadingData] = useState(true) //variable que indica cuando esta cargando
    const [inicio, setInicio] = useState(true); //con esta variable validamos si ya cargo por primera vez el form  o no

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      id_arte: null,
      nombre: "",
      fecha_creacion: "",
      descripcion_arte: "",
      nombre_serie: "",
      cantidad_piezas: "",
      especificacion_tecnica: "",
      conservacion: "",
      esquema_montaje: "",
      proceso_elaboracion: "",
      lugar_creacion: "",
      numero_edicion: "",
      imagenes: [],
      artistas: [],
    });

    //funciones

    //Con esta funcion enviamos de regreso al usuario al dar clic en back
    const handleBack = () => {
      navigate(locationData?.from, { state: { dataCache: locationData?.oldDataCache ? locationData.oldDataCache : null}})
    }

    //con esto ajustamos el objeto de salida
    const returnNewObjArte = (f, id, property) => {
      //ajustamos el objeto
      let newObj = {
        property: property,
        id: id,
        id_arte: f.id_arte ? f.id_arte : null,
        nombre: f.nombre ? f.nombre : "",
        fecha_creacion: f.fecha_creacion ? f.fecha_creacion : "",
        descripcion_arte: f.descripcion_arte ? f.descripcion_arte : "",
        serie: f.serie ? f.serie : [],
        artistas: f.artistas ? f.artistas : [],
        cantidad_piezas: f.cantidad_piezas ? f.cantidad_piezas : "",
        especificacion_tecnica: f.especificacion_tecnica ? f.especificacion_tecnica : "",
        conservacion: f.conservacion ? f.conservacion : "",
        esquema_montaje: f.esquema_montaje ? f.esquema_montaje : "",
        proceso_elaboracion: f.proceso_elaboracion ? f.proceso_elaboracion : "",
        lugar_creacion: f.lugar_creacion ? f.lugar_creacion : "",
        numero_edicion: f.numero_edicion ? f.numero_edicion : "",
        imagenes: f.imagenes.map((d) => {
          return {
            ...d, 
            delete: false,
          }
        }),
      }
      return newObj
    }

    //Funcion para obtener la data de los endpoints
    const getDataEndpoint = async (url, params) => {
      let result;
      //llamamos al endpoint
      return api.get(`${url}`, params).then(response => {
        result = {error: false, data: response.data, code: 200}  
        return result
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            result = {error: true, data: [], code: err?.response?.status}
            break;
          case 404:
            result = {error: false, data: [], code: err?.response?.status}
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              2, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            result = {error: true, data: [], code: err?.response?.status}
            break;
        }
        return result
      })
    }

    //obtenemos la data del sistema
    const getData = async (id, property="id_arte") => {
      //Con esta funcion obtenemos un arte por el id
      let arte = await getDataEndpoint(`/get_artes`, {params: {[property]: id, indice_idioma: parseInt(language), filtrar_idioma: true}})

      //Si no encontramos el arte, detenemos el flujo y no hacemos nada
      if(parseInt(arte.code) === 404){
        showModal?.current?.content?.info(
          (user?.grupo_actor === 1) ? 5 : 8, //accion que tomara , //accion que tomara 
          `${translation[language]?.msg_error_arte ? translation[language]?.msg_error_arte : "Arte no encontrada, intente nuevamente."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;        
      }

      //si no hubo error al encontrar el arte, ejecutamos los otros endpoints
      let piezas = (property === "codigo_pieza") ? await getDataEndpoint(`/get_piezas`, {params: {codigo_pieza: id, boo_pagination: false}}) : {error: false, data: [], code: 400}
      let propietario_piezas = (property === "codigo_pieza" && user) ? await getDataEndpoint(`/get_propietario_piezas`, {...config, params: {codigo_pieza: id, boo_pagination: false, indice_idioma: parseInt(language)}}) : {error: false, data: [], code: 404}
      let paises = await getDataEndpoint(`/get_pais_dominio`, null)

      //acomodamos la data
      let newArte = returnNewObjArte(arte.data[0][0], id, property)
      //ordenamos las piezas por orden de adquisicion
      let newPropietarioPiezas = propietario_piezas.data.length === 0 ? [] : propietario_piezas.data[0].sort(function(a, b) {
        var dateA = parse(a.fecha_adquisicion, "yyyy-MM-dd", new Date());
        var dateB = parse(b.fecha_adquisicion, "yyyy-MM-dd", new Date());
        return dateB - dateA;
      });

      setFormFields(JSON.parse(JSON.stringify(newArte)));
      setInfoPieza(parseInt(piezas.code) === 400  ? {} : piezas.data[0][0]);
      setPropietariosPiezas(newPropietarioPiezas)
      setPaises(paises.data)
      setLoadingData(false)
      setInicio(false)
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      let id, property

      //si el location tiene id_arte
      if(locationData?.id_arte){
        id = locationData?.id_arte
        property ="id_arte"
      //si el location tiene codigo_pieza
      }else if (locationData?.codigo_pieza){
        id = locationData?.codigo_pieza
        property ="codigo_pieza"
      //de lo contrario buscamos por parametrio id_arte o codigo_pieza en la ruta
      }else{
        const query = new URLSearchParams(window.location.search);
        const idArte = query.get("id_arte");
        const codigoPieza = query.get("codigo_pieza");
        id = codigoPieza ? codigoPieza : idArte
        property = codigoPieza ? "codigo_pieza" : "id_arte"
      }
      //si no hay id significa que no se encontro el arte
      if(!id){
        showModal?.current?.content?.info(
          (user?.grupo_actor === 1) ? 5 : 8, //accion que tomara 
          `${translation[language]?.msg_error_arte ? translation[language]?.msg_error_arte : "Arte no encontrada, intente nuevamente."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
        return;
      }
      
      //obtenemos la data del formulario
      getData(id, property)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 


    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      if(!inicio){
        setLoadingData(true)
        const fetchDataArte = async () => {
          let property = formFields.property
          let id = formFields.id
          //Con esta funcion obtenemos el arte en el idioma que cambiaron, si no agarra otro para no devolver error
          let arte = await getDataEndpoint(`/get_artes`, {params: {[property]: id, indice_idioma: parseInt(language), filtrar_idioma: true}})
          setFormFields(JSON.parse(JSON.stringify(returnNewObjArte(arte.data[0][0], id, property))));
          setLoadingData(false);
        }
        fetchDataArte()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 


    return (
            <Container> 
              <ModalContent
                ref={showModal}
                // acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
              <Screen>
                <CContainer className='px-4'> 
                    <FormularioArte
                      formFields={formFields}
                      handleBack={handleBack}
                      loadingData={loadingData}
                      locationData={locationData}
                      paises={paises}
                      propietariosPiezas={propietariosPiezas}
                      infoPieza={infoPieza}
                      //traducciones
                      translation={translation}
                      language={language}
                      returnLanguageDateFormat={returnLanguageDateFormat}
                    />                            
                </CContainer>
              </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`