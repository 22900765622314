import React , { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CContainer,
  CForm
} from '@coreui/react'
import styled from 'styled-components'
import FormularioPropietario from './FormularioPropietario';
import ModalContent from '../../hooks/ModalContent'
import { globalFunctions } from "../../hooks/globalFunctions";
import api from '../../api/api'
import { format } from 'date-fns'

export default function CrudPropietario({
  user,
  admin,
  registro=false,
  //traducciones
  translation={},
  language="",
  idiomas=[],
}) {
  
    //Variables
    
    const navigate = useNavigate();
    const { 
      validationCheckText,
      validationEmailAddress,
      validationIsNumberInt,
      findObjFromArray,
      propertyTypeOf,
      returnNewObjSort
    } = globalFunctions();
    
    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null
    // Esta es la variable que almacenara los parametros que enviaremos al endpoint
    let userIdPropietario = user?.grupo_actor === 2 ? user?.id_propietario : null
    let locationIdPropietario = locationData?.id_propietario ? locationData?.id_propietario : null
    let idPropietario = userIdPropietario ? userIdPropietario : locationIdPropietario ? locationIdPropietario : null

    const showModal = useRef(); //referencia del hijo

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };
    const [inicio, setInicio] = useState(true); //con esta variable validamos si ya cargo por primera vez el form  o no
    const [changes, setChanges] = useState(false); //guarda si hubo cambios o no
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [loadingData, setLoadingData] = useState(true) //variable que indica cuando esta cargando
    const [generos, setGeneros] = useState([]) //variable para almacenar los generos
    const [estadosEnvio, setEstadosEnvio] = useState([]) //variable para almacenar los estados dependiendo de la variable pais_envio
    const [estadosFisica, setEstadosFisica] = useState([]) //variable para almacenar los estados dependiendo de la variable pais_fisica
    const [documentos, setDocumentos] = useState([]) //variable para almacenar los generos
    const [userPos, setUserPos] = useState({lat: null, long: null})
    
    
    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      nombre: "",
      apellidos: "",
      fecha_nacimiento: "",
      genero: "",
      fecha_registro: format(new Date(), "yyyy-MM-dd"),
      ciudad_nacimiento: "",
      pais_nacimiento: "",
      tipo_documento: 1,
      numero_identificacion: "",
      email: "",
      cod_telefono: "",
      telefono: "",
      cod_celular: "",
      celular: "",
      nombre_dir_fisica: "",
      direccion_fisica: "",
      codigo_postal_fisica: "",
      ciudad_fisica: "",
      estado_fisica: "",
      pais_fisica: "",
      nombre_dir_envio: "",
      direccion_envio: "",
      codigo_postal_envio: "",
      ciudad_envio: "",
      estado_envio: "",
      pais_envio: "",
      email_envio: "",
      cod_telefono_envio: "",
      telefono_envio: "",
      boo_activo: true,
      boo_estatus: false,
      pin: "",
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      nombre: false,
      apellidos: false,
      fecha_nacimiento: false,
      genero: false,
      fecha_registro: false,
      ciudad_nacimiento: false,
      pais_nacimiento: false,
      tipo_documento: false,
      numero_identificacion: false,
      email: false,
      cod_telefono: false,
      telefono: false,
      cod_celular: false,
      celular: false,
      nombre_dir_fisica: false,
      direccion_fisica: false,
      codigo_postal_fisica: false,
      ciudad_fisica: false,
      estado_fisica: false,
      pais_fisica: false,
      nombre_dir_envio: false,
      direccion_envio: false,
      codigo_postal_envio: false,
      ciudad_envio: false,
      estado_envio: false,
      pais_envio: false,
      email_envio: false,
      cod_telefono_envio: false,
      telefono_envio: false,
      pin: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      nombre: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      apellidos: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      fecha_nacimiento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      genero: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      fecha_registro: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      ciudad_nacimiento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      pais_nacimiento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      tipo_documento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      numero_identificacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      email: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      cod_telefono: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      telefono: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      cod_celular: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      celular: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      nombre_dir_fisica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      direccion_fisica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      codigo_postal_fisica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      ciudad_fisica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      estado_fisica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      pais_fisica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      nombre_dir_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      direccion_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      codigo_postal_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      ciudad_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      estado_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      pais_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      email_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      cod_telefono_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      telefono_envio: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      pin: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });


    const [paises, setPaises] = useState([]); //lista de paises

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value, checked} = variable;
      let fieldsObj;

      switch (name) {
        case "boo_activo":
          fieldsObj = { ...formFields, [name]: checked}
          break;
        case "cod_celular":
        case "celular":
        case "cod_telefono":
        case "telefono":
        case "cod_telefono_envio":
        case "telefono_envio":
          fieldsObj = { ...formFields, [name]: value.replace(/^0+/, '')}
          break;
        case "pais_envio":
          fieldsObj = { ...formFields, [name]: value, estado_envio: ""}
          break;
        case "pais_fisica":
          fieldsObj = { ...formFields, [name]: value, estado_fisica: ""}
          break;
        default:
          fieldsObj = { ...formFields, [name]: value}
          break;
      }
      updateErrors(name)
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChanges(true)
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(!formFields['nombre']) {
        errorsObj['nombre'] = true
        errorsObjText['nombre'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationCheckText(formFields['nombre'])){
        errorsObj['nombre'] = true
        validState = false      
        errorsObjText['nombre'] = translation[language]?.campo_invalido ? translation[language]?.campo_invalido : "Campo inválido. Introduce letras, acentos y espacio"  
      }

      if(!formFields['apellidos']) {
        errorsObj['apellidos'] = true
        errorsObjText['apellidos'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationCheckText(formFields['apellidos'])){
        errorsObj['apellidos'] = true
        validState = false      
        errorsObjText['apellidos'] = translation[language]?.campo_invalido ? translation[language]?.campo_invalido : "Campo inválido. Introduce letras, acentos y espacio"  
      }

      if(!formFields['fecha_nacimiento']) {
        errorsObj['fecha_nacimiento'] = true
        errorsObjText['fecha_nacimiento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['genero']) {
        errorsObj['genero'] = true
        errorsObjText['genero'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }


      if(!formFields['pais_nacimiento']) {
        errorsObj['pais_nacimiento'] = true
        errorsObjText['pais_nacimiento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['ciudad_nacimiento']) {
        errorsObj['ciudad_nacimiento'] = true
        errorsObjText['ciudad_nacimiento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['email']) {
        errorsObj['email'] = true
        errorsObjText['email'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationEmailAddress(formFields['email'])){
        errorsObj['email'] = true
        errorsObjText['email'] = translation[language]?.correo_invalido ? translation[language]?.correo_invalido : "Correo Inválido"
        validState = false            
      }

      if(!formFields['numero_identificacion']) {
        errorsObj['numero_identificacion'] = true
        errorsObjText['numero_identificacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['tipo_documento']) {
        errorsObj['tipo_documento'] = true
        errorsObjText['tipo_documento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['cod_telefono']) {
        errorsObj['cod_telefono'] = true
        errorsObjText['cod_telefono'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['cod_telefono'])){
        errorsObj['cod_telefono'] = true
        errorsObjText['cod_telefono'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      if(!formFields['telefono']) {
        errorsObj['telefono'] = true
        errorsObjText['telefono'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['telefono'])){
        errorsObj['telefono'] = true
        errorsObjText['telefono'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      if(!formFields['cod_celular']) {
        errorsObj['cod_celular'] = true
        errorsObjText['cod_celular'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['cod_celular'])){
        errorsObj['cod_celular'] = true
        errorsObjText['cod_celular'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      if(!formFields['celular']) {
        errorsObj['celular'] = true
        errorsObjText['celular'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['celular'])){
        errorsObj['celular'] = true
        errorsObjText['celular'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      if(!formFields['codigo_postal_fisica']) {
        errorsObj['codigo_postal_fisica'] = true
        errorsObjText['codigo_postal_fisica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['ciudad_fisica']) {
        errorsObj['ciudad_fisica'] = true
        errorsObjText['ciudad_fisica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['estado_fisica']) {
        errorsObj['estado_fisica'] = true
        errorsObjText['estado_fisica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['pais_fisica']) {
        errorsObj['pais_fisica'] = true
        errorsObjText['pais_fisica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }
      
      //direccion fisica

      if(!formFields['nombre_dir_fisica']) {
        errorsObj['nombre_dir_fisica'] = true
        errorsObjText['nombre_dir_fisica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }      

      if(!formFields['direccion_fisica']) {
        errorsObj['direccion_fisica'] = true
        errorsObjText['direccion_fisica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      //direccion de envio
      if(!formFields['nombre_dir_envio']) {
        errorsObj['nombre_dir_envio'] = true
        errorsObjText['nombre_dir_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['direccion_envio']) {
        errorsObj['direccion_envio'] = true
        errorsObjText['direccion_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['codigo_postal_envio']) {
        errorsObj['codigo_postal_envio'] = true
        errorsObjText['codigo_postal_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['ciudad_envio']) {
        errorsObj['ciudad_envio'] = true
        errorsObjText['ciudad_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['estado_envio']) {
        errorsObj['estado_envio'] = true
        errorsObjText['estado_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['pais_envio']) {
        errorsObj['pais_envio'] = true
        errorsObjText['pais_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }


      // if(!formFields['email_envio']) {
      //   errorsObj['email_envio'] = true
      //   errorsObjText['email_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
      //   validState = false
      // }else if(!validationEmailAddress(formFields['email_envio'])){
      //   errorsObj['email_envio'] = true
      //   errorsObjText['email_envio'] = translation[language]?.correo_invalido ? translation[language]?.correo_invalido : "Correo Inválido"
      //   validState = false            
      // }

      // if(!formFields['cod_telefono_envio']) {
      //   errorsObj['cod_telefono_envio'] = true
      //   errorsObjText['cod_telefono_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
      //   validState = false
      // }else if(!validationIsNumberInt(formFields['cod_telefono_envio'])){
      //   errorsObj['cod_telefono_envio'] = true
      //   errorsObjText['cod_telefono_envio'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
      //   validState = false            
      // }

      // if(!formFields['telefono_envio']) {
      //   errorsObj['telefono_envio'] = true
      //   errorsObjText['telefono_envio'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
      //   validState = false
      // }else if(!validationIsNumberInt(formFields['telefono_envio'])){
      //   errorsObj['telefono_envio'] = true
      //   errorsObjText['telefono_envio'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
      //   validState = false            
      // }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //funciones

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          goBack()
          break;
        case 2:
          navigate('/consultar-artes')
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //Con esto enviaremos de regreso al usuario al dar clic en back
    const handleBack = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        goBack()
      }
    }

    //con esto volvemos atras
    const goBack = () => {
      if(admin){
        navigate('/admin-propietarios', { state: { dataCache: locationData.oldDataCache}})
      }else if(!admin && registro){
        navigate('/registro-arte')
      }
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      //si hubo cambios
      if(!changes && !registro){
        goBack()
      }else{
        //si todos los campos no estan llenos no avanza
        if (!validateFields()) {
          showModal?.current?.content?.info(
            null, //accion que tomara 
            `${translation[language]?.msg_campos_obligatorios ? translation[language]?.msg_campos_obligatorios : "Por favor, complete los campos obligatorios"}`, //mensaje principal
            null, //mensaje secundario
            false, //indica si hay o no boton de cancelar
            `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
          );
          return       
        }
        //si todos los campos estan llenos llamamos a goBack
        putPropietario()
      }
    }

    //Funcion para generar una contrasena automaticamente
    const generatePin = () => {
      return `${(Math.floor(100000 + Math.random() * 900000))}`;
    };

    //Funcion para obtener los dominios que usaremos
    const getDataDominio = async (url) => {
      let result;
      //llamamos al endpoint
      return api.get(`${url}`).then(response => {
        result = {error: false, data: response.data}  
        return result
      }).catch(err => {
        switch (err?.response?.status) {
          case 404:
            result = {error: false, data: []}
            break;
          default:
            result = {error: true, data: []}
            break;
        }
        return result
      })
    }

    //Funcion para obtener los dominios que usaremos
    const getLocationFromUser = async () => {
      return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            resolve(position.coords);
          }, reject);
        } else {
          reject('Geolocation not supported');
        }
      });
    }

    //retornamos las coordenadas
    const getCoords = async () => {
      let coords;
      try {
        let defaultLocation = await getLocationFromUser();
        coords =  {lat: defaultLocation.latitude, long: defaultLocation.longitude}
      } catch (error) {
        coords = {lat: 0, long: 0}
      }
      return coords;
    };

    //obtenemos la data del sistema
    const getData = async (loadPropietario=true) => {

      //si es un registro, establecemos el correo que colocaron en la vista anterior
      const propietarioData = {
        error: false, 
        data: {
          ...formFields,
          apellidos: locationData?.apellidos ? locationData?.apellidos : "",
          email: locationData?.email ? locationData?.email  : "",
          nombre: (locationData?.nombre && locationData?.nombre !== "None") ? locationData?.nombre  : "",
          cod_celular: locationData?.cod_celular ? locationData?.cod_celular : "",
          celular: locationData?.celular ? locationData?.celular : "",
        }
      }

      //obtenemos el propietario
      let propietario = loadPropietario ? await getPropietario() : propietarioData
      //obtenemos los paises
      let paises = await getDataDominio(`/get_pais_dominio?&indice_idioma=${parseInt(language)}`)
      //obtenemos los estados segun la variable pais_fisica
      let estado_fisica = parseInt(propietario.data.pais_fisica) > 0 ? await getDataDominio(`get_estados_dominio?fk_pais=${findObjFromArray(paises.data, "indice", parseInt(propietario.data.pais_fisica))?.id_pais}&indice_idioma=${parseInt(language)}`) : {error: false, data: []}
      //obtenemos los estados segun la variable pais_envio
      let estado_envio = parseInt(propietario.data.pais_envio) > 0 ? await getDataDominio(`get_estados_dominio?fk_pais=${findObjFromArray(paises.data, "indice", parseInt(propietario.data.pais_envio))?.id_pais}&indice_idioma=${parseInt(language)}`) : {error: false, data: []}
      //obtenemos los generos
      let newGeneros = await getDataDominio("/get_genero_dominio")
      //obtenemos los tipos de documentos
      let tipo_documentos = await getDataDominio("/get_tipo_documento")
      //obtenemos las coordenadas del usuario
      let newCoords = await getCoords()
      //Si no hay error en alguno de los endpoints guardamos las variables
      if(!propietario.error && !paises.error && !estado_fisica.error && !estado_envio.error && !newGeneros.error && !tipo_documentos.error && newCoords){
        setPaises(paises.data)
        setFormFields(propietario.data)
        setDocumentos(tipo_documentos.data)
        setGeneros(newGeneros.data)
        setUserPos(newCoords)
        setEstadosFisica(estado_fisica.data)
        setEstadosEnvio(estado_envio.data)
        setInicio(false)
        setLoadingData(false)
      }
    }

    //Con esta funcion obtenemos un propietario por el id
    const getPropietario = async () => {
      let obj = {
        id_propietario: idPropietario,
      };
      const newConfig = {...config, params: obj}
      return api.get(`/get_propietarios`, newConfig).then(response => {
        let f = response.data.propietarios[0]
        let newObj = {
          nombre: f.nombre ? f.nombre : "",
          apellidos: f.apellidos ? f.apellidos : "",
          fecha_nacimiento: f.fecha_nacimiento,
          genero: f.genero ? parseInt(f.genero) : 1,
          fecha_registro: f.fecha_registro ?  f.fecha_registro : "",
          ciudad_nacimiento: f.ciudad_nacimiento ? f.ciudad_nacimiento : "",
          pais_nacimiento: parseInt(f.pais_nacimiento)>=0 ? parseInt(f.pais_nacimiento) : "",
          tipo_documento: parseInt(f.tipo_documento)>=0 ? parseInt(f.tipo_documento) : 1,
          numero_identificacion: f.numero_identificacion ? f.numero_identificacion : "",
          email: f.email ? f.email : "",
          cod_telefono: (f.telefono && f?.telefono?.split('-')[0]) ? f?.telefono.split('-')[0].replace("+", "") : "",
          telefono: (f.telefono && f?.telefono.split('-')[1]) ? f?.telefono.split('-')[1] : "",
          cod_celular: (f.celular && f?.celular.split('-')[0]) ? f?.celular.split('-')[0].replace("+", "") : "",
          celular: (f.celular && f?.celular.split('-')[1]) ? f?.celular.split('-')[1] : "",
          nombre_dir_fisica: f.nombre_dir_fisica ? f.nombre_dir_fisica : "",
          direccion_fisica: f.direccion_fisica ? f.direccion_fisica : "",
          codigo_postal_fisica: f.codigo_postal_fisica ? f.codigo_postal_fisica : "",
          ciudad_fisica: f.ciudad_fisica ? f.ciudad_fisica : "",
          estado_fisica: parseInt(f.estado_fisica) > 0 ? parseInt(f.estado_fisica) : "",
          pais_fisica: parseInt(f.pais_fisica) > 0 ? parseInt(f.pais_fisica) : "",
          nombre_dir_envio: f.nombre_dir_envio ? f.nombre_dir_envio : "",
          direccion_envio: f.direccion_envio ? f.direccion_envio : "",
          codigo_postal_envio: f.codigo_postal_envio ? f.codigo_postal_envio : "",
          ciudad_envio: f.ciudad_envio ? f.ciudad_envio : "",
          estado_envio: parseInt(f.estado_envio) > 0 ? parseInt(f.estado_envio) : "",
          pais_envio: parseInt(f.pais_envio) > 0 ? parseInt(f.pais_envio) : "",
          cod_telefono_envio: (f.telefono_envio && f?.telefono_envio?.split('-')[0]) ? f?.telefono_envio.split('-')[0].replace("+", "") : "",
          telefono_envio: (f.telefono_envio && f?.telefono_envio.split('-')[1]) ? f?.telefono_envio.split('-')[1] : "",
          email_envio: f.email_envio ? f.email_envio : "",
          boo_activo: f.boo_activo ? f.boo_activo : false,
          boo_estatus: f.boo_estatus ? f.boo_estatus : true,
          pin: null,
        }
        return {error: false, data: newObj}; 
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          default:
            break;
        }
        return {error: true, data: []};
      })
    }

    //con esta función insertamos o actualizamos un propietario
    const putPropietario = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
      //si no es admin y es el registro generamos un
      let newPin = (!admin && registro) ? generatePin() : null
      let f = formFields
      let newObj = {
        id_propietario: idPropietario,
        nombre: f.nombre ? f.nombre : "",
        apellidos: f.apellidos ? f.apellidos : "",
        fecha_nacimiento: f.fecha_nacimiento,
        genero: parseInt(f.genero),
        ciudad_nacimiento: f.ciudad_nacimiento ? f.ciudad_nacimiento : "",
        tipo_documento: parseInt(f.tipo_documento),
        pais_nacimiento: parseInt(f.pais_nacimiento),
        numero_identificacion: f.numero_identificacion,
        email: f.email ? f.email : "",
        telefono: `+${f.cod_telefono}-${f.telefono}`,
        celular: `+${f.cod_celular}-${f.celular}`,
        nombre_dir_fisica: f.nombre_dir_fisica ? f.nombre_dir_fisica : "",
        direccion_fisica: f.direccion_fisica ? f.direccion_fisica : "",
        codigo_postal_fisica: f.codigo_postal_fisica ? f.codigo_postal_fisica : "",
        ciudad_fisica: f.ciudad_fisica ? f.ciudad_fisica : "",
        estado_fisica: parseInt(f.estado_fisica) > 0 ? parseInt(f.estado_fisica) : 0,
        pais_fisica: parseInt(f.pais_fisica) > 0 ? parseInt(f.pais_fisica) : 0,
        nombre_dir_envio: f.nombre_dir_envio ? f.nombre_dir_envio : "",
        direccion_envio: f.direccion_envio ? f.direccion_envio : "",
        codigo_postal_envio: f.codigo_postal_envio ? f.codigo_postal_envio : "",
        ciudad_envio: f.ciudad_envio ? f.ciudad_envio : "",
        estado_envio: parseInt(f.estado_envio) > 0 ? parseInt(f.estado_envio) : 0,
        pais_envio: parseInt(f.pais_envio) > 0 ? parseInt(f.pais_envio) : 0,
        email_envio: f.email_envio ? f.email_envio : "",
        telefono_envio: `+${f.cod_telefono_envio}-${f.telefono_envio}`,
        boo_activo: f.boo_activo,
        boo_estatus: locationData?.transicion ? true : f.boo_estatus,
        latitude: (!admin && registro) ? (userPos.lat ? userPos.lat : null) : null,
        longitude: (!admin && registro) ?(userPos.long ? userPos.long : null) : null,
        clave: newPin,
      }
      let a = user ? config : null
      api.put("/put_propietario", newObj, a).then(response => {
        let newObj2 = {
          ...newObj,
          id_propietario: response.data.id_propietario
        }
        actionAfterPutPropietario(newObj2)
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
          case 405:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 409:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_correo_ya_registrado ? translation[language]?.msg_correo_ya_registrado : "El correo electrónico ya se encuentra registrado, por favor intente nuevamente con otro."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break 
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }


    //con esta función insertamos una pieza
    const putPropietarioPieza = (obj) => {
      let newObj = {
        propietario_piezas: [{
          fk_pieza: locationData?.fk_pieza ? locationData?.fk_pieza : null,
          codigo_invitacion: locationData?.codigo_invitacion ? locationData?.codigo_invitacion : null,
          fk_propietario: obj?.id_propietario ? obj?.id_propietario : null,
          fecha_adquisicion: format(new Date(), "yyyy-MM-dd"),
          fecha_venta: format(new Date(), "yyyy-MM-dd"),
        }]
      }
      api.put("/put_propietario_pieza", newObj).then(response => {
        let newObj2 = {
          ...locationData,
          id_propierario: obj?.id_propietario,
          email: obj?.email,
          nombre: obj?.nombre,
          pin: obj?.clave,
          autoLogin: true, 
        }
        navigate('/consultar-artes', { state: { dataCache: newObj2}})
      }).catch(error => {
      })
    }

    //con esto vamos a decidir que accion tomar luego del put
    const actionAfterPutPropietario = (obj) => {
      if(admin){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
      //si no es admin y estamos registrando, enviamos a la ficha 
      }else if(!admin && registro && !locationData?.transicion){
        putPropietarioPieza(obj)
      }else if (!admin && registro && locationData?.transicion){
        let newObj2 = {
          email: obj?.email,
          pin: obj?.clave,
          autoLogin: true, 
        }
        navigate('/consultar-artes', { state: { dataCache: newObj2}})
      //si no es admin y tampoco es el registro. Un propietario esta editando los datos
      }else if(!admin && !registro){
        setChanges(false)
        showModal?.current?.content?.info(
          null, //accion que tomara 
          `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
      }
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      //si es admin 
      if(admin){
        if(!user){
          showModal?.current?.content?.authentication(
            1, //accion que tomara 
            `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
            `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          );
          return;
        }
        if(user?.grupo_actor !== 1){
          showModal?.current?.content?.info(
            1, //accion que tomara 
            `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
            null, //mensaje secundario
            false, //indica si hay o no boton de cancelar
            `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
          );
          return;
        }
        getData(true)
      //si no es admin, significa que es usuario
      }else{
        if(registro){
          if(!locationData){
            setAcceptAction(1)
            showModal?.current?.content?.info(
              3, //accion que tomara 
              `${translation[language]?.msg_codigo_invitacion_pieza_valido ? translation[language]?.msg_codigo_invitacion_pieza_valido : "Debes colocar un código de invitación y un código de pieza válido."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
          } 
          getData(false)
        }else{
          if(user?.grupo_actor !== 2){
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_funcion_solo_propietarios ? translation[language]?.msg_funcion_solo_propietarios : "Función disponible solo para Propietarios."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            return;
          }
          getData(true)          
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //Llamamos al endpoint de estados con el fk_pais nuevo pais_envio seleccionado
    useEffect(()=> {
      if(!inicio && changes){
        const fetchDataEstadosEnvio = async () => {
          let estado_envio = parseInt(formFields.pais_envio) > 0 ? await getDataDominio(`get_estados_dominio?fk_pais=${findObjFromArray(paises, "indice", parseInt(formFields.pais_envio))?.id_pais}&indice_idioma=${parseInt(language)}`) : {error: false, data: []}
          setEstadosEnvio(JSON.parse(JSON.stringify(estado_envio.data)));
        }
        fetchDataEstadosEnvio()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields.pais_envio]) 


    //Llamamos al endpoint de estados con el fk_pais nuevo pais_fisica seleccionado
    useEffect(()=> {
      if(!inicio && changes){
        const fetchDataEstadosFisica = async () => {
          let estado_fisica = parseInt(formFields.pais_fisica) > 0 ? await getDataDominio(`get_estados_dominio?fk_pais=${findObjFromArray(paises, "indice", parseInt(formFields.pais_fisica))?.id_pais}&indice_idioma=${parseInt(language)}`) : {error: false, data: []}
          setEstadosFisica(JSON.parse(JSON.stringify(estado_fisica.data)));
        }
        fetchDataEstadosFisica()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFields.pais_fisica]) 

    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])    

    //ordenamos el arreglo segun el indice de idioma
    useEffect(()=> {
      if(!inicio){
        let variable_paises = propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`
        //obtenemos los estados segun la variable pais_fisica
        let variable_estado_fisica = propertyTypeOf(estadosFisica.length === 0 ? 'undefined' : estadosFisica[0][`estado_idioma_${language}`]) === 'Undefined' ? `estado_idioma_1` : `estado_idioma_${language}`
        //obtenemos los estados segun la variable pais_envio
        let variable_estado_envio = propertyTypeOf(estadosEnvio.length === 0 ? 'undefined' : estadosEnvio[0][`estado_idioma_${language}`]) === 'Undefined' ? `estado_idioma_1` : `estado_idioma_${language}`      
        //con esto hacemos el sort
        returnNewObjSort(paises, variable_paises, false)
        returnNewObjSort(estadosFisica, variable_estado_fisica, false)
        returnNewObjSort(estadosEnvio, variable_estado_envio, false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 

    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CForm 
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <FormularioPropietario
                        formFields={formFields}
                        formErrors={formErrors}
                        formErrorsText={formErrorsText}
                        handleChange={handleChange}
                        handleBack={handleBack}
                        generos={generos}
                        admin={admin}
                        loadingData={loadingData}
                        paises={paises}
                        registro={registro}
                        documentos={documentos}
                        estadosEnvio={estadosEnvio}
                        estadosFisica={estadosFisica}
                        //traducciones
                        translation={translation}
                        language={language}  
                        idiomas={idiomas} 
                      />
                    </CForm>                              
                  </CContainer>
                </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`