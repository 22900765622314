import React, {useState} from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CFormFeedback,
  CSpinner
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  CInput, 
  FormTextarea,
  Button, 
  ButtonVariant, 
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import CustomInputDate from '../../../hooks/CustomInputDate'
import { globalFunctions } from "../../../hooks/globalFunctions";
import Select from '../../../hooks/CustomSelect'
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function CardContent({
  handleBack,
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  paises,
  goToVerArte,
  edit=false,
  loadingData,
  variablesEnv,
  //traducciones
  translation={},
  language="",
  idiomas=[]
}) {
    //cosas personalizadas para el estilo del select
    const customStylesSelect = {
      fontSize: "12pt",
    }

    const { 
      findObjFromArray,
      propertyTypeOf
    } = globalFunctions();

    const [showImages, setShowImages] = useState(3);

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-4">
                    <Header
                      title={edit ? `${translation[language]?.titulo_editar_artista ? translation[language]?.titulo_editar_artista : "Editar Artista"}` : `${translation[language]?.titulo_nuevo_artista ? translation[language]?.titulo_nuevo_artista : "Nuevo Artista"}`}
                      isBack={true}
                      handleBack={handleBack}
                    />
                    {loadingData ? (
                      <CRow>
                        <CCol xs={12} className={`d-flex align-items-center mb-5 mt-3 justify-content-center`}>
                          <div className="text-center">
                            <CSpinner size="sm"/>
                              <CSpinner size="sm" variant="grow"/>
                              <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                          </div>                       
                        </CCol>
                      </CRow>
                    ) : (
                      <>
                        <CRow className="d-flex justify-content-center py-4 font-size-12">
                          <CCol lg={8} xxl={8}>
                            <CRow className="mb-0">

                              <CCol md={8}>
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.nombres_y_apellidos ? translation[language]?.nombres_y_apellidos : "Nombres y Apellidos"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="nombre_apellido"
                                  value={formFields.nombre_apellido}
                                  invalid={formErrors.nombre_apellido}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.nombre_apellido}</CFormFeedback> 
                              </CCol>

                              <CCol md={4}>
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.seudonimo ? translation[language]?.seudonimo : "Seudónimo"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="seudonimo"
                                  value={formFields.seudonimo}
                                  invalid={formErrors.seudonimo}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.seudonimo}</CFormFeedback> 
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.pais_nacimiento ? translation[language]?.pais_nacimiento : "País de nacimiento"}`}
                                </CFormLabel>
                                <Select 
                                  options={paises}
                                  nombre="pais_nacimiento"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.pais_nacimiento} 
                                  error={formErrors.pais_nacimiento} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.pais_nacimiento && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.pais_nacimiento}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                    {`${translation[language]?.ciudad_nacimiento ? translation[language]?.ciudad_nacimiento : "Ciudad de nacimiento"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="ciudad_nacimiento"
                                  value={formFields.ciudad_nacimiento}
                                  invalid={formErrors.ciudad_nacimiento}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.ciudad_nacimiento}</CFormFeedback> 
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.fecha_nacimiento ? translation[language]?.fecha_nacimiento : "Fecha de nacimiento"}`}
                                </CFormLabel>
                                <CustomInputDate
                                  nombre="fecha_nacimiento"
                                  error={formErrors.fecha_nacimiento}
                                  handleChange={handleChange}
                                  valor={formFields.fecha_nacimiento}
                                  //traducciones
                                  language={findObjFromArray(idiomas, "indice", parseInt(language)) ? findObjFromArray(idiomas, "indice", parseInt(language))?.abreviado : 'es'}
                                  placeholderText={translation[language]?.etiqueta_formato_fecha ? translation[language]?.etiqueta_formato_fecha : "dd/mm/aaaa"}                                  
                                />                     
                                {formErrors.fecha_nacimiento && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.fecha_nacimiento}</CFormFeedback> 
                                )}
                              </CCol>
                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.titulos_experiencia ? translation[language]?.titulos_experiencia : "Títulos y experiencia"}`}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="3"
                                  className="font-size-12"
                                  name="titulo_experiencia"
                                  value={formFields.titulo_experiencia}
                                  invalid={formErrors.titulo_experiencia}
                                  onChange={handleChange}
                                />       
                                <CFormFeedback invalid>{formErrorsText.titulo_experiencia}</CFormFeedback>
                              </CCol>

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.brief_corto ? translation[language]?.brief_corto : "Brief corto"}`}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="2"
                                  className="font-size-12"
                                  name="brief_corto"
                                  value={formFields.brief_corto}
                                  invalid={formErrors.brief_corto}
                                  onChange={handleChange}
                                />
                                <CFormFeedback invalid>{formErrorsText.brief_corto}</CFormFeedback>
                              </CCol>

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.brief_largo ? translation[language]?.brief_largo : "Brief largo"}`}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="3"
                                  className="font-size-12"
                                  name="brief_largo"
                                  value={formFields.brief_largo}
                                  invalid={formErrors.brief_largo}
                                  onChange={handleChange}
                                />
                                <CFormFeedback invalid>{formErrorsText.brief_largo}</CFormFeedback>
                              </CCol>
                            </CRow>
                            <br/>
                            <hr className='mb-0 primary-color'/>
                            <div className="mt-4 mb-5 d-flex justify-content-end">
                              <ButtonVariant
                                className="me-3"
                                variant={'outline'}
                                onClick={handleBack}
                              >
                                {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                              </ButtonVariant>
                              <Button 
                                type="submit"
                              >
                                {`${translation[language]?.boton_guardar ? translation[language]?.boton_guardar : "Guardar"}`}
                              </Button>                    
                            </div>
                            {formFields.id_artista && (
                              <>
                                <CContainer className="bg-custom py-2 d-flex align-items-center">
                                  <CFormLabel className="mt-0 mb-0">
                                  {`${translation[language]?.lista_artes_realizadas ? translation[language]?.lista_artes_realizadas : "Lista de Artes realizados con"}`} {variablesEnv?.REACT_APP_NAME}
                                  </CFormLabel>
                                </CContainer>
                                <CRow>
                                  {formFields.artes.length > 0 ? (
                                    <>
                                      {formFields.artes.slice(0, showImages).map((arte, i) =>
                                        <CCol key={i} md={4} className={`mt-3`}>
                                          <div className="border border-color">
                                            <div className="box2 big2">
                                              <LazyLoadImage
                                                alt={"pic"}
                                                effect="blur" 
                                                className='foto'
                                                src={arte.imagen ? arte.imagen : "/sin-imagen.png"}
                                                placeholderSrc={arte.imagen ? arte.imagen : "/sin-imagen.png"}
                                              /> 
                                            </div>
                                          </div>
                                          <div 
                                            className="text-center py-2 bg-color cursor-pointer text-white text-underline"
                                            onClick={()=>goToVerArte(arte)}
                                          >
                                            {arte.nombre ? arte.nombre : ""}
                                          </div>
                                        </CCol> 
                                      )}  
                                      {formFields.artes.length > 3 && (
                                        <>
                                          {showImages >= formFields.artes.length ? (
                                            <>
                                              <div 
                                                onClick={()=> setShowImages(3)}
                                                className="text-end mt-3 cursor-pointer text-underline"
                                              >
                                                {`${translation[language]?.etiqueta_ver_menos ? translation[language]?.etiqueta_ver_menos : "Ver menos"}`}
                                              </div>  
                                            </>
                                          ) : (
                                            <>
                                            <div 
                                              onClick={()=> setShowImages(showImages + 3)}
                                              className="text-end mt-3 cursor-pointer text-underline"
                                            >
                                              {`${translation[language]?.etiqueta_ver_mas ? translation[language]?.etiqueta_ver_mas : "Ver más"}`}
                                            </div> 
                                            </>
                                          )}  
                                        </>
                                      )}                                                             
                                    </>
                                  ) : (
                                    <>
                                      <CCol md={12} className="mt-2">
                                        <div 
                                            className="d-flex primary-text-color font-size-12" 
                                          >
                                          <img
                                            src={infoIcon}
                                            alt="pin"
                                            width="16"
                                            height="16"
                                            className="me-2 mt-1"
                                          />
                                            {`${translation[language]?.artistas_sin_artes ? translation[language]?.artistas_sin_artes : "Artista sin Artes registradas en la aplicación."}`}
                                        </div> 
                                      </CCol>  
                                    </>
                                  )}                            
                                </CRow> 
                              </>
                            )}
                          </CCol>
                        </CRow>
                      </>
                    )}   
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
.bg-custom{
  border-top: 1px solid var(--primary-color);
  background-color: #F7F5EF;
}
`
