import React from 'react'
import {
  CRow, 
  CCol,
  CInputGroup,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu
} from '@coreui/react'
import {
  CInput,
  CInputGroupImg,
  Button,
  DropdownItem
} from '../hooks/styled'
import styled from "styled-components";
import Select from '../hooks/CustomSelect'
import { globalFunctions } from "../hooks/globalFunctions";
import closeIcon from '../icons/Icon material-close.svg'
import buscarIcon from '../icons/buscar.svg'
import logoGold from "../icons/logo90.svg";
import logoIcon from "../icons/Logo-icon.svg";

export default function Header({
  title,
  subtitle="",
  handleBack,
  isLine=true,
  isBack=true,
  isSearchAndFilter=false,
  isFilter=false,
  filtros,
  dataConfig,
  setDataConfig,
  handleChangeFiltros,
  placeholderSearch,
  isButton,
  buttonName,
  ButtonFunction,
  contentp=null,
  isLogo=false,
  changeOrderLogo=true,
  islogoIcon=false,
  //idiomas
  showDropLanguage=false,
  changeLanguage,
  idiomas=[],
  language="",
  showValuesText="",
  filterByText="",
  selectText="",
  noOptionsMessage=""

}) {

    const { 
      findObjFromArray
    } = globalFunctions();

    //cosas personalizadas para el estilo del select
    const customStylesSelect = {
      fontSize: "12pt",
      width: "150px",
    }

    //cosas personalizadas para el estilo del select
    const customStylesSelect2 = {
      fontSize: "12pt",
      width: "90px",
    }

    //arreglo con el showValues
    const showValues = [
      { value: 6, label: '6'},
      { value: 12, label: '12'},
      { value: 18, label: '18'},
      { value: 24, label: '24'},
    ]
  
    return (
            <Content>
              <div className="pt-4 pb-3">        
                <CRow className="font-weight-bold d-flex align-items-center">
                  <CCol xs={((isBack && !isLogo) || (!isLogo && showDropLanguage)) ? 10 : 12}>
                    {isLogo ? (
                      <CRow className={`d-flex align-items-end ${(title && isBack) ? 'justify-content-between' : ((!title && !isBack) ? 'justify-content-center' : '')}`}>
                        {(title || (!title && isBack)) && (
                          <CCol xs={isBack ? '4' : '6'} sm="4" className={`${((!title && isBack) || !changeOrderLogo) ? 'order-1' : 'order-2 order-sm-1'} align-items-end`}>
                            {title}
                          </CCol>
                        )}
                        <CCol xs={isBack ? '4' : '12'} sm="4" className={`${((!title && isBack) || !changeOrderLogo) ? 'order-2' : 'order-1 order-sm-2'} text-center text-sm-center`}>
                          <img
                            src={islogoIcon ? logoIcon : logoGold}
                            alt="logo"
                            width={islogoIcon ? "75" : "90"}
                            height={islogoIcon ? "75" : "90"}
                          />
                        </CCol>  
                        {(isBack || (title && !isBack)) && (
                        <CCol xs={isBack ? '4' : '12'} sm="4" className="order-3 order-sm-3 text-sm-end"> 
                          {showDropLanguage && (
                            <CDropdown variant="dropdown" direction="center">
                              <CDropdownToggle color="secondary" className='dropdown-p shadow-none p-0'>{findObjFromArray(idiomas, "indice", parseInt(language))?.idioma}</CDropdownToggle>
                              <CDropdownMenu>
                                {idiomas.map((idioma) => (
                                  <DropdownItem 
                                    key={idioma.indice}
                                    href="#" 
                                    onClick={()=> changeLanguage(idioma.indice)}
                                  >
                                    {idioma.idioma}
                                  </DropdownItem>
                                ))}
                              </CDropdownMenu>
                            </CDropdown> 
                          )}                    
                          {isBack && (
                            <img
                              height="16"
                              width="16"
                              src={closeIcon}
                              className="cursor-pointer"
                              onClick={handleBack}
                              alt="x"
                            />                             
                          )}
                        </CCol> 
                        )}                  
                      </CRow> 
                    ) : (
                      <div className={`d-lg-flex align-items-center`}>
                        <div>{title}</div>
                        {subtitle && (
                          <>
                            <div className="mx-2 d-none d-lg-inline-flex">|</div> 
                            <div className="text-ellipsis">{subtitle}</div>
                          </>
                        )}
                      </div>
                    )}
                  </CCol>
                  {(isBack && !isLogo) &&(
                    <CCol xs={2} className="text-end">
                      <img
                        height="16"
                        width="16"
                        src={closeIcon}
                        className="cursor-pointer"
                        onClick={handleBack}
                        alt="x"
                      /> 
                    </CCol> 
                  )}
                  {(!isBack && !isLogo && showDropLanguage) &&(
                    <CCol xs={12} md="2" className="text-md-end">
                      <CDropdown variant="dropdown" direction="center">
                        <CDropdownToggle color="secondary" className='dropdown-p shadow-none p-0'>{findObjFromArray(idiomas, "indice", parseInt(language))?.idioma}</CDropdownToggle>
                        <CDropdownMenu>
                          {idiomas.map((idioma) => (
                            <DropdownItem 
                              key={idioma.indice}
                              href="#" 
                              onClick={()=> changeLanguage(idioma.indice)}
                            >
                              {idioma.idioma}
                            </DropdownItem>
                          ))}
                        </CDropdownMenu>
                      </CDropdown> 
                    </CCol> 
                  )}
                </CRow>
                {isLine && (
                  <hr className='mt-3 mb-0 primary-color'/>
                )}   
              </div>
              {isButton &&(
                <div className='d-flex justify-content-end mb-4'>
                  <Button 
                    className="text-white font-size-12"
                    color="success" 
                    style={{padding: '.3rem 1.2rem'}}
                    onClick={ButtonFunction}
                  >
                      {buttonName}
                  </Button>
                </div>
              )}
              {isSearchAndFilter && (
                <CRow className="mb-3">
                  <CCol lg="6" className="d-sm-flex align-items-center mb-2 mb-lg-0">
                    {contentp ? (
                      <>
                      {contentp}
                      </>
                    ) : (
                      <>
                        {isFilter && (
                          <>
                            <div className="me-3 mb-2 mb-md-0">{filterByText ? filterByText : "Filtrar por"}</div>                  
                            <Select 
                              options={filtros}
                              nombre="filter"
                              placeholder={selectText ? selectText : "Seleccionar"}
                              isSearchable={false} 
                              handleChange={(e)=>{
                                setDataConfig({
                                  ...dataConfig,
                                  filter: e.target.value
                                })
                              }}
                              valor={dataConfig.filter} 
                              error={false} 
                              styles={customStylesSelect}
                              noOptionsMessage={`${noOptionsMessage ? noOptionsMessage : "Sin resultados"}`}
                            />
                          </>
                        )}
                      </>
                    )}
                  </CCol>
                  <CCol lg="6" className="d-sm-flex align-items-center justify-content-lg-end">
                    <CInputGroup className="my-2 my-sm-0">
                      <CInput
                        type={"text"}
                        size="sm"
                        className={`border-end-0 font-size-12 py-1`}
                        name="search"
                        placeholder={placeholderSearch}
                        value={dataConfig?.searchTerm}
                        onChange={handleChangeFiltros}
                      />
                      { (dataConfig.searchTerm !== "") &&
                        <CInputGroupImg
                          className={``}
                        >
                          <img 
                              src={closeIcon}
                              alt="eyes" 
                              width="16"
                              height="16"
                              className="cursor-pointer"
                              onClick={()=>{
                                setDataConfig({
                                  ...dataConfig,
                                  searchTerm: ""
                                })
                              }}
                          /> 
                        </CInputGroupImg>
                      }
                      <CInputGroupImg
                        className={`border-color-gray`}
                      >
                        <img 
                            src={buscarIcon}
                            alt="eyes" 
                            width="17"
                            height="17"
                            className="cursor-pointer"
                        /> 
                      </CInputGroupImg>
                    </CInputGroup>
                    <div className="mx-sm-2 my-2 my-sm-0">{showValuesText ? showValuesText : "Mostrar"}</div>
                    <Select 
                      options={showValues}
                      nombre="showValues"
                      placeholder={selectText ? selectText : "Seleccionar"}
                      isSearchable={false} 
                      handleChange={(e)=>{
                        setDataConfig({
                          ...dataConfig,
                          itemsPerPage: e.target.value
                        })
                      }}
                      valor={dataConfig.itemsPerPage} 
                      error={false} 
                      styles={customStylesSelect2}
                      noOptionsMessage={`${noOptionsMessage ? noOptionsMessage : "Sin resultados"}`}
                    />
                  </CCol>
                </CRow>                  
              )} 
            </Content>
    )
}

const Content = styled.div`
.new-back {
  margin-left: auto;
  order: 2;
  color: #ACACAC;
  cursor: pointer;
}
.new-icon-small {
  margin-top: 0.3rem !important;
  margin-right: 0.2rem !important;
  width: 1rem !important;
  height: 1rem !important;
}
`;