import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CContainer,
  CForm
} from '@coreui/react'
import styled from 'styled-components'
import FormularioArte from './FormularioArte';
import ModalContent from '../../../hooks/ModalContent'
import ModalCropper from '../../../hooks/ModalCropper'
import { globalFunctions } from "../../../hooks/globalFunctions";
import { getTime, format, parseISO} from 'date-fns'
import api from '../../../api/api'
import ModalContentBidi from '../CRUDPiezas/ModalContentBidi'
// import ModalContentBuscarArte from './ModalContentBuscarArte';

export default function CRUDArtes({
  user,
  edit,
  //traducciones
  translation={},
  language="",
  idiomas=[],
}) {
    //Variables
    const navigate = useNavigate();
    const { 
      validationIsNumberInt
    } = globalFunctions();

    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null
    // Esta es la variable que almacenara los parametros que enviaremos al endpoint
    let idArte = locationData?.id_arte ? locationData?.id_arte : null

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    const showModal = useRef(); //referencia del hijo
    const [indiceIdiomaForm, setIndiceIdiomaForm] = useState(locationData?.indice_idioma ? locationData?.indice_idioma : language); //accion de aceptar del modal
    const [newEdit, setNewEdit] = useState(edit ? true : false); //esto nos dice si es una edicion o no
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [cancelAction, setCancelAction] = useState(null); //accion de aceptar del modal
    const [showTextDiv, setShowTextDiv] = useState(null); //accion de aceptar del modal
    const [changes, setChanges] = useState(false); //guarda si hubo cambios en el form
    const [inicio, setInicio] = useState(true); //indica si ya cargamos por primera vez el modal de buscar arte al abrir el form
    const [loadingData, setLoadingData] = useState(true) //variable que indica cuando esta cargando
    const [itemSeleccionado, setItemSeleccionado] = useState(null) //variable que indica cuando esta cargando
    // const [inputSearchArte, setInputSearchArte] = useState(""); //esta es la variable que almacena el input para buscar un arte ya existente
    // const [inputSearchArteError, setInputSearchArteError] = useState(false); //esta variable indica si hubo errores al momento de buscar un arte
    const [series, setSeries] = useState([]) //para almacenar las series

    //variable que almacena las piezas de un 
    const [piezasArte, setPiezasArte] = useState({
      total: 0,
      piezas: [],
    }) //variable que almacena las piezas del Arte seleccionado

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      id_arte: null,
      nombre: "",
      fecha_creacion: "",
      descripcion_arte: "",
      descripcion_corta: "",
      fk_serie: null,
      cantidad_piezas: "",
      especificacion_tecnica: "",
      conservacion: "",
      esquema_montaje: "",
      proceso_elaboracion: "",
      lugar_creacion:  "",
      numero_edicion: "",
      imagenes: [],
      artistas: [],
      artistas_borrar: [],
      indice_idioma_artes: [],
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      nombre: false,
      fecha_creacion: false,
      descripcion_arte: false,
      descripcion_corta: false,
      cantidad_piezas: false,
      especificacion_tecnica: false,
      conservacion: false,
      esquema_montaje: false,
      proceso_elaboracion: false,
      lugar_creacion: false,
      numero_edicion: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      nombre: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      fecha_creacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      descripcion_arte: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      descripcion_corta: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      cantidad_piezas: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      especificacion_tecnica: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      conservacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      esquema_montaje: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      proceso_elaboracion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      lugar_creacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      numero_edicion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });
    //esta variable se utiliza para agregar una nueva imagen
    const [newImage, setNewImage] = useState(null)
    //esta variable se utiliza para desplegar el modal del cropper
    const [showModalCropper, setShowModalCropper] = useState(false);
    //esta variable se utiliza para actualizar el componente de imagenes
    const [count, setCount] = useState(getTime(new Date()))

    //funciones

    //modal
    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          goBack()
          break;
        case 2:
          goCRUDPiezas(itemSeleccionado)
          break;
        case 3:
          deletePicture()
          break;
        case 4:
          deleteArtist()
          break;
        case 5:
          showModal?.current?.resetModal()
          changeIndiceIdiomaForm(itemSeleccionado)
          break;
        // case 5:
        //   openModalBuscarArte()
        //   break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //Con esto manejamos acciones propias de este CU al dar cancelar en los modals.
    const handleCancelAction = () => {
      switch (cancelAction) {
        case 1:
          goBack()
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    // con esto colocamos contenido propio en el modal
    const textdivContent = () => {
      let elements = null
      switch (showTextDiv) {
          case 1:
            elements = (
              <ModalContentBidi
                item={itemSeleccionado} 
                onClose={() => {
                  setItemSeleccionado(null)
                  showModal?.current?.resetModal()
                }}
                //traducciones
                translation={translation}
                language={language}               
              />
            )
            break;
          // case 2:
          //   elements = (
          //     <ModalContentBuscarArte
          //       title={inputSearchArte}
          //       setInput={setInputSearchArte}
          //       inicio={inicio}
          //       handleCancel={() => {
          //         setInputSearchArte("")
          //         setInicio(false)
          //         showModal?.current?.resetModal()
          //       }}  
          //       handleAccept={() => {
          //         getArteBuscador()
          //       }}           
          //       setInputSearchArteError={setInputSearchArteError}
          //       inputSearchArteError={inputSearchArteError}      
          //       //traducciones
          //       translation={translation}
          //       language={language}               
          //     />
          //   )
          //   break;
          default:
            break;
      }
      return elements
    };
    
    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value } = variable;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChanges(true)
    }

    //luego de ajustar la foto con el cropper, con esto agregamos la foto al arreglo que almacena todas las imagenes
    const onSetPhoto = (obj) => {
      const {url, variable, type} = obj
      let arr = [...formFields[variable]];
      arr.push({
        fk_arte: null,
        imagen: url,
        tipo: type,
        id_imagen: null,
        delete: false,
        index: arr.length > 0 ? arr[arr.length-1].index+1 : 0,
        boo_favorita: false,
        update: false,
      });      
      let fieldsObj = { ...formFields, [variable]: arr};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setNewImage(null)
      setCount(getTime(new Date()))
      setChanges(true)
    };

    //con esto agregamos una foto y llamamos al modal cropper para ajustar la imagen
    const handleChangeImage = (e, variable, type, inputFile) => {  
      inputFile.current.click();   
      if (e !== undefined) {   
        if (e.target.files !== undefined) {  
          const file = e.target.files[0];  
          var reader = new FileReader();
          let base64 = null; 
          if(file !== undefined) {  
            reader.readAsDataURL(file); 
            reader.onload = function(event) {   
              base64 = event.target.result;
              let newObj = {
                url: base64,
                variable: variable,
                type: type,
              }
              setNewImage(JSON.parse(JSON.stringify(newObj)))   
              if(base64) {
                setShowModalCropper(true); 
              }
            };  
          } 
        }
      }  
      setTimeout(() => {
        inputFile.current.value = "";
      }, 1500);
    }

    //con esto preguntamos si esta seguro de eliminar
    const handleDeletePicture = (index, variable) => {
      setItemSeleccionado({
        index: index,
        variable: variable,
      })
      setAcceptAction(3)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_eliminar_imagen ? translation[language]?.msg_eliminar_imagen : "¿Estás seguro de eliminar esta imagen?"}`, // mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
    }

    //con esto eliminamos una foto
    const deletePicture = () => {
      const {index, variable } = itemSeleccionado
      let f = formFields
      let obj = f[variable]
      let newIndex = obj.findIndex(o => o.index === index)
      if(newIndex !== -1){
        obj[newIndex].delete = true
        setFormFields(JSON.parse(JSON.stringify(f)));
        setCount(getTime(new Date()))
        setItemSeleccionado(null)
        setChanges(true)
        showModal?.current?.resetModal()
      }
    }

    //con esto preguntamos si esta seguro de eliminar un artista
    const handleDeleteArtist = (obj) => {
      setItemSeleccionado(obj)
      setAcceptAction(4)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_desvincular_artista ? translation[language]?.msg_desvincular_artista : "¿Está seguro de desvincular el artista de este Arte?"}`, // mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
    }

    //con esto eliminamos un artista
    const deleteArtist = () => {
      const {id_artista } = itemSeleccionado
      let f = formFields
      let obj = f.artistas
      let obj2 = f.artistas_borrar
      let newIndex = obj.findIndex(o => o.id_artista === id_artista)
      if(newIndex !== -1){
        obj2.push(obj[newIndex])
        obj.splice(newIndex, 1);
        setFormFields(JSON.parse(JSON.stringify(f)));
        setItemSeleccionado(null)
        setChanges(true)
        showModal?.current?.resetModal()
      }
    }

    //con esto eliminamos una foto
    const changePictureFav = (index, type, variable, allFalse) => {
      let f = formFields
      let obj = f[variable]
      let newIndex = obj.findIndex(o => o.index === index)
      if(newIndex !== -1){
        //todas las demas a false
        obj?.filter(img => img.tipo === type).forEach((element) => {
          if(element.boo_favorita){
            element.boo_favorita = false
            element.update = true
          } 
        });
        if(!allFalse){
          //la seleccionada a true
          obj[newIndex].boo_favorita = true
          obj[newIndex].update = true
        }
        setFormFields(JSON.parse(JSON.stringify(f)));
        setChanges(true)
      }
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    //funcion que valida los campos del formulario
    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(!formFields['nombre']) {
        errorsObj['nombre'] = true
        errorsObjText['nombre'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['cantidad_piezas']) {
        errorsObj['cantidad_piezas'] = true
        errorsObjText['cantidad_piezas'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['cantidad_piezas'])){
        errorsObj['cantidad_piezas'] = true
        errorsObjText['cantidad_piezas'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }else if(parseInt(formFields['cantidad_piezas']) < 1){
        errorsObj['cantidad_piezas'] = true
        errorsObjText['cantidad_piezas'] = translation[language]?.campo_numerico_mayor ? translation[language]?.campo_numerico_mayor : "Campo númerico mayor a 0"
        validState = false            
      }

      if(!formFields['especificacion_tecnica']) {
        errorsObj['especificacion_tecnica'] = true
        errorsObjText['especificacion_tecnica'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['conservacion']) {
        errorsObj['conservacion'] = true
        errorsObjText['conservacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['fecha_creacion']) {
        errorsObj['fecha_creacion'] = true
        errorsObjText['fecha_creacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['lugar_creacion']) {
        errorsObj['lugar_creacion'] = true
        errorsObjText['lugar_creacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['numero_edicion']) {
        errorsObj['numero_edicion'] = true
        errorsObjText['numero_edicion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }      

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //Para tomar accion luego de darle clic a ver BIDI
    const handleClickShowBidi = (item) => {
      setItemSeleccionado(item)
      setShowTextDiv(1)
      showModal?.current?.content?.ownContent("md")
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      if (!validateFields()) {
        showModal?.current?.content?.info(
          null, //accion que tomara 
          `${translation[language]?.msg_campos_obligatorios ? translation[language]?.msg_campos_obligatorios : "Por favor, complete los campos obligatorios"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return       
      }
      //si no hay errores
      putArte()
    }

    //Con esta función preguntamos al usuario si esta seguro de cancelar los cambios.
    const handleBack = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        goBack()
      }
    }

    //Con esta funcion enviamos de regreso al usuario al dar clic en back
    const goBack = () => {
      navigate('/admin-artes', { state: { dataCache: locationData?.oldDataCache ? locationData.oldDataCache : null}})
    }

    //con esto vamos a la vista que administra las piezas
    const goCRUDPiezas = (id=null) => {
      let dataCache = {
        id_arte: id ? id : formFields.id_arte,
        getData: true,
        indice_idioma: parseInt(indiceIdiomaForm),
        nombre_arte: formFields.nombre,
        total_piezas: piezasArte.total,
        oldDataCache: locationData?.oldDataCache ? locationData?.oldDataCache : null
      }
      navigate('/admin-administrar-piezas-arte', { state: { dataCache: dataCache}})
    } 
    
    //con esto validamos para no ir a al crud de piezas si hay cambios pendientes
    const handleGoCRUDPiezas = () => {
      if(changes){
        setItemSeleccionado(null)
        setAcceptAction(2)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        goCRUDPiezas()
      }
    }

    //con esto vamos a la vista que administra los artistas para su seleccion
    const goCRUDArtistas = () => {
      let dataCache = {
        selection: true,
        action: 1, // action 1 significa que va desde el CRUD de artes al CRUD Artistas
        from: newEdit ? '/admin-editar-arte' : '/admin-nuevo-arte',
        oldDataCache: {
          changes: changes,
          formFields: formFields,
          formErrors: formErrors,
          formErrorsText: formErrorsText,
          piezasArte: piezasArte,
          indice_idioma: indiceIdiomaForm,
          edit: newEdit,
          series: series,
          oldDataCache: locationData?.oldDataCache ? locationData?.oldDataCache : null
        },
      }
      navigate('/admin-artistas', { state: { dataCache: dataCache}})
    }    

    //con esta funcion 
    const putArte = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
      let imagenes_to_add = formFields.imagenes.filter(data => (!data.id_imagen && !data.delete)).map((data) => {
        return {
          fk_arte: formFields.id_arte ? formFields.id_arte : null,
          imagen: data.imagen,
          tipo: data.tipo,
          boo_favorita: data.boo_favorita,
        }
      });

      let newObj = {
        artes: [{
          id_arte: formFields.id_arte ? formFields.id_arte : null,
          nombre: formFields.nombre ? formFields.nombre : "NA",
          fk_serie: formFields.fk_serie ? formFields.fk_serie : null,
          especificacion_tecnica: formFields.especificacion_tecnica ? formFields.especificacion_tecnica : null,
          conservacion: formFields.conservacion ? formFields.conservacion : null,
          proceso_elaboracion: formFields.proceso_elaboracion ? formFields.proceso_elaboracion : null,
          fecha_creacion: format(parseISO(formFields.fecha_creacion), "yyyy-MM-dd"),
          cantidad_piezas: formFields.cantidad_piezas ? parseInt(formFields.cantidad_piezas) : null,
          esquema_montaje: formFields.esquema_montaje ? formFields.esquema_montaje : null,
          descripcion_arte: formFields.descripcion_arte ? formFields.descripcion_arte : "",
          descripcion_corta: formFields.descripcion_corta? formFields.descripcion_corta : "",
          lugar_creacion:  formFields.lugar_creacion ? formFields.lugar_creacion : "",
          numero_edicion: formFields.numero_edicion ? formFields.numero_edicion : "",
          indice_idioma: parseInt(indiceIdiomaForm),
          imagenes: imagenes_to_add.length > 0 ? imagenes_to_add : null,
          imagenes_para_borrar: formFields.imagenes.filter(data => data.delete).map((data) => {
            return {
              id_imagen: data.id_imagen,
            }
          }),
          imaganes_para_actualizar: formFields.imagenes.filter(data => (data.id_imagen && data.update)).map((data) => {
            return {
              id_imagen: data.id_imagen,
              boo_favorita: data.boo_favorita,
            }
          }),
          //artistas
          añadir_artistas: formFields.artistas.filter(data => (!data.id_artista_creador_arte)).map((data) => {
            return {
              fk_artista: data.id_artista,
            }
          }),
          borrar_artistas: formFields.artistas_borrar.filter(data => (data.id_artista_creador_arte)).map((data) => {
            return {
              id_artista_creador_arte: data.id_artista_creador_arte,
            }
          }),
        }]        
      }
      api.put("/put_arte", newObj, config).then(response => {
        setItemSeleccionado(response.data[0].id_arte)
        setCancelAction(1)
        setAcceptAction(newEdit ? 1 : 2)
        if(newEdit){
          showModal?.current?.content?.success(
            3, //accion que tomara 
            `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, // mensaje principal
            null, //mensaje secundario
            false, //indica si hay o no boton de cancelar
            `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
          );
        }else{
          showModal?.current?.content?.success(
            3, //accion que tomara el boton aceptar
            `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, //mensaje principal
            `${translation[language]?.msg_agregar_piezas ? translation[language]?.msg_agregar_piezas : "¿Desea agregar piezas a este Arte?"}`, //mensaje secundario
            true, //indica si hay o no boton de cancelar
            `${translation[language]?.boton_si ? translation[language]?.boton_si : "Si"}`, //texto de boton de aceptar
            `${translation[language]?.boton_no ? translation[language]?.boton_no : "No"}`, //texto de boton de cancelar
            4, // accion que tomara el boton cancelar
          );
        }     
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 409:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_arte_ya_registrado ? translation[language]?.msg_arte_ya_registrado : "El nombre del Arte ya se encuentra registrado. Por favor, ingresa uno diferente."}`, // mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //funciones que se utilizan para editar un arte

    //Funcion para obtener la data de los endpoints
    const getDataEndpoint = async (url, params) => {
      let result;
      //llamamos al endpoint
      return api.get(`${url}`, params).then(response => {
        result = {error: false, data: response.data, code: 200}  
        return result
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            result = {error: true, data: [], code: err?.response?.status}
            break;
          case 404:
            result = {error: false, data: [], code: err?.response?.status}
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              2, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            result = {error: true, data: [], code: err?.response?.status}
            break;
        }
        return result
      })
    }

    //obtenemos la data del sistema
    const getData = async () => {
      //Con esta funcion obtenemos un arte por el id
      let arte = await getDataEndpoint(`/get_artes`, {...config, params: {id_arte: idArte, indice_idioma: parseInt(indiceIdiomaForm), filtrar_idioma: true}})
      //Con esta funcion obtenemos las piezas de un arte por el id_arte = fk_arte
      let piezas_artes = !newEdit ? {error: false, data: [], code: 404} : await getDataEndpoint(`/get_piezas`, {...config, params: {fk_arte: idArte, page_size: 5, page: 1}})
      //Con esta funcion obtenemos las series
      let series = await getDataEndpoint(`/get_series`, {...config, params: {boo_pagination: false}})
      
      //Si no hay error en alguno de los endpoints guardamos las variables
      if(!arte.error && !piezas_artes.error && !series.error){
        let booExiste = arte.data[0][0].existe
        let form = returnNewObjForm(arte.data[0][0], !booExiste)
        let piezas = {
          total: piezas_artes?.data[1]?.total ? piezas_artes?.data[1]?.total : 0,
          piezas: piezas_artes?.data[0] ? piezas_artes?.data[0] : [],
        }
        let newSeries = returnNewObjSeries(series.data[0])
        setSeries(newSeries)
        setFormFields(JSON.parse(JSON.stringify(form)));
        setPiezasArte(piezas)
        setLoadingData(false)
        setInicio(false)
      }   
    }

    // //obtenemos la data del sistema
    // const getArteBuscador = async () => {
    //   //cargando
    //   showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
    //   //los parametros de busqueda
    //   let params = {
    //     nombre: inputSearchArte,
    //     coincidencia_nombre: false,
    //     indice_idioma: parseInt(language)
    //   }
    //   //Con esta funcion obtenemos un arte por los parametros anteriores
    //   let arte = await getDataEndpoint(`/get_artes`, {...config, params: params})
    //   let code = arte.code
    //   //aqui tomamos la accion a realizar dependiendo del codigo
    //   switch (code) {
    //     //esto significa que el arte EXISTE en x idioma
    //     case 200:
    //       let booExiste = arte.data[0][0].existe
    //       let newArte = returnNewObjForm(arte.data[0][0], !booExiste)
    //       //Con esta funcion obtenemos las piezas de un arte por el id_arte = fk_arte
    //       let piezas_artes = await getDataEndpoint(`/get_piezas`, {...config, params: {fk_arte: newArte.id_arte, page_size: 5, page: 1}})      
    //       let piezas = {
    //         total: piezas_artes?.data[1]?.total ? piezas_artes?.data[1]?.total : 0,
    //         piezas: piezas_artes?.data[0] ? piezas_artes?.data[0] : [],
    //       }
    //       closeSearchArte(newArte, piezas, booExiste)
    //       break;
    //     //esto significa que el arte no introducido no fue encontrado en ningun idioma
    //     case 404:
    //       setInputSearchArteError(true)
    //       openModalBuscarArte()
    //       break;
    //     default:
    //       break;
    //   }
    // }

    // //con esto vamos a cerrar el modal
    // const closeSearchArte = (arte, piezas, booExiste) => {
    //   setedit(JSON.parse(JSON.stringify(booExiste)))
    //   setFormFields(JSON.parse(JSON.stringify(arte)));
    //   setPiezasArte(JSON.parse(JSON.stringify(piezas)));
    //   setInputSearchArte("")
    //   setInicio(false)
    //   if(booExiste){
    //     navigate(`/admin-editar-arte`, { replace: true })
    //   }
    //   showModal?.current?.resetModal()
    // }

    const returnNewObjForm = (f, vacio=false) => {
      //si la variable vacio es true, significa que el arte existe y que se va a registrar el arte. Eliminamos las variables
      let newObj = {
        id_arte: f.id_arte ? f.id_arte : null,
        nombre: (f.nombre && !vacio) ? f.nombre : "",
        fecha_creacion: f.fecha_creacion ? f.fecha_creacion : "",
        descripcion_arte: (f.descripcion_arte && !vacio) ? f.descripcion_arte : "",
        descripcion_corta: (f.descripcion_corta && !vacio) ? f.descripcion_corta : "",
        fk_serie: f.fk_serie ? f.fk_serie : null,
        cantidad_piezas: f.cantidad_piezas ? f.cantidad_piezas : "",
        especificacion_tecnica: (f.especificacion_tecnica && !vacio) ? f.especificacion_tecnica : "",
        conservacion: (f.conservacion && !vacio) ? f.conservacion : "",
        esquema_montaje: (f.esquema_montaje && !vacio) ? f.esquema_montaje : "",
        proceso_elaboracion: (f.proceso_elaboracion && !vacio) ? f.proceso_elaboracion : "",
        lugar_creacion:  (f.lugar_creacion && !vacio) ? f.lugar_creacion : "",
        numero_edicion: (f.numero_edicion && !vacio) ? f.numero_edicion : "",
        artistas: f.artistas ? f.artistas : [],
        artistas_borrar: [],
        indice_idioma_artes: f.indice_idioma_artes ? f.indice_idioma_artes : [],
        imagenes: f.imagenes.map((d, i) => {
          return {
            ...d, 
            delete: false,
            update: false,
          }
        }),
      }  
      return newObj
    }

    //con esto ordenamos la data del obj series
    const returnNewObjSeries = (r) => {
      let newData = r.map((data) => {
        return {
            value: data.id_serie,
            label: data.nombre ? data.nombre : "",
            descripcion: data.descripcion ? data.descripcion : "",
        }
      });

      let sinValor = [
        {
          value: null,
          label: `NA`,
          descripcion: "",
        }
      ]
      const array3 = sinValor.concat(newData);
      return array3
    }

    //con esto cargamos la data al ir a CU3 y volver a CU2
    const loadData = () => {
      const {artistas_seleccionados, formFields} = locationData
      //este es el arreglo de artistas viejo que tenia el arte antes de ir a seleccionar y volver
      let artistas_old = formFields?.artistas.concat(formFields?.artistas_borrar);

      //este sera el arreglo de nuevos artistas (despues de la seleccion)
      let artistas_new = !artistas_seleccionados ? formFields?.artistas : artistas_seleccionados.map((d) => {
        return {
          ...d, 
          id_artista_creador_arte: artistas_old?.find( item => item.id_artista === d.id_artista) ? artistas_old.find( item => item.id_artista === d.id_artista).id_artista_creador_arte : null
        }
      })

      //ordenamos la data
      artistas_new.sort(function(a, b) {
          var textA = a.nombre_apellido.toUpperCase();
          var textB = b.nombre_apellido.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      //este es el arreglo con los artistas a borrar, los que no estan en artistas_new
      let artistas_borrar = artistas_old.filter(o1 => !artistas_new.some(o2 => o1.id_artista === o2.id_artista))

      //este es el nuevo form donde colocamos los arreglos
      let newObj = {
        ...locationData.formFields,
        artistas: artistas_new,
        artistas_borrar: artistas_borrar
      }

      //Seteamos las variables de estado
      setChanges(locationData.changes)
      setFormFields(newObj)
      setFormErrorsText(formErrorsText)
      setFormErrors(locationData.formErrors)
      setSeries(locationData.series)
      setPiezasArte(locationData.piezasArte)
      setLoadingData(false)
      setIndiceIdiomaForm(locationData.indice_idioma)
      setInicio(false)
      setNewEdit(locationData.edit)
    }

    //con esto vamos a cambiar el idioma del arte del formulario
    const changeIndiceIdiomaForm = async (newIndice) => {
      if(!idArte){
        setIndiceIdiomaForm(parseInt(newIndice))
        setItemSeleccionado(null)
        return;
      }
      setLoadingData(true)
      //Con esta funcion obtenemos un arte por el id
      let arte = await getDataEndpoint(`/get_artes`, {...config, params: {id_arte: idArte, indice_idioma: parseInt(newIndice), filtrar_idioma: true}})
      //Si no hay error en alguno de los endpoints guardamos las variables
      if(!arte.error){
        let booExiste = arte.data[0][0].existe
        //Con esta funcion obtenemos las piezas de un arte por el id_arte = fk_arte
        let piezas_artes = !booExiste ? {data: []} : await getDataEndpoint(`/get_piezas`, {...config, params: {fk_arte: idArte, page_size: 5, page: 1}})
        let form = returnNewObjForm(arte.data[0][0], !booExiste)
        let piezas = {
          total: piezas_artes?.data[1]?.total ? piezas_artes?.data[1]?.total : 0,
          piezas: piezas_artes?.data[0] ? piezas_artes?.data[0] : [],
        }
        let ruta = booExiste ? '/admin-editar-arte' : '/admin-nuevo-arte'
        setFormFields(JSON.parse(JSON.stringify(form)));
        setPiezasArte(piezas)
        setItemSeleccionado(null)
        setInicio(false)
        setLoadingData(false)
        setIndiceIdiomaForm(parseInt(newIndice))
        setNewEdit(booExiste ? true : false)
        setChanges(false)
        navigate(ruta, { replace: true, state: { dataCache: locationData ? locationData : null}})
      } 
    }

    //con esto atajamos cuando le damos clic a cambiar de idioma
    const handleChangeIndiceIdiomaForm = (newIndice) =>{
      if(changes){
        setItemSeleccionado(newIndice)
        setAcceptAction(5)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        changeIndiceIdiomaForm(newIndice)
      }
    }

    // //con esto abrimos el modal para buscar un arte
    // const openModalBuscarArte = () => {
    //   setShowTextDiv(2)
    //   showModal?.current?.content?.ownContent("md")
    // }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      if(newEdit && !idArte && !locationData?.loadData){
        showModal?.current?.content?.info(
          5, //accion que tomara 
          `${translation[language]?.msg_seleccione_arte ? translation[language]?.msg_seleccione_arte : "Seleccione un Arte para editar"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      //si getData es true, significa que el arte ya existe, llamamos a los gets
      if(locationData?.getData && !locationData?.loadData){
        getData()
      //si load data es true, significa que esta seleccionando artistas
      }else if(locationData?.loadData){
        //cargamos la data que ya tenia con la seleccion de nuevos artistas
        loadData()
      }else{
        const fetchDataSeries = async () => {
          //Con esta funcion obtenemos las series
          let series = await getDataEndpoint(`/get_series`, {...config, params: {boo_pagination: false}})
          let newSeries = returnNewObjSeries(series.data[0])
          setSeries(newSeries)
          setLoadingData(false)
          setInicio(false)
          // openModalBuscarArte()
        }
        fetchDataSeries()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 

    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                cancelButtonAction={handleCancelAction}
                modalContentInside={textdivContent}
              />
              <ModalCropper 
                configImg={newImage} 
                aspectRatio={16/9}  
                onSetPhoto={onSetPhoto} 
                show={showModalCropper} 
                setShow={setShowModalCropper}
                //traducciones
                translation={translation}
                language={language}  
              />
              <Screen>
                <CContainer className='px-4'> 
                  <CForm 
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <FormularioArte
                      formFields={formFields}
                      formErrors={formErrors}
                      formErrorsText={formErrorsText}
                      handleChange={handleChange}
                      handleChangeImage={handleChangeImage}
                      count={count}
                      deletePicture={handleDeletePicture}
                      handleBack={handleBack}
                      goCRUDPiezas={handleGoCRUDPiezas}
                      goCRUDArtistas={goCRUDArtistas}
                      piezasArte={piezasArte}
                      loadingData={loadingData}
                      edit={newEdit}
                      handleClickShowBidi={handleClickShowBidi}
                      changePictureFav={changePictureFav}
                      handleDeleteArtist={handleDeleteArtist}
                      series={series}
                      inicio={inicio}
                      indiceIdiomaForm={indiceIdiomaForm}
                      handleChangeIndiceIdiomaForm={handleChangeIndiceIdiomaForm}
                      // openModalBuscarArte={openModalBuscarArte}
                      //traducciones
                      translation={translation}
                      language={language}   
                      idiomas={idiomas}
                    />
                  </CForm>                              
                </CContainer>
              </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`