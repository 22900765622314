import React from "react";
import {
  CRow,
  CCol,
  CBadge,
  CTooltip
} from '@coreui/react'
import styled from 'styled-components'
import pencilIcon from '../../../icons/pencil.svg'
import verArtesIcon from '../../../icons/Icon ionic-ver artes.svg'


export default function ClientListRows({
  item,
  index,
  total,
  goToEditarPropietario,
  handleGoToTransferirArte,
  goToVerArtesPropietario,
  //traducciones
  translation={},
  language="", 
}) {



    return (
            <Content>
              <div className={`bg-color d-lg-none`}> 
                  <CRow className="p-2 pl-3">               
                      <CCol className="text-white" xs="12">
                        {item.nombre ? item.nombre : ''}
                          
                      </CCol>
                  </CRow>
              </div> 
              <CRow className="py-2 px-2 px-sm-3">
                <CCol lg={3} className="d-none d-lg-block">
                  <div>{item?.nombre ? item?.nombre : ''} {item?.apellidos ? item?.apellidos : ''}</div>
                  {!item.boo_estatus ? (
                  <CTooltip content={`${!item.boo_estatus ? `${translation[language]?.etiqueta_pendiente_registro ? translation[language]?.etiqueta_pendiente_registro : "Nuevo Propietario pendiente por registrarse"}` : ''}`}>
                    <CBadge className={`cursor-pointer rounded-0 ${item.boo_activo ? 'badge-color' : 'badge-border'} ${!item.boo_estatus ? '' : 'w-badge'}`}>{!item.boo_estatus ? `${translation[language]?.etiqueta_transicion ? translation[language]?.etiqueta_transicion : "En transición"}` : (item.boo_activo ? `${translation[language]?.activo ? translation[language]?.activo : 'Activo'}` : `${translation[language]?.inactivo ? translation[language]?.inactivo : 'Inactivo'}`)}</CBadge>
                  </CTooltip>
                  ) : (
                    <CBadge className={`rounded-0 ${item.boo_activo ? 'badge-color' : 'badge-border'} ${!item.boo_estatus ? '' : 'w-badge'}`}>{!item.boo_estatus ? `${translation[language]?.etiqueta_transicion ? translation[language]?.etiqueta_transicion : "En transición"}` : (item.boo_activo ? `${translation[language]?.activo ? translation[language]?.activo : 'Activo'}` : `${translation[language]?.inactivo ? translation[language]?.inactivo : 'Inactivo'}`)}</CBadge>
                  )}
                </CCol>
                <CCol lg={4}>
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.correo ? translation[language]?.correo : "Correo"}`}: </span>
                  {item.email ? item.email : ''}
                </CCol>
                <CCol lg={2}>
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.telefono ? translation[language]?.telefono : "Teléfono"}`}: </span>
                  {item.telefono ? item.telefono.replace("-", "") : ''}
                </CCol>
                <CCol lg={2}>
                  <span  className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.celular ? translation[language]?.celular : "Celular"}`}: </span>
                  {item.celular ? item.celular.replace("-", "") : ''}
                </CCol>
                <CCol lg={1} className="d-lg-flex col-lg-1 justify-content-lg-end">
                  <div className="me-0 mb-2 mb-lg-0 me-lg-2">
                    <span  className={`d-inline-flex d-lg-none me-1`}>{translation[language]?.hint_ver_artes ? translation[language]?.hint_ver_artes : "Ver Artes"}: </span>
                    <CTooltip content={`${translation[language]?.hint_ver_artes ? translation[language]?.hint_ver_artes : "Ver Artes"}`}>
                      <img 
                        className='icon-border' 
                        src={verArtesIcon}  
                        alt="icon"
                        width="25px"
                        height="25px"
                        onClick={()=> goToVerArtesPropietario(item)}
                      />
                    </CTooltip>
                  </div>
                  <div>
                    <span  className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}: </span>
                    <CTooltip content={`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}>
                      <img 
                        className='icon-border' 
                        src={pencilIcon}  
                        alt="icon"
                        width="25px"
                        height="25px"
                        onClick={()=> !item.boo_estatus ? handleGoToTransferirArte(item) : goToEditarPropietario(item)}
                      />
                    </CTooltip>
                  </div>
                </CCol>
              </CRow>
              {index+1 !== total && (
                  <hr className="mt-0 mb-0 primary-color"/>
              )}     
            </Content>
    )
}
const Content = styled.div`
`
