import React from "react";
import {
  CCardBody,
  CContainer,
  // CInputGroup,
  CRow,
  CCol,
  CCardHeader,
  CSpinner,
  CFormLabel,
  CForm,
  CFormFeedback,
  // CTooltip
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  Card2,
  CInput,
  // InputGroupText,
  Button,
  ButtonVariant
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import Pagination from "../../../hooks/Paginacion";
import PiezaListRows from './PiezaListRows';
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'
// import refreshIcon from "../../../icons/Icon feather-refresh-ccw.svg";

export default function CardContent({
  data,
  dataConfig,
  getData,
  loadingData,
  setDataConfig,
  handleChangeFiltros,
  handleBack,
  returnLeftColumnContent,
  handleGoToTransferirArte,
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  handleSubmit,
  // generarCodigo,
  changes,
  locationData,
  handleDeletePieza,
  handleClickShowBidi,
  setForm,
  discardChanges,
  //traducciones
  translation={},
  language="",
}) {

    // //con esto retornamos las filas
    const RenderData = (registro) => {
      return (
        <>
          {registro.map((item, i) => {
            return (
              <PiezaListRows
                key={i}
                item={item}
                total={registro.length}
                index={i}
                handleDeletePieza={handleDeletePieza}
                handleClickShowBidi={handleClickShowBidi}
                handleGoToTransferirArte={handleGoToTransferirArte}
                handleEditPieza={setForm}
                //traducciones
                translation={translation}
                language={language}
              />
            );
          })}
        </>
      );
    };

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-2 px-sm-4">
                    <Header
                      title={`${translation[language]?.administrar_piezas ? translation[language]?.administrar_piezas : "Administrar piezas"}: ${locationData?.nombre_arte ? locationData?.nombre_arte : ''} `}
                      isBack={true}
                      handleBack={handleBack}
                      isSearchAndFilter={true}
                      isFilter={false}
                      dataConfig={dataConfig}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      placeholderSearch={`${translation[language]?.buscar_codigo_pieza ? translation[language]?.buscar_codigo_pieza : "Buscar por Código de pieza"}`}
                      showValuesText={`${translation[language]?.mostrar ? translation[language]?.mostrar : "Mostrar"}`}
                      filterByText={`${translation[language]?.filtrar_por ? translation[language]?.filtrar_por : "Filtrar por"}`}
                      selectText={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                      noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                      contentp={returnLeftColumnContent()}
                    /> 
                    <Card2 className="border-0 pb-4">
                      <CCardHeader className="border border-bottom-0 border-start-0 border-end-0 border-color bg-custom rounded-0 font-size-12 d-none d-xl-block">
                        <CRow className="py-2">
                          <CCol xl={2} className="px-1">{`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}</CCol>
                          <CCol xl={2} className="px-1">{`${translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : "Código de invitación"}`}</CCol>
                          <CCol xl={2} className="px-1">{`${translation[language]?.url ? translation[language]?.url : "URL"}`}</CCol>
                          <CCol xl={1} className="px-0">{`${translation[language]?.numero_pieza ? translation[language]?.numero_pieza : "Número de pieza"}`}</CCol>
                          <CCol xl={1} className="px-1">{`${translation[language]?.bidi ? translation[language]?.bidi : "BIDI"}`}</CCol>
                          <CCol xl={2} className="px-1">{`${translation[language]?.propietario ? translation[language]?.propietario : "Propietario"}`}</CCol>
                          <CCol xl={2} className="px-0 d-flex">                        
                          </CCol>
                        </CRow>
                      </CCardHeader>
                      <hr className="mt-0 mb-0 opacity-unset d-block d-xl-none" />
                      <CCardBody className="font-size-12 px-2 px-xl-3">
                        <CForm 
                          onSubmit={handleSubmit}
                          noValidate
                        >
                          <CRow className="px-1 px-xl-0">
                            <CCol xs={6} xl={2} className="mb-3 mb-xl-0 pe-1 ps-0 px-xl-1">
                              <CFormLabel 
                                className="d-block d-xl-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}
                              </CFormLabel>                            
                              <CInput
                                size="sm"
                                type="text"
                                name="codigo_pieza"
                                value={formFields?.codigo_pieza}
                                invalid={formErrors?.codigo_pieza}
                                onInput={(e) => e.target.value = e.target.value.replace(/ /g, "")}
                                onChange={handleChange}
                                className="font-size-12"
                              />
                              <CFormFeedback invalid>{formErrorsText?.codigo_pieza}</CFormFeedback> 
                            </CCol>
                            <CCol xs={6} xl={2} className="mb-3 mb-xl-0 pe-0 ps-1 px-xl-1">
                              <CFormLabel 
                                className="d-block d-xl-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : "Código de invitación"}`}
                              </CFormLabel>
                                <CInput
                                  type="text"
                                  size="sm"
                                  name="codigo_invitacion"
                                  className={`icon-padding-error font-size-12 ${formErrors?.codigo_invitacion ? 'background-image-none' : ''}`}
                                  value={formFields?.codigo_invitacion}
                                  invalid={formErrors?.codigo_invitacion}
                                  onChange={handleChange}
                                  onInput={(e) => e.target.value = e.target.value.replace(/ /g, "")}
                                />                               
                              <CFormFeedback invalid>{formErrorsText?.codigo_invitacion}</CFormFeedback> 
                            </CCol>
                            <CCol xs={6} xl={2} className="mb-3 mb-xl-0 pe-1 ps-0 px-xl-1">
                              <CFormLabel 
                                className="d-block d-xl-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.url ? translation[language]?.url : "URL"}`}
                              </CFormLabel> 
                              <CInput
                                size="sm"
                                type="text"
                                name="url"
                                value={formFields.url}
                                invalid={formErrors.url}
                                disabled={formFields.edit ? false : true}
                                onChange={handleChange}
                                className="font-size-12"
                              />
                              <CFormFeedback invalid>{formErrorsText?.url}</CFormFeedback> 
                            </CCol>
                            <CCol xs={6} xl={1} className="mb-3 mb-xl-0 pe-0 ps-1 px-xl-1">
                              <CFormLabel 
                                className="d-block d-xl-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.numero_pieza ? translation[language]?.numero_pieza : "Número de pieza"}`}
                              </CFormLabel> 
                              <CInput
                                size="sm"
                                type="text"
                                name="numero_pieza"
                                value={formFields.numero_pieza}
                                invalid={formErrors.numero_pieza}
                                onChange={handleChange}
                                className="font-size-12"
                              />
                              <CFormFeedback invalid>{formErrorsText?.numero_pieza}</CFormFeedback> 
                            </CCol>
                            <CCol xl="3">
                            </CCol>
                            <CCol xs={6} xl={1} className="pe-1 ps-0">
                              {(formFields.edit || changes) && (
                                <ButtonVariant 
                                  type="button"
                                  className="font-size-11 w-100 padding-button" 
                                  onClick={()=> discardChanges()}
                                >
                                  {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                                </ButtonVariant>   
                              )}  
                            </CCol>
                            {/* {(formFields.edit || changes) && (
                              <div className="col-1-5ths-50 pe-1 ps-0">
                                <ButtonVariant 
                                  type="button"
                                  className="font-size-11 w-100 padding-button" 
                                  onClick={()=> discardChanges()}
                                >
                                  {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                                </ButtonVariant>     
                              </div>                            
                            )} */}
                            <CCol xs={6} xl={1} className="ps-1 pe-0">
                              <Button 
                                type="submit"
                                className="font-size-11 w-100 padding-button" 
                              >
                                {(formFields.edit || changes) ? `${translation[language]?.boton_guardar ? translation[language]?.boton_guardar : "Guardar"}` : `${translation[language]?.boton_agregar ? translation[language]?.boton_agregar : "Agregar"}`}
                              </Button>     
                            </CCol>
                          </CRow>
                        </CForm>
                      </CCardBody>
                      <CCardBody className="py-0 px-0 border border-color font-size-12">
                        {data.length > 0 && !loadingData ?  
                        (
                          <>{RenderData(data)}</>
                        ) 
                        : 
                        (
                            <CContainer className="text-start ps-2">  
                              <CRow>               
                                <CCol xs={12} className={`d-flex align-items-center mb-3 mt-3 ${loadingData && "justify-content-center"}`}>
                                  {loadingData ? 
                                    (
                                      <div className="text-center">
                                        <CSpinner size="sm"/>
                                          <CSpinner size="sm" variant="grow"/>
                                          <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                                      </div>
                                    )
                                  :
                                    (
                                      <>
                                        <div className="me-2">
                                          <img
                                            src={infoIcon}
                                            alt=""
                                            width="18"
                                          />
                                        </div>
                                        <span 
                                            className={`font-size-12`}
                                        >
                                          {dataConfig?.searchTerm === "" ? `${translation[language]?.msg_error_agregar_piezas ? translation[language]?.msg_error_agregar_piezas : 'Por favor, ingresa los datos para agregar la pieza de Arte.'}` : `${translation[language]?.msg_error_resultados ? translation[language]?.msg_error_resultados : 'No se encontraron resultados para la búsqueda.'}`}
                                        </span>     
                                      </>
                                    )
                                  }                              
                                </CCol>
                              </CRow>                              
                            </CContainer>
                        )
                        }
                      </CCardBody>
                    </Card2>
                    {(data.length > 0 && !loadingData) && (
                      <Pagination
                        getData={getData} // funcion para actualizar la data
                        dataConfig={dataConfig} // data que almacena toda la configuracion de busqueda, paginacion y filtrado
                        setDataConfig={setDataConfig}
                        ifGetData={true} // indica si obtenemos la data con endpoint
                        //traducciones
                        translation={translation}
                        language={language}
                      />
                    )}
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
.padding-button {
  padding: 0.3rem 0.3rem;
}
.form-control:disabled {
  background-color: #F7F5EF;
  border-color: var(--primary-color);
  opacity: 1;
}
`
