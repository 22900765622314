import React, {useState, useEffect, useRef} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CSpinner,
} from '@coreui/react'
import styled from 'styled-components'
import {LoadingScreen} from '../../hooks/styled'
import Login from "./Login";
import ModalContent from '../../hooks/ModalContent'
import api from '../../api/api'
import { globalFunctions } from "../../hooks/globalFunctions";
import { sendEmail } from '../../hooks/sendEmail';
import SysmodelersIcon from '../../icons/Logo-Original.svg'

export default function LoginIndex({
  setUser, 
  setTipoActor, 
  user, 
  admin,
  tipoActor,
  variablesEnv,
  //traducciones
  translation={},
  language="",
}) {
    const { goToInicio } = globalFunctions();
    //Variables
    const navigate = useNavigate();
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null

    const showModal = useRef(); //referencia del hijo
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("Loading...");

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      email: locationData?.email ? locationData?.email  : "",
      password: locationData?.pin ? locationData?.pin  : "",
      remember_me: false
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      email: false,
      password: false,
    });

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      const { name, value, checked } = e.target;
      let newValue = (name === "remember_me") ? checked : value
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: newValue };
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    // con esta funcion validamos el correo y la password
    const validateFields = () => {
      let errorsObj = formErrors
      let validState = true

      if(!formFields['email']) {
        errorsObj['email'] = true
        validState = false
      }

      if(!formFields['password']) {
        errorsObj['password'] = true
        validState = false
      }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      return validState
    }

    //para atajar el evento al dar clic en recuperar
    const handleForgot = () => {
      if (!formFields.email) {
        showModal?.current?.content?.info(
          null, //accion que tomara 
          `${translation[language]?.msg_ingresar_correo ? translation[language]?.msg_ingresar_correo : "Por favor, ingresa tu correo electrónico para recibir el mensaje de recuperación de contraseña."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      postEmail()
    }

    //para enviar el correo
    const postEmail = async () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...');
      const email = [formFields.email];
      const logo  = variablesEnv?.REACT_APP_LOGO_URL
      const subject = `${translation[language]?.titulo_recuperar_contrasena ? translation[language]?.titulo_recuperar_contrasena : 'Recuperación de contraseña'}`;
      const nombre = formFields.email?.split('@')[0] ? formFields.email?.split('@')[0] : formFields.email;
      const saludo = `${translation[language]?.msg_correo_saludo ? translation[language]?.msg_correo_saludo : 'Hola,'} ${nombre}`
      const origin = "login";
      let mensaje = `
        <div style="white-space: normal;color:#B2A169;font-size:16px;padding: 5px 1.5rem 0px 1.5rem;">
          <p style="color:#B2A169; margin-bottom: 0px!important">${translation[language]?.msg_correo_recuperar_1 ? translation[language]?.msg_correo_recuperar_1 : 'Recibimos la solicitud para restablecer tu contraseña. Haz clic en el siguiente botón y sigue las instrucciones de la aplicación.'}</p>
          <a href="{}" target="_blank" rel="noopener noreferrer" style="
          display: block;
          max-width: 150px;
          margin: 20px 0;
          padding: 5px 0px;
          text-align: center;
          background-color: #B2A169;
          color: #ffffff;
          text-decoration: none;
          font-size:16px;
          ">
              ${translation[language]?.boton_cambio_clave ? translation[language]?.boton_cambio_clave : 'Cambio de clave'}
          </a>       
        </div>
      `
      //Función que envía el correo
      let result = await sendEmail(email, subject, saludo, mensaje, origin, null, logo)
      if(result.response){
        showModal?.current?.content?.info(
          null, //accion que tomara 
          `${translation[language]?.msg_envio_enlace ? translation[language]?.msg_envio_enlace : "Se envió a tu correo electrónico un enlace de recuperación."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );     
      }else{
        switch (result.status) {
          case 404:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_enlace ? translation[language]?.msg_error_enlace : "Correo electrónico no encontrado, no es posible enviar un enlace de recuperación."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      }
    }

    //con esto redireccionamos al usuario
    const goToHome = (dataUser, dataActor) => {
      //seteamos las variables de estado que almacenan el usuario y tipo de actor
      setUser(dataUser)
      setTipoActor(dataActor)
      //seteamos variables del storage
      if(formFields.remember_me){
        window.localStorage.setItem('remember_me', formFields.remember_me)
        window.localStorage.setItem('user', JSON.stringify(dataUser));
        window.localStorage.setItem("tipoActor", JSON.stringify(dataActor));
      }else{
        window.sessionStorage.setItem('user', JSON.stringify(dataUser));
        window.sessionStorage.setItem("tipoActor", JSON.stringify(dataActor));
      }
      goToInicio(navigate, dataUser)
    }

    const sendEmailRegistro = async (user, nav) => {
      let obj = locationData
      const email = [obj.email];
      const AppName  = variablesEnv?.REACT_APP_NAME
      const RutaArte = variablesEnv?.REACT_APP_ARTE_URL
      const url = variablesEnv?.REACT_APP_WEB_URL
      const logo  = variablesEnv?.REACT_APP_LOGO_URL
      const subject2 = `${translation[language]?.titulo_registro_arte ? translation[language]?.titulo_registro_arte : 'Registro de Arte'} `;
      const nombre = obj.nombre ? (obj.nombre?.split(' ')[0] ? obj.nombre?.split(' ')[0] : obj.nombre) : obj.email?.split('@')[0] ? obj.email?.split('@')[0] : obj.email;
      const saludo = `${translation[language]?.msg_correo_saludo ? translation[language]?.msg_correo_saludo : 'Hola,'} ${nombre}`
      const origin = "login2";
      //si registro un arte
      let mensaje2 = `
        <div style="white-space: normal;color:#B2A169;font-size:16px;padding: 5px 1.5rem 0px 1.5rem;">    
          <p style="color:#B2A169; margin-bottom: 0px!important">${translation[language]?.msg_correo_registro_1 ? translation[language]?.msg_correo_registro_1 : 'La pieza de Arte'} <a href="${RutaArte}?codigo_pieza=${obj.codigo_pieza}" style="color:#B2A169;text-decoration:underline !important;" target="_blank">${obj?.nombre_arte}</a>  ${translation[language]?.msg_correo_registro_2 ? translation[language]?.msg_correo_registro_2 : 'se registró con éxito.  Haz clic en el título de la pieza para verla.'}</p>
          <p style="color:#B2A169;margin-bottom: 0px!important; margin-top: 2rem !important">${translation[language]?.msg_correo_registro_3 ? translation[language]?.msg_correo_registro_3 : 'Para próximas consultas o registros de Arte, haz clic'} <a href="${url}/registro-arte" style="color:#B2A169;text-decoration:underline !important;">${translation[language]?.msg_correo_registro_4 ? translation[language]?.msg_correo_registro_4 : 'aquí'}</a>.</p>
          <p style="color:#B2A169;margin-bottom: 0px!important; margin-top: 3rem !important">${translation[language]?.msg_correo_registro_5 ? translation[language]?.msg_correo_registro_5 : '¡Hasta pronto!'}</p>
          <p style="color:#B2A169; margin-top: 0px!important;">${AppName}</p>
          </div>
      `
      //Función que envía el correo
      let result = obj.registro_arte ? await sendEmail(email, subject2, saludo, mensaje2, origin, null, logo) : {response: true}
      if(result.response){
        goToHome(user, nav)
      }else{
        goToHome(user, nav)
      }
    }

    //para atajar el evento al dar clic en recuperar
    const login = () => {
      setLoading(true)
      let newMsg = translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'
      setMsg(newMsg)
      let u;
      api.put("/put_auth_user",{
        email:formFields.email,
        clave:formFields.password
      }).then(response => {
        u = response.data
        let tok = "Token " + u.token
        api.get("/get_accesos_tipo_actor",{
          params:{grupo_actor:u.grupo_actor},
          headers:{Authorization: tok}
        }).then(response => {
          if(locationData?.registro_arte){
            sendEmailRegistro(u, response.data)
          }else{
            goToHome(u, response.data)
          }
        })
        .catch(err => {
          if(locationData?.registro_arte){
            sendEmailRegistro(u, [])
          }else{
            goToHome(u, [])
          }
        })
      })
      .catch(error => {
        setLoading(false)
        switch (error?.response?.status) {
          case 401:
            let mensaje = admin ? `${translation[language]?.msg_correo_contrasena_incorrecto ? translation[language]?.msg_correo_contrasena_incorrecto : "El correo o la contraseña son incorrectos. Inténtalo de nuevo."}` : `${translation[language]?.msg_correo_pin_incorrecto ? translation[language]?.msg_correo_pin_incorrecto : "El correo o el pin de seguridad son incorrectos. Inténtalo de nuevo."}`
            showModal?.current?.content?.info(
              null, //accion que tomara 
              mensaje, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          case 406:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_usuario_desactivado ? translation[language]?.msg_usuario_desactivado : "Usuario desactivado. Inténtalo de nuevo."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      if (!validateFields()) {
        return       
      }
      login()
    }

    //con este efecto redireccionamos al usuario al inicio si ya hay una sesion abierta
    useEffect(()=> {
      if(user!== null && (tipoActor!==null)){
        goToInicio(navigate, user)
      }
      if(!admin && !locationData){
        showModal?.current?.content?.info(
          7, //accion que tomara 
          `${translation[language]?.msg_introducir_codigos ? translation[language]?.msg_introducir_codigos : "Por favor, Introduce un código de invitación y un código de pieza para acceder."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return
      }
      if(!admin && locationData && locationData?.autoLogin){
        login()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
            <>
              <Content> 
                <ModalContent
                  ref={showModal}
                  // acceptAction={handleAcceptAction}
                  // modalContentInside={textdivContent}
                  translation={translation}
                  language={language}
                />
                {loading  && (
                  <LoadingScreen>
                    <div className="feedback-visual">
                      <CSpinner
                        className="primary-color big-spinner"
                      />
                    </div>
                    <h5 className="loading-msg">{msg}</h5>
                  </LoadingScreen>
                )}
                {(!loading && !locationData?.autoLogin) && (
                  <>
                    <Login
                      formFields={formFields}
                      formErrors={formErrors}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleForgot={handleForgot}
                      admin={admin}
                      translation={translation}
                      language={language}
                    />                 
                  </>
                )}
              </Content>
              <div className="d-flex logoContent gray-color-text font-size-11">
                by  
                <img src={SysmodelersIcon} alt="sysIcon" width="110" className="ms-2"/>
              </div>               
            </>
    )
}


const Content = styled.div`
  min-height: 100vh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  padding: 7%; 
  .big-spinner{
    height: 150px;
    width: 150px;
  }
  .form-control:disabled {
      background-color: #F5F5F5;
      border-color: var(--primary-color);
      opacity: 1;
  }
`