import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {createRoot} from 'react-dom/client'
import '@coreui/coreui/dist/css/coreui.min.css'
import './index.css';
import "cropperjs/dist/cropper.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

createRoot(
  document.getElementById('root')
).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
reportWebVitals();
