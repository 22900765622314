import React, {useState} from 'react'
import {
  CForm,
  CFormText,
  CInputGroup,
  CFormLabel,
  CFormFeedback,
  CRow,
  CCol,
} from '@coreui/react'
import styled from 'styled-components'
import {
  CInputGroupImg, 
  CInput, 
  InputGroupText, 
  Button,
  FormCheck
} from '../../hooks/styled'
//icons
import MailIcon from '../../icons/Icon material-email.svg';
import LockIcon from '../../icons/Icon awesome-lock.svg';
import EyeIcon from "../../icons/icon-feather-eye.svg";
import EyeOffIcon from "../../icons/icon-feather-eye-off.svg";
import logo from "../../icons/logo-blanco.svg";
import logoGold from "../../icons/logo90.svg";
export default function Login({
  formFields,
  formErrors,
  handleChange,
  handleSubmit,
  handleForgot,
  admin,
  //traducciones
  translation={},
  language="",
}) {
    const [showPassword, setShowPassword] = useState(false);
    return (
              <ContainerLogin className={`${admin ? 'admin-container' : 'user-container'}`}>
                <CRow className="">
                  <ColLeft md={admin ? 6 : 12} className="border-colLeftLogin px-4 py-4">  
                    {!admin && (
                      <div className="px-3 mb-3 text-center">
                        <img
                          src={logoGold}
                          alt="correo"
                          width="100"
                          height="100"
                        />
                      </div>

                    )}
                    <CForm 
                      className="mt-4 px-3"
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      {admin && (
                        <CFormLabel className="title mb-4">{translation[language]?.titulo_inicio_sesion ? translation[language]?.titulo_inicio_sesion : "Inicio de sesión"}</CFormLabel>
                      )}
                      
                      <CInputGroup className={`${formErrors.email ? 'mb-0' : 'mb-4'}`}>
                          <InputGroupText className={`n-bg-gray ${formErrors.email ? 'invalid-border-color' : 'border-color-gray'}`}>
                            <img
                              src={MailIcon}
                              alt="correo"
                              width="18"
                              height="18"
                            />
                          </InputGroupText>
                        <CInput
                          type="text"
                          id="email"
                          placeholder={`${translation[language]?.correo ? translation[language]?.correo : 'Correo'}`}
                          name="email"
                          className={`border-start-0 ${formErrors.email ? 'background-image-none' : ''}`}
                          value={formFields.email}
                          invalid={formErrors.email}
                          disabled={!admin ? true : false}
                          onChange={handleChange}
                        />
                      </CInputGroup>
                      {formErrors.email && (
                        <CFormFeedback invalid className="d-block mb-4">
                          {translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"}
                        </CFormFeedback> 
                      )}
                      <CInputGroup>
                          <InputGroupText className={`n-bg-gray ${formErrors.password ? 'invalid-border-color' : 'border-color-gray'}`}>
                            <img
                              src={LockIcon}
                              alt="password"
                              width="18"
                              height="18"
                            />
                          </InputGroupText>
                        <CInput
                          type={showPassword ? "text" : "password"}
                          className={`border-start-0 border-end-0 ${formErrors.password ? 'background-image-none' : ''}`}
                          placeholder={admin ? `${translation[language]?.contrasena ? translation[language]?.contrasena : 'Contraseña'}` : `${translation[language]?.codigo_seguridad ? translation[language]?.codigo_seguridad : 'Código de seguridad'}`}
                          name="password"
                          value={formFields.password}
                          invalid={formErrors.password}
                          onChange={handleChange}
                          onInput={!admin ? (e) => e.target.value = e.target.value.slice(0, 6) : undefined}
                        />
                        <CInputGroupImg
                          className={`${formErrors.password ? 'invalid-border-color' : 'border-color-gray'}`}
                        >
                          <img 
                              src={!showPassword ? EyeOffIcon : EyeIcon}
                              alt="eyes" 
                              width="20"
                              height="20"
                              onClick={() => setShowPassword(!showPassword)}
                              className="cursor-pointer"
                          /> 
                        </CInputGroupImg>
                      </CInputGroup>
                      {formErrors.password && (
                        <CFormFeedback invalid className="d-block">
                          {translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"}
                        </CFormFeedback> 
                      )}
                      {admin && (
                      <FormCheck 
                        className="mt-3" 
                        label={translation[language]?.check_recuerdame ? translation[language]?.check_recuerdame : 'Recuérdame'}
                        name="remember_me"
                        value={formFields.remember_me}
                        onChange={handleChange}
                      />
                      )}
                      <CRow className="d-flex align-items-center mt-4">
                        <CCol md="4">
                          <Button 
                            type="submit"
                          >
                            {translation[language]?.boton_entrar ? translation[language]?.boton_entrar : 'Entrar'}
                          </Button>
                        </CCol>
                        {admin && (
                          <CCol md="8" className="d-flex mt-2 mt-md-0 justify-content-md-end">
                            <CFormText 
                              className="primary-text-color cursor-pointer font-size-default"
                              onClick={() => handleForgot()}
                            >
                              {translation[language]?.hint_olvidaste_contrasena ? translation[language]?.hint_olvidaste_contrasena : '¿Olvidaste tu contraseña?'}
                            </CFormText>
                          </CCol>
                        )}
                      </CRow>
                    </CForm>
                  </ColLeft>
                  {admin && (
                  <ColRight md="6" className="border-colRightLogin p-4 text-center">
                    <div className="mt-4 px-3">
                      <img
                        src={logo}
                        alt="logo"
                        width="130"
                        height="130"
                      />
                      <p className="text-white mt-2">
                        {translation[language]?.hint_mensaje_principal ? translation[language]?.hint_mensaje_principal : 'Una nueva forma de Arte Real para construir el Nuevo Mundo'}
                      </p>
                    </div>
                  </ColRight>
                  )}
                </CRow>
              </ContainerLogin>
    )
}


const ContainerLogin = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;  
`;

const ColLeft = styled(CCol)`
  background-color: #ffffff;
  border-radius: 0rem;
  border: 1px solid #B2A169;
`
const ColRight = styled(CCol)`
  background-color: #B2A169;
  border: 1px solid #B2A169;
  border-radius: 0rem;
`