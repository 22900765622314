import React from "react";
import {
  CRow,
  CCol,
  CTooltip,
  CBadge
} from '@coreui/react'
import styled from 'styled-components'
import borrarIcon from '../../../icons/Icono borrar - dorado.svg'
import pencilIcon from '../../../icons/pencil.svg'
import transferIcon from '../../../icons/Icon open-transfer.svg'


export default function PiezaListRows({
  item,
  index,
  total,
  handleDeletePieza,
  handleClickShowBidi,
  handleEditPieza,
  handleGoToTransferirArte,
  //traducciones
  translation={},
  language="", 
}) {


    const returnNombrePropietario = (obj) => {
      let newObj = obj.find( newItem => newItem.vigencia) ? obj.find( newItem => newItem.vigencia) : null
      let name = newObj ? `${newObj?.nombre_propietario ? newObj?.nombre_propietario : ''} ${newObj?.apellido_propietario ? newObj?.apellido_propietario : ''}` : ''
      return name;
    }

    return (
            <Content>
              <div className={`bg-custom d-xl-none`}> 
                  <CRow className="p-2 pl-3">               
                      <CCol xs="12">
                      {`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}: {item.codigo_pieza ? item.codigo_pieza : ""}
                      </CCol>
                  </CRow>
              </div> 
              <CRow className="py-2 px-3 px-sm-3 d-flex align-items-center">
                <CCol xl={2} className="px-1 d-none d-xl-block">
                  {item.codigo_pieza ? item.codigo_pieza : ""}
                </CCol>
                <CCol xl={2} className="px-1">
                  <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : "Código de invitación"}`}: </span>
                  {item.codigo_invitacion ? item.codigo_invitacion : ""}
                </CCol>
                <CCol xl={2} className="px-1">
                  <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.url ? translation[language]?.url : "URL"}`}: </span>                 
                  <span 
                    className=""
                  >
                    {item.url ? item.url : ""}
                  </span>                 
                </CCol> 
                <CCol xl={1} className="px-1">
                  <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.numero_pieza ? translation[language]?.numero_pieza : "Número de pieza"}`}: </span>
                  {item.numero_pieza ? item.numero_pieza : ""}
                </CCol>    
                <CCol xl={1} className="px-1">
                  <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.bidi ? translation[language]?.bidi : "BIDI"}`}: </span>
                  <span 
                    className="text-decoration-underline cursor-pointer"
                    onClick={()=> handleClickShowBidi(item)}
                  >
                    {`${translation[language]?.bidi_ver ? translation[language]?.bidi_ver : "Ver"}`}
                  </span>
                </CCol>           
                <CCol xl={2} className="px-1">
                  <span className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.propietario ? translation[language]?.propietario : "Propietario"}`}: </span>
                  {/* {item?.propietario_pieza?.length > 0 ? (item.propietario_pieza.find( newItem => newItem.vigencia) ? item.propietario_pieza.find( newItem => newItem.vigencia)?.nombre_propietario : '') : ""} */}
                  {item?.propietario_pieza?.length > 0 &&  item.propietario_pieza.find( newItem => newItem.vigencia) && (
                  <>
                    <div>{returnNombrePropietario(item.propietario_pieza)}</div>               
                    {!item?.propietario_pieza?.find( newItem => newItem.vigencia)?.boo_estatus && (
                      <CTooltip content={`${translation[language]?.etiqueta_pendiente_registro ? translation[language]?.etiqueta_pendiente_registro : "Nuevo Propietario pendiente por registrarse"}`}>
                        <CBadge className={`cursor-pointer rounded-0 badge-color`}>{`${translation[language]?.etiqueta_transicion ? translation[language]?.etiqueta_transicion : "En transición"}`}</CBadge>                   
                      </CTooltip>
                    )}
                  </>
                  )}                
                </CCol>       
                <CCol xl={2} className="px-1 d-xl-flex justify-content-xl-end">
                  {(item?.propietario_pieza?.length > 0 &&  !item.propietario_pieza.find( newItem => newItem.vigencia)?.boo_estatus ) && (
                    <div className="me-1 mb-2">
                      <span  className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.enviar_invitacion_registrar ? translation[language]?.enviar_invitacion_registrar : "Enviar invitación para registrar"}`}</span>
                      <CTooltip content={`${translation[language]?.enviar_invitacion_registrar ? translation[language]?.enviar_invitacion_registrar : "Enviar invitación para registrar"}`}>
                        <img 
                          className='icon-border' 
                          src={transferIcon}  
                          alt="icon"
                          width="25px"
                          height="25px"
                          onClick={()=> {
                            handleGoToTransferirArte(item)
                          }}
                        />
                      </CTooltip>
                    </div>
                  )} 
                  <div className="me-1 mb-2">
                    <span  className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}: </span>
                    <CTooltip content={`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}>
                      <img 
                        className='icon-border' 
                        src={pencilIcon}  
                        alt="icon"
                        width="25px"
                        height="25px"
                        onClick={()=> {
                          window.scrollTo(0, 0);
                          handleEditPieza(item)
                        }}
                      />
                    </CTooltip>
                  </div>
                  <div>
                    <span  className={`d-inline-flex d-xl-none me-1`}>{`${translation[language]?.hint_eliminar ? translation[language]?.hint_eliminar : "Eliminar"}`}: </span>
                    <CTooltip content={`${translation[language]?.hint_eliminar ? translation[language]?.hint_eliminar : "Eliminar"}`}>
                      <img 
                        className='icon-border' 
                        src={borrarIcon}  
                        alt="icon"
                        width="25px"
                        height="25px"
                        onClick={()=> handleDeletePieza(item.id_pieza)}
                      />
                    </CTooltip>
                  </div>
                </CCol>
              </CRow>
              {index+1 !== total && (
                  <hr className="mt-0 mb-0 primary-color"/>
              )}     
            </Content>
    )
}
const Content = styled.div`
.bg-custom{
  background-color: #F7F5EF;
}
`
