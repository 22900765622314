/* eslint-disable no-useless-escape */
import {
  CInputGroupText,
  CFormInput,
  CButton,
  CFormCheck,
  CCard,
  CFormSelect,
  CFormTextarea,
  CDropdownItem
} from '@coreui/react'
import styled from 'styled-components'

export const LoadingScreen = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  .feedback-visual {
    padding: 20px;
  }
`;

export const CInput = styled(CFormInput)`
  background-color: #fff;
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
  box-shadow: none !important;
  font-size: var(--font-size);
  border-radius: 0rem;
  :focus {   
    border: 1px solid var(--primary-color);
    color: var(--primary-color) !important;
  }
  ::placeholder {
    color: var(--primary-color);
    opacity: 1;
  }
  :-ms-input-placeholder, ::-ms-input-placeholder {
    color: var(--primary-color) ;
  }
`

export const FormTextarea = styled(CFormTextarea)`
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
  box-shadow: none !important;
  font-size: var(--font-size);
  border-radius: 0rem;
  :focus {   
    border: 1px solid var(--primary-color);
    color: var(--primary-color) !important;
  }
  ::placeholder {
    color: var(--primary-color);
    opacity: 1;
  }
  :-ms-input-placeholder, ::-ms-input-placeholder {
    color: var(--primary-color) ;
  }
`

export const FormSelect = styled(CFormSelect)`
  background-color: #fff;
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
  box-shadow: none !important;
  font-size: var(--font-size);
  border-radius: 0rem;
  :focus {   
    border: 1px solid var(--primary-color);
    color: var(--primary-color) !important;
  }
  ::placeholder {
    color: var(--primary-color);
    opacity: 1;
  }
  :-ms-input-placeholder, ::-ms-input-placeholder {
    color: var(--primary-color) ;
  }
`

export const CInputGroupImg = styled(CInputGroupText)`
  background-color: #fff;
  border-radius: 0rem;
`
export const InputGroupText = styled(CInputGroupText)`
  border-radius: 0rem;
`

export const DropdownItem = styled(CDropdownItem)`
  color: var(--primary-color);
`


export const Button = styled(CButton)`
  background-color: var(--primary-color)!important;
  border-color: var(--primary-color)!important;
  color: #fff;
  box-shadow: none!important;
  :hover, :focus, :active, :focus-visible {
    color: #fff;
    background-color: #BEB083!important;
    border-color: #BEB083!important;
  }
  font-size: var(--font-size);
  border-radius: 0rem;
`

export const ButtonVariant = styled(CButton)`
  font-size: var(--font-size);
  border-radius: 0rem;
  color: var(--primary-color) !important;
  box-shadow: none!important;
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  :hover, :focus, :active, :focus-visible {
    color: #fff !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
`

export const FormCheck = styled(CFormCheck)`
  .form-check-label{
    font-size: var(--font-size);
    color: var(--primary-color) !important;
    margin-left: -0.2em;
    margin-top: 0.15rem;
  }
`

export const Card = styled(CCard)`
  border-radius: 0px !important;
`

export const Card2 = styled(CCard)`
  border: 1px solid var(--primary-color); 
  border-radius: 0px!important;
  .list-header {
    @media (max-width: 991.5px) {
      display: none;
    }
  }
  .bg-color{
    background-color: var(--primary-color) !important;
  }
`

export const Content = styled.div`
  min-height: 100vh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  padding: 7%; 
  .big-spinner{
    height: 150px;
    width: 150px;
  }
`

export const FormSwitch = styled.div`
.switch {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.2rem;
}

/* Esconde el checkbox HTML default del swith */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E1D9C3;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  left: 0rem;
  bottom: 0rem;
  background-color: var(--color);
  transition: .4s;
}

input:checked + .slider {
  background-color: #E1D9C3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #E1D9C3;
}

input:checked + .slider:before {
  transform: translateX(1.3rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
`