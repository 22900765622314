/* eslint-disable no-useless-escape */
import { differenceInYears, parse, format, parseISO } from "date-fns"
export const globalFunctions = () => {

    //con esta funcion redireccionamos a home
    const goToInicio = (navigate, user) => {
      switch (user?.grupo_actor) {
        case 1:
          navigate("/admin-artes");
          break;
        case 2:
          navigate("/mis-artes");
          break;      
        default:
          navigate("/portal");
          break;
      }    
    }

    //con esta funcion validamos un string es internacional
    const validationInternationalName = (str) => {
        let pattern = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
        return pattern.test(str);
    }
    
    //con esto validamos si un string es un correo electronico valido
    const validationEmailAddress = (str) => {
        let pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);
    }

    //con esto validamos si un string es numerico
    const validationIsNumberInt = (str) => {
        let pattern =/^[0-9]+$/;
        return pattern.test(str);
    }

    //con esto validamos si un string es numerico
    const validationIsAlphaNumeric = (str) => {
        let pattern =/^[a-zA-Z0-9]+$/;
        return pattern.test(str);
    }

    //con esto validamos si un string es solo texto
    const validationCheckText = (str) => {
        let pattern =/^[\p{L}\s]+$/iu;
        return pattern.test(str);
    }

    //para poder colocar tablas de colores
    const IsEven = (intNumber) =>{
        if(intNumber % 2 === 0){
            return true;
        } else {
            return false;
        }  
    }

    //para cortar texto
    const truncateText = (str, max_char) =>{
      if (str.length > max_char) {
        return str.substring(0, max_char) + '...';
     }
     return str;
    }

    //primera letra en mayuscula
    const titleCase = (str) => {
        return str.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(' ');
    }

    //funcion para calcular la edad
    const calculateAge = (dob) => {
      const parseDate = format(parseISO(dob), "dd/MM/yyyy");
      const date = parse(parseDate, "dd/MM/yyyy", new Date())
      const age = differenceInYears(new Date(), date)
      return age
    }

    //con esto buscamos un objecto dentro de un arreglo
    const findObjFromArray = (array, variable, valor) => {
        let item
        if(array.length > 0){
            item = array.find(item => item[variable] === valor);
        }
        if(item) {
          return item;
        } else {
          return [];
        }
    }


    //Con esto vamos a redondear los numeros visualmente
    const roundNumber = (num) => {
      let newValue;
      newValue = (num >= 1 || num < 0) ? (Math.round((num))) : (Math.round((num) * 100) / 100)
      return newValue;
    }

    //Con esto vamos a redondear los numeros visualmente
    const roundNumberTwo = (num) => {
      return Math.round((num) * 100) / 100;
    }

    //con esto retornamos un arreglo ordenado por una propiedad (titulo, etc). Si es un string colocamos toLowerCase
    const returnNewObjSort = (array, property, cloneArry=true, string=true) => {
      let newObj = cloneArry ? (JSON.parse(JSON.stringify(array))) : array
      let newArray = newObj.sort( (a, b) => { 
        let newA = string ? a[property].toLowerCase() : a[property]
        let newB = string ? b[property].toLowerCase() : b[property]
        if (newA < newB) {
          return -1;
        }
        if (newA > newB) {
          return 1;
        }
        return 0;
      });
      return newArray
    }

    //con esto vamos a retornar el idioma por defecto del explorador
    const returnLanguage = () => {
      let lang = window.navigator.languages ? window.navigator.languages[0] : null;
      lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
  
      let shortLang = lang;
      if (shortLang.indexOf('-') !== -1)
          shortLang = shortLang.split('-')[0];
  
      if (shortLang.indexOf('_') !== -1)
          shortLang = shortLang.split('_')[0];   

      return shortLang;
    }

    const propertyTypeOf = (value) => {
      return Object.prototype.toString.call(value).split(' ')[1].split(']')[0].toString()
    }

    //con esta funcion vamos a retornar la clase o el mensaje de un arte. Para saber si esta registrada o no
    const returnClassOrMessageLanguage = (indice, item, message=false, translation, language, form=false) => {
      let registered = item?.indice_idioma_artes?.length > 0 ? item?.indice_idioma_artes?.includes(indice) : false
      let result;
      if(!message){
        // let another = form ? ( parseInt(indice) === parseInt(language)) : ( parseInt(indice) === parseInt(item.indice_idioma))
        let selected = (registered || form) ? ((parseInt(indice) === parseInt(language)) ? true : false) : false
        let newClassName = selected ? "language-selected" : (registered ? "language-registered" : "language-not-registered")
        result = newClassName
      }else{
        result = registered ? `${translation[language]?.hint_arte_creada ? translation[language]?.hint_arte_creada : "Arte creada para el idioma"}` : `${translation[language]?.hint_arte_no_creada ? translation[language]?.hint_arte_no_creada : "Arte no creada para el idioma"}`
      }
      return result;
    }

    return {
      goToInicio,
      validationInternationalName,
      validationEmailAddress,
      validationIsNumberInt,
      validationCheckText,
      IsEven,
      titleCase,
      findObjFromArray,
      roundNumber,
      roundNumberTwo,
      calculateAge,
      validationIsAlphaNumeric,
      truncateText,
      returnNewObjSort,
      returnLanguage,
      propertyTypeOf,
      returnClassOrMessageLanguage
    };
}
