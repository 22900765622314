import React from 'react'
import {CModal, CModalBody} from '@coreui/react';
import {Button, ButtonVariant} from './styled'

export default function Modal({
  show, 
  iconVisible=true, 
  hasTitle=true, 
  onClose, 
  icon, 
  modalContentTitle, 
  contentSecondary,
  content, 
  rightButtonHandle, 
  leftButton,
  leftButtonName="Cancelar",
  leftButtonHandle, 
  buttons=true, 
  rightButtonName="Aceptar",
  contentp=false, 
  textdiv,
  size
}) {
  return (
    <>
      <CModal 
        alignment="center" 
        visible={show}
        onDismiss={onClose}
        size={size === "md" ? null : size}
        keyboard={false}
      >
        {show && (
          <>
        {contentp ? (
          <div>{textdiv}</div>
        ) :
        (
          <CModalBody className="text-center">
            {iconVisible && (
              <img 
                  src={icon}
                  alt="icon" 
                  width="33"
                  height="33"
                  className="mb-4 mt-1"
              />          
            )}
            {hasTitle && (
              <p className="fw-bold mb-3">{modalContentTitle}</p>         
            )}                               
            <p className="text-small mb-1">{content}</p>
            {contentSecondary && (
              <p className="text-small mb-1">{contentSecondary}</p>
            )} 
          </CModalBody>
        )
        }
        {buttons && (
          <div className="text-center">  
            {leftButton && (
              <ButtonVariant
                className="text-white mb-3 me-3"
                style={{padding: '.3rem 1.3rem'}}
                color="success" 
                onClick={leftButtonHandle}>
                  {leftButtonName}
              </ButtonVariant>
            )}    
            <Button 
              className="text-white mb-3"
              color="success" 
              style={{padding: '.3rem 1.3rem'}}
              onClick={rightButtonHandle}>
                {rightButtonName}
            </Button>
          </div>
        )}
          </>
        )}
      </CModal>
    </>
  )
}

