import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CCardHeader,
  CSpinner,
  CFormLabel,
  CFormFeedback,
  CForm,
  CTooltip
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  Card2,
  CInput,
  ButtonVariant,
  Button,
  FormTextarea
} from '../../hooks/styled'
import Header from "../../hooks/Header";
import Pagination from "../../hooks/Paginacion";
import SerieListRows from './SerieListRows';
import infoIcon from '../../icons/Icon ionic-md-information-circle-outline.svg'
import camaraIcon from '../../icons/Icono camara - dorado.svg'
import iconoCheck from '../../icons/Icono check.svg'
import borrarIcon from '../../icons/Icono eliminar circular.svg'


export default function CardContent({
  data,
  dataConfig,
  getData,
  loadingData,
  setDataConfig,
  setForm,
  discardChanges,
  formFields,
  formErrors,
  formErrorsText,
  changes,
  handleChangeFiltros,
  handleSubmit,
  handleChange,
  handleChangeImage,
  inputFile,
  handleClickShowImg,
  handleDeletePicture,
  //traducciones
  translation={},
  language="",
}) {

    //con esto retornamos las filas
    const RenderData = (registro) => {
      return (
        <>
          {registro.map((item, i) => {
            return (
              <SerieListRows
                key={i}
                item={item}
                total={registro.length}
                index={i}
                handleEditarItem={setForm}
                handleClickShowImg={handleClickShowImg}
                translation={translation}
                language={language}
              />
            );
          })}
        </>
      );
    };

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-2 px-sm-4">
                    <Header
                      title={`${translation[language]?.titulo_administrar_colecciones ? translation[language]?.titulo_administrar_colecciones : "Administrar Colecciones"}`}
                      isBack={false}
                      isSearchAndFilter={true}
                      isFilter={false}
                      dataConfig={dataConfig}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      placeholderSearch={`${translation[language]?.search_coleccion ? translation[language]?.search_coleccion : "Buscar Colección"}`}
                      isButton={false}
                      showValuesText={`${translation[language]?.mostrar ? translation[language]?.mostrar : "Mostrar"}`}
                      filterByText={`${translation[language]?.filtrar_por ? translation[language]?.filtrar_por : "Filtrar por"}`}
                      selectText={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                      noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                    /> 
                    <Card2 className="border-0 pb-4">
                      <CCardHeader className="border-bottom-0 bg-color rounded-0 font-size-12 text-white d-none d-lg-block">
                        <CRow className="py-2">
                          <CCol lg={2}>{`${translation[language]?.imagen ? translation[language]?.imagen : "Imagen"}`}</CCol>
                          <CCol lg={2}>{`${translation[language]?.nombre ? translation[language]?.nombre : "Nombre"}`}</CCol>
                          <CCol lg={5}>{`${translation[language]?.descripcion ? translation[language]?.descripcion : "Descripción"}`}</CCol>
                          <CCol lg={3}></CCol>
                        </CRow>
                      </CCardHeader>
                      <CCardBody className="py-0 px-0 border border-color font-size-12">
                        <CForm 
                          onSubmit={handleSubmit}
                          noValidate
                          className="even2 mb-3"
                        >  
                          <CRow className="py-2 px-2 px-sm-3"> 
                            <CCol sm={6} lg={2} className="mb-3 mb-lg-0">
                              <CFormLabel 
                                className="d-block d-lg-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.imagen ? translation[language]?.imagen : "Imagen"}`}
                              </CFormLabel> 
                              <div className="d-flex align-items-center">
                                <div className="w-100 image-upload d-flex justify-content-center">
                                  <CFormLabel className="mt-0 mb-0" htmlFor={`file-input`}>
                                    <CTooltip content={`${translation[language]?.hint_seleccionar_imagen ? translation[language]?.hint_seleccionar_imagen : "Seleccione la imagen a insertar"}`}>
                                      <img 
                                        className='ms-0 cursor-pointer' 
                                        src={camaraIcon}  
                                        alt="icon"
                                        width="22px"
                                        height="22px"
                                      /> 
                                    </CTooltip>
                                  </CFormLabel>
                                  <CInput 
                                    className="d-none" 
                                    id={`file-input`}
                                    name={`file-input`}
                                    type="file" 
                                    accept="image/*"
                                    onChange={(e) => handleChangeImage(e, "imagen")} 
                                    ref={inputFile}
                                  />
                                </div>
                                {formFields.imagen && (
                                  <>
                                    <CTooltip content={`${translation[language]?.hint_imagen_cargada ? translation[language]?.hint_imagen_cargada : "La imagen cargó correctamente"}`}>
                                      <img 
                                        className='ms-1 cursor-pointer' 
                                        src={iconoCheck}  
                                        alt="icon"
                                        width="15px"
                                        height="15px"
                                      /> 
                                    </CTooltip>
                                    <CTooltip content={`${translation[language]?.hint_eliminar_imagen ? translation[language]?.hint_eliminar_imagen : "Eliminar imagen"}`}>
                                      <img 
                                        className='ms-1 cursor-pointer' 
                                        src={borrarIcon}  
                                        alt="icon"
                                        width="15px"
                                        height="15px"
                                        onClick={handleDeletePicture}
                                      /> 
                                    </CTooltip>
                                  </>
                                )}
                              </div>
                            </CCol>
                            <CCol  sm={6} lg={2} className="mb-3 mb-lg-0">
                              <CFormLabel 
                                className="d-block d-lg-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.nombre ? translation[language]?.nombre : "Nombre"}`}
                              </CFormLabel> 
                              <CInput
                                size="sm"
                                type="text"
                                name="nombre"
                                value={formFields.nombre}
                                invalid={formErrors.nombre}
                                onChange={handleChange}
                                className="font-size-12 bg-transparent"
                              />
                              <CFormFeedback invalid>{formErrorsText?.nombre}</CFormFeedback>
                            </CCol>
                            <CCol lg={5} className="mb-3 mb-lg-0">
                              <CFormLabel 
                                className="d-block d-lg-none font-size-default primary-text-color font-size-12" 
                              >
                                {`${translation[language]?.descripcion ? translation[language]?.descripcion : "Descripción"}`}
                              </CFormLabel> 
                              <FormTextarea 
                                rows="1"
                                className="form-control-sm font-size-12 bg-transparent"
                                name="descripcion"
                                value={formFields.descripcion}
                                invalid={formErrors.descripcion}
                                onChange={handleChange}
                              />
                              <CFormFeedback invalid>{formErrorsText?.descripcion}</CFormFeedback>
                            </CCol>
                            {(formFields.edit || changes) && (
                              <div className="col-lg-1-5-50">
                                <ButtonVariant 
                                  type="button"
                                  className="font-size-11 w-100 padding-button" 
                                  onClick={()=> discardChanges()}
                                >
                                  {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                                </ButtonVariant>     
                              </div>                            
                            )}
                            <div className={`${(formFields.edit || changes) ? 'col-lg-1-5-50' : 'col-lg-3'}`}>
                              <Button 
                                type="submit"
                                className="font-size-11 w-100 padding-button" 
                              >
                                {(formFields.edit || changes) ? `${translation[language]?.boton_guardar ? translation[language]?.boton_guardar : "Guardar"}` : `${translation[language]?.boton_agregar ? translation[language]?.boton_agregar : "Agregar"}`}
                              </Button>     
                            </div>
                          </CRow> 
                        </CForm>
                        {data.length > 0 && !loadingData ?  
                        (
                          <>{RenderData(data)}</>
                        ) 
                        : 
                        (
                            <CContainer className="text-start even ps-4 mb-5">  
                              <CRow>               
                                <CCol xs={12} className={`d-flex align-items-center mb-3 mt-3 ${loadingData && "justify-content-center"}`}>
                                  {loadingData ? 
                                    (
                                      <div className="text-center">
                                        <CSpinner size="sm"/>
                                          <CSpinner size="sm" variant="grow"/>
                                          <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                                      </div>
                                    )
                                  :
                                    (
                                      <>
                                        <div className="me-2">
                                          <img
                                            src={infoIcon}
                                            alt=""
                                            width="18"
                                          />
                                        </div>
                                        <span 
                                            className={`font-size-14`}
                                        >
                                          {dataConfig?.searchTerm === "" ? `${translation[language]?.msg_error_colecciones ? translation[language]?.msg_error_colecciones : 'Aún no existen Colecciones registradas.'}` : `${translation[language]?.msg_error_resultados ? translation[language]?.msg_error_resultados : 'No se encontraron resultados para la búsqueda.'}`}
                                        </span>     
                                      </>
                                    )
                                  }                              
                                </CCol>
                              </CRow>                              
                            </CContainer>
                        )
                        }
                      </CCardBody>
                    </Card2>
                    {(data.length > 0 && !loadingData) && (
                      <Pagination
                        getData={getData} // funcion para actualizar la data
                        dataConfig={dataConfig} // data que almacena toda la configuracion de busqueda, paginacion y filtrado
                        setDataConfig={setDataConfig}
                        ifGetData={true} // indica si obtenemos la data con endpoint
                        //traducciones
                        translation={translation}
                        language={language}
                      />
                    )}
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
.even2{
  background-color: #F6F4EE;
}
.padding-button {
  padding: 0.3rem 0.3rem;
}
.bg-transparent{
  background-color: transparent !important;
}
.image-upload{
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem; 
  border: 1px solid var(--primary-color);
  color: var(--primary-color) !important;
}
`
