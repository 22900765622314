import React , { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useStateRef from 'react-usestateref'
import {
  CContainer,
  CForm
} from '@coreui/react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import FormularioArtista from './FormularioArtista';
import ModalContent from '../../../hooks/ModalContent'
import { globalFunctions } from "../../../hooks/globalFunctions";
import api from '../../../api/api'

export default function CrudArtista({
  user,
  edit,
  variablesEnv,
  setChangesApp,
  //traducciones
  translation={},
  language="",
  idiomas=[]
}) {
  
    //Variables
    
    const navigate = useNavigate();
    const { 
      calculateAge,
      validationCheckText,
      propertyTypeOf,
      returnNewObjSort
    } = globalFunctions();
    
    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null
    // Esta es la variable que almacenara los parametros que enviaremos al endpoint
    let idArtista = locationData?.id_artista ? locationData?.id_artista : null

    const showModal = useRef(); //referencia del hijo

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    const [changes, setChanges] = useState(false); //si hubo cambios
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [loadingData, setLoadingData] = useState(true) //variable que indica cuando esta cargando
    const [paises, setPaises] = useState([]); //lista de paises
    const [inicio, setInicio] = useState(true); //con esta variable validamos si ya cargo por primera vez el form  o no

    //variable que almacena los valores
    const [formFields, setFormFields] = useState({
      id_artista: null,
      nombre_apellido: "",
      fecha_nacimiento: "",
      seudonimo: "",
      pais_nacimiento: "",
      ciudad_nacimiento: "",
      titulo_experiencia: "",
      brief_corto: "",
      brief_largo: "",
      artes: []
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      nombre_apellido: false,
      fecha_nacimiento: false,
      seudonimo: false,
      pais_nacimiento: false,
      ciudad_nacimiento: false,
      titulo_experiencia: false,
      brief_corto: false,
      brief_largo: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      nombre_apellido: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      fecha_nacimiento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      seudonimo: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      pais_nacimiento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      ciudad_nacimiento: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      titulo_experiencia: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      brief_corto: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      brief_largo: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });


    //con esto sabremos cuando esten cargados todos los endpoints
    const [dominiosLoad, setdominiosLoad, dominiosLoadRef] = useStateRef({
      get_data: false,
      get_pais_dominio: false,
    })

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value } = variable;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChangesApp(true)
      setChanges(true)
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    //validaciones de campos
    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true

      if(!formFields['nombre_apellido']) {
        errorsObj['nombre_apellido'] = true
        errorsObjText['nombre_apellido'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationCheckText(formFields['nombre_apellido'])){
        errorsObj['nombre_apellido'] = true
        validState = false      
        errorsObjText['nombre_apellido'] = translation[language]?.campo_invalido ? translation[language]?.campo_invalido : "Campo inválido. Introduce letras, acentos y espacio"  
      }

      if(!formFields['fecha_nacimiento']) {
        errorsObj['fecha_nacimiento'] = true
        errorsObjText['fecha_nacimiento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(calculateAge(formFields['fecha_nacimiento']) < 18){
        errorsObj['fecha_nacimiento'] = true
        errorsObjText['fecha_nacimiento'] = translation[language]?.fecha_no_valida ? translation[language]?.fecha_no_valida : "Fecha no válida. Debes ser mayor de edad"
        validState = false            
      }

      if(!formFields['pais_nacimiento']) {
        errorsObj['pais_nacimiento'] = true
        errorsObjText['pais_nacimiento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['ciudad_nacimiento']) {
        errorsObj['ciudad_nacimiento'] = true
        errorsObjText['ciudad_nacimiento'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      if(!formFields['titulo_experiencia']) {
        errorsObj['titulo_experiencia'] = true
        errorsObjText['titulo_experiencia'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }

      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //funciones

    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          goBack()
          break;
        case 2:
          navigate('/admin-artistas')
          break;
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //Con esto enviaremos de regreso al usuario al dar clic en back
    const handleBack = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_cambios ? translation[language]?.msg_cancelar_cambios : "¿Estás seguro de que quieres cancelar estos cambios?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        goBack()
      }
    }

    //Con esta funcion enviamos de regreso al usuario al dar clic en back
    const goBack = () => {
      setChangesApp(false)
      navigate('/admin-artistas', { state: { dataCache: locationData?.oldDataCache ? locationData.oldDataCache : null}})
    }

    //Con esta funcion enviamos al usuario a ver la ficha del arte
    const goToVerArte = (item) => {
      let dataCache = {
        id_arte: item.id_arte,
        from: "/admin-editar-artista",
        oldDataCache: locationData
      }
      navigate('/ver-arte', { state: { dataCache: dataCache}})
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      //si hubo cambios
      if(!changes && edit){
        goBack()
      }else{
        //si todos los campos no estan llenos no avanza
        if (!validateFields()) {
          return       
        }
        //si todos los campos estan llenos llamamos al endpoint
        putArtista()
      }
    }


    //Con esta funcion obtenemos un artista por el id
    const getArtista = (newInicio=true) => {
      let obj = {
        id_artista: idArtista,
        indice_idioma: parseInt(language),
      };
      const newConfig = {...config, params: obj}
      api.get(`/get_artistas`, newConfig).then(response => {
        let f = response.data[0][0]
        let newObj = {
          id_artista: f.id_artista ? f.id_artista : null,
          nombre_apellido: f.nombre_apellido ? f.nombre_apellido : "",
          fecha_nacimiento: f.fecha_nacimiento ? f.fecha_nacimiento : "",
          seudonimo: f.seudonimo ? f.seudonimo : "",
          pais_nacimiento: f.pais_nacimiento ? f.pais_nacimiento : "",
          ciudad_nacimiento: f.ciudad_nacimiento ? f.ciudad_nacimiento : "",
          titulo_experiencia: f.titulo_experiencia ? f.titulo_experiencia : "",
          brief_corto: f.brief_corto ? f.brief_corto : "",
          brief_largo: f.brief_largo ? f.brief_largo : "",
          artes: f.artes ? f.artes : [],
        }
        setFormFields(JSON.parse(JSON.stringify(newObj)));
        setdominiosLoad({...dominiosLoadRef.current, get_data: true})  
        setChanges(false)
        if(newInicio){
          setInicio(false)
        } 
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              2, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //con esto llamamos al endpoint putArtista
    const putArtista = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
      let f = formFields
      let newObj = {
        artistas: [{
          id_artista: f.id_artista ? f.id_artista : null,
          nombre_apellido: f.nombre_apellido ? f.nombre_apellido : null,
          seudonimo: f.seudonimo ? f.seudonimo : null,
          pais_nacimiento: f.pais_nacimiento ? parseInt(f.pais_nacimiento) : null,
          ciudad_nacimiento: f.ciudad_nacimiento ? f.ciudad_nacimiento : null,
          fecha_nacimiento: f.fecha_nacimiento ? format(parseISO(f.fecha_nacimiento), "yyyy-MM-dd") : null,
          titulo_experiencia: f.titulo_experiencia ? f.titulo_experiencia : null,
          brief_corto: f.brief_corto ? f.brief_corto : null,
          brief_largo: f.brief_largo ? f.brief_largo : null,
          creaciones_artista: null
        }]        
      }
      api.put("/put_artista", newObj, config).then(response => {
        setAcceptAction(1)
        showModal?.current?.content?.success(
          3, //accion que tomara 
          `${translation[language]?.msg_cambios_guardados ? translation[language]?.msg_cambios_guardados : "¡Tus cambios se guardaron con éxito!"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );    
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 409:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_seudonimo_registrado ? translation[language]?.msg_error_seudonimo_registrado : "El Seudónimo ya se encuentra registrado. Por favor, ingresa uno diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break;
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })      
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      //si no hay sesion mostramos mensaje de error
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      //si no es admin mostramos mensaje de error
      if(user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      //si es edicion y el id artista no existe, mostramos mensaje de error
      if(edit && !idArtista){
        showModal?.current?.content?.info(
          6, //accion que tomara 
          `${translation[language]?.msg_seleccionar_artista ? translation[language]?.msg_seleccionar_artista : "Seleccione un Artista para editar"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      //si editar es true, significa que vamos a editar un artista, llamamos a los gets
      if(edit){
        getArtista()
      }else{
        setdominiosLoad({...dominiosLoadRef.current, get_data: true}) 
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //Efecto utilizado para obtener los paises
    useEffect(() => {
      api.get(`/get_pais_dominio?&indice_idioma=${parseInt(language)}`).then(response => {
          setPaises(response.data)
          setdominiosLoad({...dominiosLoadRef.current, get_pais_dominio: true})     
      }).catch(err => {
      })
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    //efecto para indicar que ya todo cargo y retirar el cargando
    useEffect(() => {
      if (Object.values(dominiosLoadRef.current).every(value => value === true)) {
          setLoadingData(false)
      }
    },[dominiosLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }

      if(!inicio){
        setLoadingData(true)
        getArtista(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])    
    

    //ordenamos el arreglo segun el indice de idioma
    useEffect(()=> {
      if (Object.values(dominiosLoadRef.current).every(value => value === true)) {
        let variable_paises = propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`    
        //con esto hacemos el sort
        returnNewObjSort(paises, variable_paises, false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 


    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CForm 
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <FormularioArtista
                        formFields={formFields}
                        formErrors={formErrors}
                        formErrorsText={formErrorsText}
                        handleChange={handleChange}
                        handleBack={handleBack}
                        loadingData={loadingData}
                        edit={edit}
                        paises={paises}
                        goToVerArte={goToVerArte}
                        variablesEnv={variablesEnv}
                        //traducciones
                        translation={translation}
                        language={language}  
                        idiomas={idiomas} 
                      />
                    </CForm>                              
                  </CContainer>
                </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`