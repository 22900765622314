import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CFormFeedback,
  CInputGroup,
  CTooltip,
  CSpinner
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  CInput, 
  Button, 
  ButtonVariant, 
  InputGroupText,
  FormSwitch,
  FormTextarea
} from '../../hooks/styled'
import Header from "../../hooks/Header";
import Select from '../../hooks/CustomSelect'
import CustomInputDate from '../../hooks/CustomInputDate'
import { globalFunctions } from "../../hooks/globalFunctions";
import plusIcon from '../../icons/Icon material-add.svg';
import infoIcon from '../../icons/Icon ionic-md-information-circle-outline.svg'

export default function CardContent({
  handleBack,
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  documentos=[],
  generos,
  paises,
  estadosEnvio,
  estadosFisica,
  admin,
  loadingData,
  registro,
  //traducciones
  translation={},
  language="",
  idiomas=[],
}) {

    //cosas personalizadas para el estilo del select
    const customStylesSelect = {
      fontSize: "12pt",
    }

    const { 
      findObjFromArray,
      propertyTypeOf
    } = globalFunctions();

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-4">
                    <Header
                      title={`${admin ? `${translation[language]?.editar_datos ? translation[language]?.editar_datos : 'Editar datos del Propietario'}` : (registro ? `${translation[language]?.datos_propietario ? translation[language]?.datos_propietario : 'Datos personales del Propietario'}` : `${translation[language]?.informacion_personal ? translation[language]?.informacion_personal : 'Información Personal'}`)}`}
                      isBack={admin ? true : false}
                      handleBack={handleBack}
                    />
                    {loadingData ? (
                      <CRow>
                        <CCol xs={12} className={`d-flex align-items-center mb-5 mt-3 justify-content-center`}>
                          <div className="text-center">
                            <CSpinner size="sm"/>
                              <CSpinner size="sm" variant="grow"/>
                              <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                          </div>                       
                        </CCol>
                      </CRow>
                    ) : (
                      <>
                        {admin && (
                          <div className="d-flex justify-content-end">
                              <span className="mtn-1 me-2">{formFields.boo_activo ? `${translation[language]?.activo ? translation[language]?.activo : 'Activo'}` : `${translation[language]?.inactivo ? translation[language]?.inactivo : 'Inactivo'}`}</span>
                              <FormSwitch>
                                  <CTooltip content={formFields.boo_activo ? `${translation[language]?.hint_si ? translation[language]?.hint_si : "Si"}` : `${translation[language]?.hint_no ? translation[language]?.hint_no : "No"}`}>
                                      <CFormLabel className="switch mb-0">
                                      <CInput  
                                        type="checkbox" 
                                        checked={formFields.boo_activo}
                                        className="switch" 
                                        name="boo_activo"
                                        onChange={handleChange} 
                                      />
                                      <span className="slider round"></span>
                                      </CFormLabel>
                                  </CTooltip>
                              </FormSwitch>
                          </div>
                        )}
                        <CRow className="d-flex justify-content-center py-4 font-size-12">
                          <CCol lg={7} xxl={6}>
                            <CRow className="mb-0">
                              <CCol md={6}>
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.nombres ? translation[language]?.nombres : "Nombres"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="nombre"
                                  value={formFields.nombre}
                                  invalid={formErrors.nombre}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.nombre}</CFormFeedback> 
                              </CCol>
                              <CCol md={6} className="mt-3 mt-md-0">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                > 
                                  {`${translation[language]?.apellidos ? translation[language]?.apellidos : "Apellidos"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="apellidos"
                                  value={formFields.apellidos}
                                  invalid={formErrors.apellidos}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.apellidos}</CFormFeedback> 
                              </CCol>
                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                               
                                  {`${translation[language]?.fecha_nacimiento ? translation[language]?.fecha_nacimiento : "Fecha de nacimiento"}`}
                                </CFormLabel>
                                <CustomInputDate
                                  nombre="fecha_nacimiento"
                                  error={formErrors.fecha_nacimiento}
                                  handleChange={handleChange}
                                  valor={formFields.fecha_nacimiento}
                                  //traducciones
                                  language={findObjFromArray(idiomas, "indice", parseInt(language)) ? findObjFromArray(idiomas, "indice", parseInt(language))?.abreviado : 'es'}
                                  placeholderText={translation[language]?.etiqueta_formato_fecha ? translation[language]?.etiqueta_formato_fecha : "dd/mm/aaaa"}
                                />                     
                                {formErrors.fecha_nacimiento && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.fecha_nacimiento}</CFormFeedback> 
                                )}
                              </CCol>
                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                       
                                  {`${translation[language]?.genero ? translation[language]?.genero : "Género"}`}
                                </CFormLabel>
                                <Select 
                                  options={generos}
                                  nombre="genero"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={false} 
                                  handleChange={handleChange}
                                  valor={formFields.genero} 
                                  error={formErrors.genero} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(generos.length === 0 ? 'undefined' : generos[0][`genero_idioma_${language}`]) === 'Undefined' ? `genero_idioma_1` : `genero_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.genero && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.genero}</CFormFeedback> 
                                )}
                              </CCol>
                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.fecha_registro ? translation[language]?.fecha_registro : "Fecha de registro"}`}
                                </CFormLabel>
                                <CustomInputDate
                                  nombre="fecha_registro"
                                  error={formErrors.fecha_registro}
                                  handleChange={handleChange}
                                  valor={formFields.fecha_registro}
                                  disabled={true}
                                  //traducciones
                                  language={findObjFromArray(idiomas, "indice", parseInt(language)) ? findObjFromArray(idiomas, "indice", parseInt(language))?.abreviado : 'es'}
                                  placeholderText={translation[language]?.etiqueta_formato_fecha ? translation[language]?.etiqueta_formato_fecha : "dd/mm/aaaa"}
                                />                     
                                {formErrors.fecha_registro && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.fecha_registro}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.pais_nacimiento ? translation[language]?.pais_nacimiento : "País de nacimiento"}`}
                                </CFormLabel>
                                <Select 
                                  options={paises}
                                  nombre="pais_nacimiento"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.pais_nacimiento} 
                                  error={formErrors.pais_nacimiento} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.pais_nacimiento && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.pais_nacimiento}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.ciudad_nacimiento ? translation[language]?.ciudad_nacimiento : "Ciudad de nacimiento"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="ciudad_nacimiento"
                                  value={formFields.ciudad_nacimiento}
                                  invalid={formErrors.ciudad_nacimiento}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.ciudad_nacimiento}</CFormFeedback> 
                              </CCol>

                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.tipo_identificacion ? translation[language]?.tipo_identificacion : "Tipo de identificación"}`}
                                </CFormLabel>
                                <Select 
                                  options={documentos}
                                  nombre="tipo_documento"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.tipo_documento} 
                                  error={formErrors.tipo_documento} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(documentos.length === 0 ? 'undefined' : documentos[0][`documento_idioma_${language}`]) === 'Undefined' ? `documento_idioma_1` : `documento_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.tipo_documento && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.tipo_documento}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.numero_identificacion ? translation[language]?.numero_identificacion : "Número de identificación"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="numero_identificacion"
                                  value={formFields.numero_identificacion}
                                  invalid={formErrors.numero_identificacion}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.numero_identificacion}</CFormFeedback> 
                              </CCol>

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.correo ? translation[language]?.correo : "Correo"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="email"
                                  value={formFields.email}
                                  invalid={formErrors.email}
                                  onChange={handleChange}
                                  disabled={registro}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.email}</CFormFeedback>
                              </CCol>

                              <CCol md="6" className="mt-3"> 
                                <CFormLabel 
                                  className="d-block primary-text-color font-size-12" 
                                >              
                                  {`${translation[language]?.telefono ? translation[language]?.telefono : "Teléfono"}`}
                                </CFormLabel>
                                <CRow xs={{ gutter: 2 }}>
                                  <CCol xs="6" sm="4" md="5" lg="4">
                                    <CInputGroup className="has-validation">
                                      <InputGroupText className={`n-bg-default border-end-0 padding-input-small ${formErrors.cod_telefono ? 'invalid-border-color' : 'border-color-gray'}`}>
                                        <img
                                          src={plusIcon}
                                          alt="+"
                                          width="12"
                                          height="12"
                                        />
                                      </InputGroupText>
                                      <CInput
                                        type="text" 
                                        name="cod_telefono" 
                                        size="sm"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 3)}
                                        onChange={handleChange}
                                        value={formFields.cod_telefono} 
                                        invalid={formErrors.cod_telefono} 
                                        className="icon-padding-error" 
                                      />
                                      <CFormFeedback invalid>{formErrorsText.cod_telefono}</CFormFeedback> 
                                    </CInputGroup>                                               
                                  </CCol>
                                  <CCol xs="6" sm="8" md="7" lg="8">
                                    <CInput 
                                        type="text" 
                                        name="telefono"
                                        size="sm"
                                        value={formFields.telefono} 
                                        onChange={handleChange}
                                        invalid={formErrors.telefono} 
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                                        className="icon-padding-error"
                                    />  
                                    <CFormFeedback invalid>{formErrorsText.telefono}</CFormFeedback>                                                  
                                  </CCol>                                                      
                                </CRow>
                                <div 
                                    className="mt-2 d-flex primary-text-color font-size-11" 
                                  >
                                  <img
                                    src={infoIcon}
                                    alt="pin"
                                    width="16"
                                    height="16"
                                    className="me-2 mt-1"
                                  />
                                    {`${translation[language]?.hint_telefono ? translation[language]?.hint_telefono : "Introduce el código de área y el número telefónico."}`}
                                </div>
                              </CCol>

                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.celular ? translation[language]?.celular : "Celular"}`}
                                </CFormLabel>
                                <CRow xs={{ gutter: 2 }}>
                                  <CCol xs="6" sm="4" md="5" lg="4">
                                    <CInputGroup className="has-validation">
                                      <InputGroupText className={`n-bg-default border-end-0 padding-input-small ${formErrors.cod_celular ? 'invalid-border-color' : 'border-color-gray'}`}>
                                        <img
                                          src={plusIcon}
                                          alt="+"
                                          width="12"
                                          height="12"
                                        />
                                      </InputGroupText>
                                      <CInput
                                        type="text" 
                                        name="cod_celular" 
                                        size="sm"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 3)}
                                        onChange={handleChange}
                                        value={formFields.cod_celular} 
                                        invalid={formErrors.cod_celular}  
                                        className="icon-padding-error font-size-12" 
                                      />
                                      <CFormFeedback invalid>{formErrorsText.cod_celular}</CFormFeedback> 
                                    </CInputGroup>                                               
                                  </CCol>
                                  <CCol xs="6" sm="8" md="7" lg="8">
                                    <CInput 
                                      type="text" 
                                      name="celular"
                                      size="sm"
                                      value={formFields.celular} 
                                      onChange={handleChange}
                                      invalid={formErrors.celular} 
                                      onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                                      className="icon-padding-error font-size-12"
                                    />  
                                    <CFormFeedback invalid>{formErrorsText.celular}</CFormFeedback>                                                  
                                  </CCol>                                                             
                                </CRow>
                                <div 
                                    className="mt-2 d-flex primary-text-color font-size-11" 
                                  >
                                  <img
                                    src={infoIcon}
                                    alt="pin"
                                    width="16"
                                    height="16"
                                    className="me-2 mt-1"
                                  />
                                    {`${translation[language]?.hint_celular ? translation[language]?.hint_celular : "Introduce el código de área y el número celular."}`}
                                </div>
                              </CCol>

                              <CCol md={12} className="mt-4">
                                <CFormLabel 
                                  className="d-block primary-text-color font-size-12 fw-bold" 
                                >
                                  {`${translation[language]?.direccion_fisica ? translation[language]?.direccion_fisica : "Dirección física"}`}
                                </CFormLabel>
                              </CCol>

                              <CCol md={6} className="mt-1">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.nombre ? translation[language]?.nombre : "Nombre"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="nombre_dir_fisica"
                                  value={formFields.nombre_dir_fisica}
                                  invalid={formErrors.nombre_dir_fisica}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.nombre_dir_fisica}</CFormFeedback>
                              </CCol>

                              <CCol md={6} className="mt-3 mt-md-1">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.pais ? translation[language]?.pais : "País"}`}
                                </CFormLabel>
                                <Select 
                                  options={paises}
                                  nombre="pais_fisica"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.pais_fisica} 
                                  error={formErrors.pais_fisica} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.pais_fisica && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.pais_fisica}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >                    
                                  {`${translation[language]?.direccion ? translation[language]?.direccion : "Dirección"}`}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="1"
                                  className="font-size-12"
                                  name="direccion_fisica"
                                  value={formFields.direccion_fisica}
                                  invalid={formErrors.direccion_fisica}
                                  onChange={handleChange}
                                />
                                <CFormFeedback invalid>{formErrorsText.direccion_fisica}</CFormFeedback>
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.ciudad ? translation[language]?.ciudad : "Ciudad"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="ciudad_fisica"
                                  value={formFields.ciudad_fisica}
                                  invalid={formErrors.ciudad_fisica}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.ciudad_fisica}</CFormFeedback>
                              </CCol>
                              
                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.estado ? translation[language]?.estado : "Estado"}`}
                                </CFormLabel>
                                <Select 
                                  options={estadosFisica}
                                  nombre="estado_fisica"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.estado_fisica} 
                                  error={formErrors.estado_fisica} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(estadosFisica.length === 0 ? 'undefined' : estadosFisica[0][`estado_idioma_${language}`]) === 'Undefined' ? `estado_idioma_1` : `estado_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.estado_fisica && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.estado_fisica}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.codigo_postal ? translation[language]?.codigo_postal : "Código postal"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="codigo_postal_fisica"
                                  value={formFields.codigo_postal_fisica}
                                  invalid={formErrors.codigo_postal_fisica}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.codigo_postal_fisica}</CFormFeedback>
                              </CCol>

                              <CCol md={12} className="mt-4">
                                <CFormLabel 
                                  className="d-block primary-text-color font-size-12 fw-bold" 
                                >
                                  {`${translation[language]?.direccion_postal_envio_certificado ? translation[language]?.direccion_postal_envio_certificado : "Dirección postal para envío del certificado"}`}
                                </CFormLabel>
                              </CCol>

                              <CCol md={6} className="mt-1">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.nombre ? translation[language]?.nombre : "Nombre"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="nombre_dir_envio"
                                  value={formFields.nombre_dir_envio}
                                  invalid={formErrors.nombre_dir_envio}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.nombre_dir_envio}</CFormFeedback>
                              </CCol>


                              <CCol md={6} className="mt-3 mt-md-1">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.pais ? translation[language]?.pais : "País"}`}
                                </CFormLabel>
                                <Select 
                                  options={paises}
                                  nombre="pais_envio"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.pais_envio} 
                                  error={formErrors.pais_envio} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.pais_envio && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.pais_envio}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.direccion ? translation[language]?.direccion : "Dirección"}`}
                                </CFormLabel>
                                <FormTextarea 
                                  rows="1"
                                  className="font-size-12"
                                  name="direccion_envio"
                                  value={formFields.direccion_envio}
                                  invalid={formErrors.direccion_envio}
                                  onChange={handleChange}
                                />
                                <CFormFeedback invalid>{formErrorsText.direccion_envio}</CFormFeedback>
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.ciudad ? translation[language]?.ciudad : "Ciudad"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="ciudad_envio"
                                  value={formFields.ciudad_envio}
                                  invalid={formErrors.ciudad_envio}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.ciudad_envio}</CFormFeedback>
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.estado ? translation[language]?.estado : "Estado"}`}
                                </CFormLabel>
                                <Select 
                                  options={estadosEnvio}
                                  nombre="estado_envio"
                                  placeholder={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                                  isSearchable={true} 
                                  handleChange={handleChange}
                                  valor={formFields.estado_envio} 
                                  error={formErrors.estado_envio} 
                                  styles={customStylesSelect}
                                  optionLabel={propertyTypeOf(estadosEnvio.length === 0 ? 'undefined' : estadosEnvio[0][`estado_idioma_${language}`]) === 'Undefined' ? `estado_idioma_1` : `estado_idioma_${language}`}
                                  optionValue={"indice"}
                                  noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                                />
                                {formErrors.estado_envio && (
                                  <CFormFeedback invalid className="d-block">{formErrorsText.estado_envio}</CFormFeedback> 
                                )}
                              </CCol>

                              <CCol md={4} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.codigo_postal ? translation[language]?.codigo_postal : "Código postal"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="codigo_postal_envio"
                                  value={formFields.codigo_postal_envio}
                                  invalid={formErrors.codigo_postal_envio}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.codigo_postal_envio}</CFormFeedback>
                              </CCol>

                              {/* <CCol md="6" className="mt-3"> 
                                <CFormLabel 
                                  className="d-block primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.telefono ? translation[language]?.telefono : "Teléfono"}`}
                                </CFormLabel>
                                <CRow xs={{ gutter: 2 }}>
                                  <CCol xs="6" sm="4" md="5" lg="4">
                                    <CInputGroup className="has-validation">
                                      <InputGroupText className={`n-bg-default border-end-0 padding-input-small ${formErrors.cod_telefono ? 'invalid-border-color' : 'border-color-gray'}`}>
                                        <img
                                          src={plusIcon}
                                          alt="+"
                                          width="12"
                                          height="12"
                                        />
                                      </InputGroupText>
                                      <CInput
                                        type="text" 
                                        name="cod_telefono_envio" 
                                        size="sm"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 3)}
                                        onChange={handleChange}
                                        value={formFields.cod_telefono_envio} 
                                        invalid={formErrors.cod_telefono_envio} 
                                        className="icon-padding-error" 
                                      />
                                      <CFormFeedback invalid>{formErrorsText.cod_telefono_envio}</CFormFeedback> 
                                    </CInputGroup>                                               
                                  </CCol>
                                  <CCol xs="6" sm="8" md="7" lg="8">
                                    <CInput 
                                        type="text" 
                                        name="telefono_envio"
                                        size="sm"
                                        value={formFields.telefono_envio} 
                                        onChange={handleChange}
                                        invalid={formErrors.telefono_envio} 
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                                        className="icon-padding-error"
                                    />  
                                    <CFormFeedback invalid>{formErrorsText.telefono_envio}</CFormFeedback>                                                  
                                  </CCol>                                                      
                                </CRow>
                                <div 
                                    className="mt-2 d-flex primary-text-color font-size-11" 
                                  >
                                  <img
                                    src={infoIcon}
                                    alt="pin"
                                    width="16"
                                    height="16"
                                    className="me-2 mt-1"
                                  />
                                    {`${translation[language]?.hint_telefono ? translation[language]?.hint_telefono : "Introduce el código de área y el número telefónico."}`}
                                </div>
                              </CCol>
                              
                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.correo ? translation[language]?.correo : "Correo"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="email_envio"
                                  value={formFields.email_envio}
                                  invalid={formErrors.email_envio}
                                  onChange={handleChange}
                                  // disabled={registro}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.email_envio}</CFormFeedback>
                              </CCol> */}

                            </CRow>
                            <br/>

                            <hr className='mb-0 primary-color'/>
                            <div className="mt-4 d-flex justify-content-end">
                              {(registro || admin) && (
                              <ButtonVariant
                                className="me-3"
                                variant={'outline'}
                                onClick={handleBack}
                              >
                                {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                              </ButtonVariant>
                              )}
                              <Button 
                                type="submit"
                              >
                                {`${translation[language]?.boton_guardar ? translation[language]?.boton_guardar : "Guardar"}`}
                              </Button>                    
                            </div>
                          </CCol>
                        </CRow>
                      </>
                    )}   
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
.form-control:disabled {
  background-color: #F7F5EF;
  border-color: var(--primary-color);
  opacity: 1;
}
`
