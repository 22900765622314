import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CContainer,
} from '@coreui/react'
import styled from 'styled-components'
import CardContent from './CardContent';
import ModalContent from '../../hooks/ModalContent'
import { globalFunctions } from "../../hooks/globalFunctions";
import api from '../../api/api'

export default function MisArtes({
  user,
  admin=false,
  //traducciones
  translation={},
  language="",
}) {
    //Variables
    const navigate = useNavigate();

    //modal
    const showModal = useRef(); //referencia del hijo

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    //funcion global
    const { 
      returnNewObjSort
    } = globalFunctions();

    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null
    
    const [loadingData, setLoadingData] = useState(true);
    const [itemSeleccionado, setItemSeleccionado] = useState(locationData?.oldDataCache?.formFields?.pieza ? locationData?.oldDataCache?.formFields?.pieza : null);

    //arreglo que almacena las artes
    const [data, setData] = useState([]);
    const [inicio, setInicio] = useState(true); //con esta variable validamos si ya cargo por primera vez el form  o no

    //funciones

    //Con esta funcion enviamos de regreso al usuario al dar clic en back
    const handleBack = () => {
      navigate(locationData?.from, { state: { dataCache: locationData?.oldDataCache ? locationData.oldDataCache : null}})
    }

    //Con esta funcion obtenemos las artes de un propietario
    const getData = (newInicio=true) => {
      setLoadingData(true)
      let obj = {
        fk_propietario: !admin ? user?.id_propietario : locationData?.propietario?.id_propietario,
        boo_pagination: false,
        indice_idioma: parseInt(language),
      };
      const newConfig = {...config, params: obj}
      api.get(`/get_propietario_piezas`, newConfig).then(response => {
        let actuales = response.data[0].filter(item => item.vigencia)
        //antiguas y quitamos los duplicados basandonos en codigo_pieza
        const cods = response.data[0].filter(item => !item.vigencia).map(o => o.codigo_pieza)
        const antiguas = response.data[0].filter(item => !item.vigencia).filter(({codigo_pieza}, index) => !cods.includes(codigo_pieza, index + 1)) 
        //ordenamos la data
        let newObj = {
          actuales: returnNewObjSort(actuales, "nombre_arte"),
          antiguas: returnNewObjSort(antiguas, "nombre_arte"),
        }
        setData(JSON.parse(JSON.stringify(newObj)))
        if(newInicio){
          setInicio(false)
        }
        setLoadingData(false)
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            ); 
            break;
          case 404:
            let newObj = {
              actuales: [],
              antiguas: [],
            }
            setData(JSON.parse(JSON.stringify(newObj)))
            setLoadingData(false)
            break
          default:
            break;
        }
      })
    }

    //Con esta funcion enviamos al usuario a ver la ficha del arte
    const goToVerArte = (item) => {
      let dataCache = {
        codigo_pieza: item.codigo_pieza,
        from: !admin ? "/mis-artes" : "/admin-artes-propietario",
        oldDataCache: locationData ? locationData : null
      }
      navigate('/ver-arte', { state: { dataCache: dataCache}})
    }


    //Con esta funcion seleccionamos o desmarcamos un artista
    const handleSelectItem = (e, obj) => {
      setItemSeleccionado(obj)
      const delayDebounceFn = setTimeout(() => {
        let dataCache = {
          ...locationData?.oldDataCache,
          pieza: obj,
        }
        navigate(locationData?.from, { state: { dataCache: dataCache}})
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }

    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(!admin && user?.grupo_actor !== 2){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_propietarios ? translation[language]?.msg_funcion_solo_propietarios : "Función disponible solo para Propietarios."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      if(admin && user?.grupo_actor !== 1){
        showModal?.current?.content?.info(
          1, //accion que tomara 
          `${translation[language]?.msg_funcion_solo_administradores ? translation[language]?.msg_funcion_solo_administradores : "Función disponible solo para Administradores."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 


    //con este efecto llamamos a la funcion para obtener la data la primera vez que inicia el CU
    useEffect(()=> {
      if(!inicio){
        getData(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 


    return (
            <Container>
              <ModalContent
                ref={showModal}
                // acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
              <Screen>
                <CContainer className='px-4'> 
                  <CardContent
                    data={data}
                    loadingData={loadingData}
                    goToVerArte={goToVerArte}
                    locationData={locationData}
                    handleBack={handleBack}
                    handleSelectItem={handleSelectItem}
                    itemSeleccionado={itemSeleccionado}
                    admin={admin}
                    //traducciones
                    translation={translation}
                    language={language}                  
                  />                             
                </CContainer>
              </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`