import api from '../api/api'
export const sendEmail = async (
  email, 
  subject, 
  saludo, 
  mensaje,
  origin,
  allHTML=null,
  logo
  ) => {
    let string = `
    <html>
    <head>
    </head>
    <body>
        <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Helvetica';
        ">
          <div style="">
              <div style="
                padding-left: 1.5rem!important;
                padding-top: 1rem;
                padding-bottom: 1rem;
                margin: auto;
                text-align: center;
              ">
                  <img src="${logo}" alt="logo" style="width: 85px;">
              </div> 
              <div class='w-800' style="
                border-top: 1px solid #B2A169 !important;
                border-bottom: 1px solid #B2A169 !important;
                padding-left: 1.5rem!important;
              ">
                <div style="
                  padding-top: .5rem!important;
                  padding-bottom: .5rem!important;
                  font-size: 12pt;
                  color: #B2A169 !important;
                  "
                >
                  ${saludo}
                </div>
              </div>
              ${mensaje}
              </div>
          </div>
        </div>
      </body>
      </html>
    `
    return api.post("/post_send_email",{
      email_list: email,
      subject: subject,
      content: allHTML ? allHTML : string,
      content_type: 'html',
      origin: origin,
    }
    ).then(response => {
      return {
        response: true,
        data: response.data
      }
    }).catch(err => {
      return {
        response: false,
        status: err?.response?.status
      }
    })
}
