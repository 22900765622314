import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CSpinner,
  CFormLabel
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import Carousel from "../../../hooks/Carousel";
import { globalFunctions } from "../../../hooks/globalFunctions";
import 'react-slideshow-image/dist/styles.css'

export default function CardContent({
  formFields,
  handleBack,
  loadingData,
  locationData,
  paises,
  propietariosPiezas,
  infoPieza,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat,
}) {

    const { 
      findObjFromArray,
      propertyTypeOf,
      titleCase 
    } = globalFunctions();

    const returnNumPieza = () => {
      let cpl = formFields?.cantidad_piezas?.toString().length
      return infoPieza?.numero_pieza?.toString().padStart(cpl, 0)
    }

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-4 pb-4">
                    <Header
                      title={``}
                      isLogo={true}
                      islogoIcon={true}
                      isBack={locationData ? true : false}
                      handleBack={handleBack}
                    />  
                    {loadingData ? (
                      <CRow>
                        <CCol xs={12} className={`d-flex align-items-center mb-5 mt-3 justify-content-center`}>
                          <div className="text-center">
                            <CSpinner size="sm"/>
                              <CSpinner size="sm" variant="grow"/>
                              <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                          </div>                       
                        </CCol>
                      </CRow>
                    ) : (
                      <>
                        <CRow className="d-flex font-size-12">
                          <CCol md="6">
                            <Carousel
                              hiddenContent={true}
                              imagenes={formFields?.imagenes?.filter(img => img.tipo === 1)}
                              typeImage={1}
                              propertyName={"imagenes"}
                              styles={"h-400px"}
                            />
                          </CCol>
                          <CCol md="6">
                            {formFields?.property === "codigo_pieza" && (
                              <div className="mb-5">
                              <CFormLabel 
                                className="text-md-end d-block font-size-default primary-text-color font-size-12" 
                              >
                                <span>{translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : 'Código de la pieza'}: </span> {formFields?.id ? formFields?.id : ""} 
                              </CFormLabel>
                              </div>
                            )}
                            <CRow>
                              <CCol md="12">
                                <CFormLabel 
                                  className="mb-3 fw-bold d-block font-size-default primary-text-color font-size-14" 
                                >
                                  {formFields.nombre ? formFields.nombre : ''}  
                                </CFormLabel>

                                {(formFields?.property === "codigo_pieza" && infoPieza?.numero_pieza) && (
                                  <CFormLabel 
                                    className="my-0 d-block font-size-default primary-text-color font-size-12 text-uppercase" 
                                  >
                                    {`${translation[language]?.pieza ? translation[language]?.pieza : 'Pieza'} ${returnNumPieza()} / ${formFields?.cantidad_piezas}`} 
                                  </CFormLabel>
                                )}
                                <CFormLabel 
                                  className="my-0 d-block font-size-default primary-text-color font-size-12 text-uppercase" 
                                >
                                  {`${translation[language]?.numero_edicion ? translation[language]?.numero_edicion : 'No. Edición'}: ${formFields?.numero_edicion ? formFields?.numero_edicion : ''}`} 
                                </CFormLabel>

                                <CFormLabel 
                                  className="my-0 d-block font-size-default primary-text-color font-size-12 text-uppercase" 
                                >
                                  {formFields.fecha_creacion ? returnLanguageDateFormat(formFields.fecha_creacion, "PPP") : ""} 
                                </CFormLabel>
                                
                                {formFields?.lugar_creacion && (
                                  <CFormLabel 
                                    className="my-0 d-block font-size-default primary-text-color font-size-12 text-uppercase" 
                                  >
                                    {`${formFields?.lugar_creacion ? formFields?.lugar_creacion : ''}`} 
                                  </CFormLabel>
                                )}


                              </CCol>
                            </CRow>

                            <div className="secondary-color mt-3">
                              {formFields.descripcion_arte ? formFields.descripcion_arte : formFields.especificacion_tecnica}
                            </div>

                            {formFields?.serie?.length > 0 && (
                              <>
                                <CFormLabel 
                                  className="mt-3 d-block font-size-default primary-text-color font-size-12" 
                                >
                                  <span className="fw-bold">{translation[language]?.coleccion ? translation[language]?.coleccion : 'Colección'}: </span> {formFields?.serie?.length > 0 ? formFields?.serie[0]?.nombre : `${translation[language]?.hint_na ? translation[language]?.hint_na : 'NA'}`} 
                                </CFormLabel>
                                {/* <CFormLabel 
                                  className="mt-3 fw-bold d-block font-size-default primary-text-color font-size-12" 
                                >
                                  Descripción de la colección
                                </CFormLabel> */}
                                <div className="secondary-color font-size-12">
                                  {formFields?.serie?.length > 0 ? formFields?.serie[0]?.descripcion : ""} 
                                </div>
                              </>
                            )}
                          </CCol>

                          <CCol md={12} className="mt-3">
                            <CFormLabel 
                              className="fw-bold my-0 me-3 mb-2 mb-lg-0 font-size-default primary-text-color font-size-14" 
                            >
                              
                              {translation[language]?.artistas_involucrados ? translation[language]?.artistas_involucrados : 'Artistas involucrados en la creación'}
                            </CFormLabel>
                          </CCol>

                            {formFields?.artistas?.map((item, i) => {
                              return (
                                <CCol md={12} key={i} className={`mt-3 ${formFields?.artistas.length === i+1 ? 'mb-4' : ''}`}>     
                                  <CFormLabel 
                                    className="fw-bold d-block font-size-default primary-text-color font-size-14" 
                                  >
                                    {`${item.nombre_apellido ? item.nombre_apellido : ""} (${item.pais_nacimiento ? (findObjFromArray(paises, "indice", item.pais_nacimiento)[propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`]) : ''}, ${item.ciudad_nacimiento ? `${item.ciudad_nacimiento},` : ""} ${item.fecha_nacimiento ? returnLanguageDateFormat(item.fecha_nacimiento, "yyyy") : ""})`} 
                                  </CFormLabel>
                                  {item.seudonimo && (
                                    <CFormLabel 
                                      className="d-block font-size-default secondary-color font-size-12" 
                                    >
                                      {item?.seudonimo ? item?.seudonimo : ""} 
                                    </CFormLabel>                                    
                                  )}
                                  {item.titulo_experiencia && (
                                    <>
                                      {/* <CFormLabel className="fw-bold d-block">
                                        Títulos y experiencia:                   
                                      </CFormLabel>  */}
                                      <CFormLabel className="secondary-color font-size-12">
                                        {item.titulo_experiencia ? item.titulo_experiencia : ''}                    
                                      </CFormLabel>                                      
                                    </>
                                  )}   
                                  {/* <CFormLabel className="fw-bold d-block">
                                    Brief corto:                   
                                  </CFormLabel>  */}
                                  {/* <CFormLabel>
                                    {item.brief_corto ? item.brief_corto : ''}                    
                                  </CFormLabel>             */}
                                  {item.brief_largo && (
                                    <>
                                      {/* <CFormLabel className="fw-bold d-block">
                                        Brief largo:                   
                                      </CFormLabel>  */}
                                      <CFormLabel className="secondary-color font-size-12">
                                        {item.brief_largo ? item.brief_largo : ''}                    
                                      </CFormLabel>                                      
                                    </>
                                  )}   
                                  
                                </CCol>
                              );
                            })} 

                          {formFields.descripcion_arte && (
                          <CCol md={12} className="mt-3">
                            <CFormLabel 
                              className="fw-bold d-block font-size-default primary-text-color font-size-14" 
                            >                              
                              {translation[language]?.especificaciones_tecnicas ? translation[language]?.especificaciones_tecnicas : 'Especificaciones técnicas'}
                            </CFormLabel>
                            <CFormLabel className="secondary-color font-size-12">
                              {formFields.especificacion_tecnica ? formFields.especificacion_tecnica : ''}                    
                            </CFormLabel> 
                          </CCol>
                          )}

                          <CCol md={12} className="mt-3">
                            <CFormLabel 
                              className="fw-bold d-block font-size-default primary-text-color font-size-14" 
                            >                              
                              {translation[language]?.conservacion_pieza ? translation[language]?.conservacion_pieza : 'Conservación de la pieza'}
                            </CFormLabel>
                            <CFormLabel className="secondary-color font-size-12">
                              {formFields.conservacion ? formFields.conservacion : ''}                    
                            </CFormLabel> 
                          </CCol>

                          <CCol md={12} className="mt-3 mb-2">
                            <CFormLabel 
                              className="fw-bold d-block font-size-default primary-text-color font-size-14" 
                            >                              
                              {translation[language]?.proceso_elaboracion ? translation[language]?.proceso_elaboracion : 'Proceso de elaboración'}
                            </CFormLabel>
                            <CFormLabel className="secondary-color font-size-12">
                              {formFields.proceso_elaboracion ? formFields.proceso_elaboracion : ''}                    
                            </CFormLabel> 
                          </CCol>
                          <CCol md="12">
                            <Carousel
                              imagenes={formFields?.imagenes?.filter(img => img.tipo === 2)}
                              typeImage={2}
                              propertyName={"imagenes"}
                              responsive={true}
                              styles={"h-200px"}
                              hiddenContent={true}
                            />
                          </CCol>

                          <CCol md={12} className="mt-3 mb-2">
                            <CFormLabel 
                              className="fw-bold d-block font-size-default primary-text-color font-size-14" 
                            >                       
                              {translation[language]?.esquema_montaje ? translation[language]?.esquema_montaje : 'Esquema de montaje'}
                            </CFormLabel>
                            <CFormLabel className="secondary-color font-size-12">
                              {formFields.esquema_montaje ? formFields.esquema_montaje : ''}                    
                            </CFormLabel> 
                          </CCol>
                          <CCol md="12">
                            <Carousel
                              imagenes={formFields?.imagenes?.filter(img => img.tipo === 3)}
                              typeImage={3}
                              propertyName={"imagenes"}
                              responsive={true}
                              styles={"h-200px"}
                              hiddenContent={true}
                            />
                          </CCol>
                          {propietariosPiezas.length > 0 && (
                            <CCol md="12">
                              <hr className="primary-color"/>
                              <CFormLabel 
                                className="fw-bold d-block primary-text-color font-size-14" 
                              >
                                {translation[language]?.propietarios ? translation[language]?.propietarios : 'Propietarios'}
                              </CFormLabel>
    
                              {propietariosPiezas?.map((item, i) => {
                                return (
                                    <CFormLabel 
                                      key={i}
                                      className="d-block secondary-color font-size-12" 
                                    > 
                                      {`${item.nombre_propietario ? item.nombre_propietario : ""}${item.apellido_propietario ? ` ${item.apellido_propietario}` : ""}. ${item.fecha_adquisicion ? titleCase(returnLanguageDateFormat(item.fecha_adquisicion, "LLLL yyyy")) : ""} - ${item.vigencia ? `${translation[language]?.etiqueta_presente ? translation[language]?.etiqueta_presente : "presente"}` : `${item.fecha_venta ? returnLanguageDateFormat(item.fecha_venta, "LLLL yyyy") : ""}`} 
                                      `} 
                                    </CFormLabel>
                                );
                              })} 
                            </CCol>
                          )}
                        </CRow>
                      </>
                    )} 
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
.bg-custom{
  border-top: 1px solid var(--primary-color);
  background-color: #F7F5EF;
}
.container-name {
  min-width: 180px;
  max-width: 250px;
  border: 1px solid var(--primary-color);
}
`
