import React , { useState, useRef, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CContainer,
  CForm
} from '@coreui/react'
import styled from 'styled-components'
import FormularioTransferirArte from './FormularioTransferirArte';
import ModalContent from '../../hooks/ModalContent'
import { globalFunctions } from "../../hooks/globalFunctions";
import useStateRef from 'react-usestateref'
import api from '../../api/api'
import { sendEmail } from '../../hooks/sendEmail';

export default function TransferirArte({
  user,
  variablesEnv,
  //traducciones
  translation={},
  language="",
}) {

    //Variables

    //para los endpoints
    const config = {
      headers: {
        Authorization: `Token ${user?.token}`,
      },
    };

    const navigate = useNavigate();

    const showModal = useRef(); //referencia del hijo
    const [acceptAction, setAcceptAction] = useState(null); //accion de aceptar del modal
    const [changes, setChanges] = useState(false); //accion que indica cuando hubo cambios
    const [loadingData, setLoadingData] = useState(true) //variable que indica cuando esta cargando

    //los arreglos de las piezas
    const [piezas, setPiezas] = useState([]) //variable que indicas las piezas que existen
    const [piezaSeleccionada, setPiezaSeleccionada] = useState({}) //variable que indica la pieza seleccionada

    //variable que almacena los valores
    const [formFields, setFormFields, formFieldsRef] = useStateRef({
      nombre: "",
      apellidos: "",
      email: "",
      cod_celular: "",
      celular: "",
      codigo_invitacion: "",
    });

    //variable que almacena los errores
    const [formErrors, setFormErrors] = useState({
      nombre: false,
      apellidos: false,
      email: false,
      cod_celular: false,
      celular: false,
    });

    //variable que almacena el texto de los errores
    const [formErrorsText, setFormErrorsText] = useState({
      nombre: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      apellidos: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      email: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      cod_celular: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      celular: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
    });

    //con esto sabremos cuando esten cargados todos los endpoints
    const [dominiosLoad, setdominiosLoad, dominiosLoadRef] = useStateRef({
      get_data: false,
      get_propietario: false,
    })

    //data almacenada en el location
    const location = useLocation();
    let locationData = location.state ? location.state?.dataCache : null

    //funciones
    
    //Con esto manejamos acciones propias de este CU al dar aceptar en los modals.
    const handleAcceptAction = () => {
      switch (acceptAction) {
        case 1:
          goBack()
          showModal?.current?.resetModal()
          break;
        case 2:
          if(!locationData?.admin){
            putTransferirArte()
          }else{
            putPropietario()
          }    
          break;       
        default:
          showModal?.current?.resetModal()
          break;
      }
    };

    //estas son funciones globales
    const { 
      validationEmailAddress,
      validationIsNumberInt,
      validationCheckText,
      validationIsAlphaNumeric
    } = globalFunctions();
    

    //para guardar los valores introducidos por el usuario.
    const handleChange = (e) => {
      let variable = e?.target ? e.target : e
      const { name, value } = variable;
      updateErrors(name)
      let fieldsObj = { ...formFields, [name]: value};
      setFormFields(JSON.parse(JSON.stringify(fieldsObj)));
      setChanges(true)
    }

    //Con esta función actualizamos los errores del formulario al escribir un nuevo valor.
    const updateErrors = (name) => {
      let errorsObj = {...formErrors, [name]: false}
      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
    }

    //funcion que se utiliza para validar los campos
    const validateFields = () => {
      let errorsObj = formErrors
      let errorsObjText = formErrorsText
      let validState = true


      if(!formFields['nombre']) {
        errorsObj['nombre'] = true
        errorsObjText['nombre'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationCheckText(formFields['nombre'])){
        errorsObj['nombre'] = true
        validState = false      
        errorsObjText['nombre'] = translation[language]?.campo_invalido ? translation[language]?.campo_invalido : "Campo inválido. Introduce letras, acentos y espacio"  
      }

      if(!formFields['apellidos']) {
        errorsObj['apellidos'] = true
        errorsObjText['apellidos'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationCheckText(formFields['apellidos'])){
        errorsObj['apellidos'] = true
        validState = false      
        errorsObjText['apellidos'] = translation[language]?.campo_invalido ? translation[language]?.campo_invalido : "Campo inválido. Introduce letras, acentos y espacio"  
      }

      if(!formFields['email']) {
        errorsObj['email'] = true
        errorsObjText['email'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationEmailAddress(formFields['email'])){
        errorsObj['email'] = true
        errorsObjText['email'] = translation[language]?.correo_invalido ? translation[language]?.correo_invalido : "Correo Inválido"
        validState = false            
      }

      if(!formFields['cod_celular']) {
        errorsObj['cod_celular'] = true
        errorsObjText['cod_celular'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['cod_celular'])){
        errorsObj['cod_celular'] = true
        errorsObjText['cod_celular'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      if(!formFields['celular']) {
        errorsObj['celular'] = true
        errorsObjText['celular'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
        validState = false
      }else if(!validationIsNumberInt(formFields['celular'])){
        errorsObj['celular'] = true
        errorsObjText['celular'] = translation[language]?.campo_numerico ? translation[language]?.campo_numerico : "Campo númerico"
        validState = false            
      }

      if(locationData?.admin){
        if(!formFields['codigo_invitacion']) {
          errorsObj['codigo_invitacion'] = true
          errorsObjText['codigo_invitacion'] = translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio"
          validState = false
        }else if(!validationIsAlphaNumeric(formFields['codigo_invitacion'])){
          errorsObj['codigo_invitacion'] = true
          errorsObjText['codigo_invitacion'] = translation[language]?.campo_alfanum ? translation[language]?.campo_alfanum : "Campo alfanumérico"
          validState = false            
        }
      }


      setFormErrors(JSON.parse(JSON.stringify(errorsObj)));
      setFormErrorsText(JSON.parse(JSON.stringify(errorsObjText)));
      return validState
    }

    //con esto vamos a la vista de mis artes
    const goToMisArtes = () => {
      let dataCache = {
        selection: true,
        from: '/transferir-arte',
        oldDataCache: {
          changes: changes,
          formFields: formFields,
          formErrors: formErrors,
          formErrorsText: formErrorsText,
        },
      }
      navigate('/mis-artes', { state: { dataCache: dataCache}})
    } 


    //retornamos el string HTML content del correo
    const returnStringHTML = (f, fp) => {
      //aqui buscamos los artistas y los convertimos en string
      let artistas = fp.artistas.length === 0 ? null : fp.artistas.length === 1 ? fp.artistas[0].nombre_apellido : fp.artistas.reduce(function (a, b) {
        return (a.nombre_apellido || a) + ", " + b.nombre_apellido}
      )

      let cpl = fp?.cantidad_piezas?.toString().length
      let np = fp?.pieza?.toString().padStart(cpl, 0)

      // //mensaje del email
      const logo  = variablesEnv?.REACT_APP_LOGO_URL
      const AppName  = variablesEnv?.REACT_APP_NAME
      const sin_imagen = variablesEnv?.REACT_APP_SIN_IMAGEN
      const url = variablesEnv?.REACT_APP_WEB_URL
      
      let string = `
      <html>
        <head>
        </head>
        <body>
          <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Helvetica';
          ">
            <div>
              <div style="
                padding-left: 1.5rem!important;
                padding-top: 1rem;
                padding-bottom: 1rem;
                text-align: center;
              ">
                  <img src="${logo} " alt="logo" style="width: 85px;">
              </div> 
              <div style="
                border-top: 1px solid #B2A169 !important;
                border-bottom: 1px solid #B2A169 !important;
                padding-left: 1.5rem!important;
              ">
                <div style="
                  padding-top: .5rem!important;
                  padding-bottom: .5rem!important;
                  font-size: 16px;
                  color: #B2A169 !important;
                  "
                >
                ${translation[language]?.msg_correo_saludo ? translation[language]?.msg_correo_saludo : 'Hola,'} ${f.nombre ? f.nombre : ''} 
                </div>
              </div>
              <div style="display: flex; margin-top: 1em; padding: 5px 1.5rem 0px 1.5rem;">
                <div
                >
                  <div style="
                    height: 160px !important;
                    min-height: 160px!important;
                    max-height: 160px!important;
                    text-align: center;
                    width: 100% !important;
                    background: white;
                    overflow: hidden;                       
                    "
                  >
                    <img 
                      alt="pic" 
                      src="${fp.imagen_arte ? fp.imagen_arte : sin_imagen}" 
                      style="
                      width: 100%;
                      height: 160px!important;
                      min-height: 160px!important;
                      max-height: 160px!important;
                      object-fit: contain;
                      object-position: center center;
                      "
                    />
                  </div>
                </div>
                <div style="
                  color: #B2A169;
                  font-size: 13px;
                  border-left: 1.4px solid #B2A169;
                  margin: 14px 24px 14px 24px;
                  padding-left: 20px;
                ">
                  <p style="color:#B2A169;margin-top: 0px!important;margin-bottom: 5px!important;text-transform: uppercase;">${translation[language]?.pieza ? translation[language]?.pieza : 'Pieza'} ${fp.pieza ? np : ''}  / ${fp.cantidad_piezas ? fp.cantidad_piezas : ''}</p>
                  <p style="color:#B2A169;margin-top: 0px!important;margin-bottom: 5px!important;text-transform: uppercase;">${translation[language]?.titulo ? translation[language]?.titulo : 'Título'}:  ${fp.nombre_arte ? fp.nombre_arte : ''}</p>
                  <p style="color:#B2A169;margin-top: 0px!important;margin-bottom: 5px!important;text-transform: uppercase;">${fp.artistas.length > 0 ? `${translation[language]?.artistas ? translation[language]?.artistas : 'Artistas'}` : `${translation[language]?.artista ? translation[language]?.artista : 'Artista'}`}:  
                    ${artistas ? artistas : `${translation[language]?.msg_error_artista ? translation[language]?.msg_error_artista : 'No posee artista'}`}
                  </p>
                </div>
              </div>
              <div style="white-space: normal;color:#B2A169;font-size:16px;padding: 5px 1.5rem 0px 1.5rem;">    
                <p style="color:#B2A169; margin-top: 0px!important">${translation[language]?.msg_correo_transf_1 ? translation[language]?.msg_correo_transf_1 : 'En tus manos tienes una pieza de Arte Real.'}</p> 

                <p style="margin-top: 20px!important; margin-bottom: 5px!important">${translation[language]?.msg_correo_transf_2 ? translation[language]?.msg_correo_transf_2 : 'Te invitamos a registrarla en nuestra app escaneando, con la cámara de tu'}</p>
                <p style="color:#B2A169; margin-top: 0px!important;margin-bottom: 5px!important">${translation[language]?.msg_correo_transf_3 ? translation[language]?.msg_correo_transf_3 : 'teléfono inteligente, el código Bidi presente en este correo o haciendo clic'}</p> 
                <p style="color:#B2A169; margin-top: 0px!important;margin-bottom: 5px!important"><a href="${url}/registro-arte" style="color:#B2A169;text-decoration:underline !important;">${translation[language]?.msg_correo_transf_4 ? translation[language]?.msg_correo_transf_4 : 'aquí'}</a>. ${translation[language]?.msg_correo_transf_5 ? translation[language]?.msg_correo_transf_5 : 'Este paso es indispensable para hacer llegar a tus manos el'}</p> 
                <p style="color:#B2A169; margin-top: 0px!important; margin-bottom: 5px!important">${translation[language]?.msg_correo_transf_6 ? translation[language]?.msg_correo_transf_6 : 'Certificado de Autenticidad de tu pieza de Arte creada por'} ${AppName},</p>
                <p style="color:#B2A169; margin-top: 0px!important; margin-bottom: 5px!important">${translation[language]?.msg_correo_transf_7 ? translation[language]?.msg_correo_transf_7 : 'que garantiza que es única, auténtica y original.'}</p>

                <p style="margin-top: 20px!important; margin-bottom: 5px!important">${translation[language]?.msg_correo_transf_8 ? translation[language]?.msg_correo_transf_8 : 'Esta app también pone a tu disposición los detalles asociados al Arte: su'}</p>
                <p style="color:#B2A169; margin-top: 0px!important;margin-bottom: 5px!important">${translation[language]?.msg_correo_transf_9 ? translation[language]?.msg_correo_transf_9 : 'intención, elaboración, simbología, artistas, esquema de montaje y'}</p> 
                <p style="color:#B2A169; margin-top: 0px!important">${translation[language]?.msg_correo_transf_10 ? translation[language]?.msg_correo_transf_10 : 'recomendanciones para su conversación.'}</p> 
            
                <p style="color:#B2A169; margin-top: 20px!important;  margin-bottom: 5px!important;">${translation[language]?.msg_correo_transf_11 ? translation[language]?.msg_correo_transf_11 : '¡Bienvenido y gracias por formar parte!'}</p>
                <hr style="
                    margin-top: 15px !important;
                    color: #B2A169 !important;
                    border: 0 !important;
                    border-top: 1px solid !important;
                    opacity: .25 !important;
                "
                />
              </div>
              <div style="display: flex; margin-top: 1em; padding: 5px 1.5rem 0px 1.5rem;">
                <div
                >
                  <div style="
                    height: 100px !important;
                    min-height: 100px!important;
                    max-height: 100px!important;
                    text-align: center;
                    width: 100% !important;
                    background: white;
                    overflow: hidden;                       
                    "
                  >
                    <img 
                      alt="pic" 
                      src="{}" 
                      style="
                        width: 100%;
                        height: 100px!important;
                        min-height: 100px!important;
                        max-height: 100px!important;
                        object-fit: contain;
                        object-position: center center;
                      "
                    />
                  </div>
                  <div
                    style="
                      max-width: 250px;
                      border-top: 1px solid #B2A169;
                      background-color: #F7F5EF;
                      text-align: center;
                      color: #b2a169;                 
                    "                      
                  >
                    ${f.codigo_invitacion ? f.codigo_invitacion : ''}
                  </div>
                </div>
                <div style="
                  color: #B2A169;
                  font-size: 16px;
                  margin-left: 10px;
                ">
                  <p style="color:#B2A169;margin-top: 5px!important;margin-bottom: 5px!important;">${translation[language]?.msg_correo_transf_12 ? translation[language]?.msg_correo_transf_12 : 'Para autenticarte, necesitarás ingresar el código de invitación'}</p>
                  <p style="color:#B2A169;margin-top: 0px!important;margin-bottom: 5px!important;">${translation[language]?.msg_correo_transf_13 ? translation[language]?.msg_correo_transf_13 : 'presente en este correo y el código del Arte, ubicado en el sello'}</p>
                  <p style="color:#B2A169;margin-top: 0px!important;margin-bottom: 5px!important;">${translation[language]?.msg_correo_transf_14 ? translation[language]?.msg_correo_transf_14 : 'holográfico en la parte posterior de tu obra.'}</p>
                </div>
              </div>
              <div style="white-space: normal;color:#B2A169;font-size:16px;padding: 5px 1.5rem 0px 1.5rem;">    
                <p style="color:#B2A169;margin-bottom: 0px!important; margin-top: 3rem !important">${AppName}</p>
              </div>
            </div>
          </div>
        </body>
      </html>
      `
      //esta es el retorno
      return string
    }


    //para transferir un arte
    const putTransferirArte = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
      let f = formFields
      let fp = piezaSeleccionada

      //lo que enviaremos en el put
      let newObj = {
        nombre_remitente: fp.nombre_propietario,
        apellidos_remitente: fp.apellido_propietario,
        codigo_pieza: fp.codigo_pieza,
        codigo_invitacion: f.codigo_invitacion,
        codigo_invitacion_generado: f.codigo_invitacion,
        email_remitente: fp.email,
        nombre_receptor: f.nombre ? f.nombre : "",
        apellidos_receptor: f.apellidos ? f.apellidos : "",
        email_receptor: f.email ? f.email : "",
        telf_receptor: `+${f.cod_celular}-${f.celular}`,
        subject: `${translation[language]?.titulo_transferencia_arte ? translation[language]?.titulo_transferencia_arte : "Transferencia de Arte"}`,
        content: returnStringHTML(f, fp)
      }

      //llamamos al endpoint
      api.put("/put_transferir_arte", newObj, config).then(response => {
        setForm() 
        showModal?.current?.content?.success(
          null, //accion que tomara 
          `${translation[language]?.msg_pieza_arte_transferida ? translation[language]?.msg_pieza_arte_transferida : "¡La pieza de Arte se transfirió con éxito!"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );   
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 420: 
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_correo_receptor ? translation[language]?.msg_error_correo_receptor : "El correo receptor no puede ser el mismo del emisor. Por favor, ingresa uno diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          case 421: 
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_existe_email ? translation[language]?.msg_existe_email : "La dirección de correo electrónico introducida pertenece a un administrador.  Por favor, ingresa una diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //con esto seteamos el formulario y lo limpiamos
    const setForm = () => {
      //limpiamos el form
      setFormFields({
        ...formFields,
        nombre: "",
        apellidos: "",
        email: "",
        cod_celular: "",
        celular: "",
        codigo_invitacion: "",
      })
      //limpiamos los errores
      setFormErrors({
        ...formErrors,
        nombre: false,
        apellidos: false,
        email: false,
        cod_celular: false,
        celular: false,
        codigo_invitacion: false,
      })
      //limpiamos el texto de los errores
      setFormErrorsText({
        ...formErrorsText,
        nombre: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        apellidos: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        email: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        cod_celular: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        celular: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
        codigo_invitacion: translation[language]?.obligatorio ? translation[language]?.obligatorio : "Obligatorio",
      })
      //limpiamos el changes
      setChanges(false)
      //seteamos las piezas
      setPiezas([])
      setPiezaSeleccionada({})
    }

    //Con esta función preguntamos al usuario si esta seguro de cancelar los cambios.
    const discardChanges = () => {
      if(changes){
        setAcceptAction(1)
        showModal?.current?.content?.info(
          3, //accion que tomara 
          `${translation[language]?.msg_cancelar_transferencia ? translation[language]?.msg_cancelar_transferencia : "¿Estás seguro de que quieres cancelar la transferencia en curso?"}`, //mensaje principal
          null, //mensaje secundario
          true, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }else{
        goBack()
      }
    }

    //para atajar la acttion en el boton atras
    const goBack = () => {
      //si es un admin volvemos de donde vino (cu1 tabla propietarios o cu2 crud artes)
      if(locationData?.admin){
        navigate(locationData?.from, { state: { dataCache: locationData?.oldDataCache ? locationData.oldDataCache : null}})
      }else{
        setForm()
      }
    }

    //para confirmar la transferencia
    const confirmTrans = () => {
      setAcceptAction(2)
      showModal?.current?.content?.info(
        3, //accion que tomara 
        `${translation[language]?.msg_transferir_pieza_arte ? translation[language]?.msg_transferir_pieza_arte : "¿Estás seguro de transferir la pieza de Arte?"}`, //mensaje principal
        null, //mensaje secundario
        true, //indica si hay o no boton de cancelar
        `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
      );
    }

    //con esto cargamos la data al ir a CU4.1 y volver a CU4.2
    const loadData = () => {

      //colocamos la pieza en un arreglo
      let arrayPiezas = []
      let pieza = locationData?.pieza ? locationData?.pieza : locationData?.formFields?.pieza ?  locationData?.formFields?.pieza : {}
      if(pieza.id_pieza){
        arrayPiezas.push(pieza)
      }
      let newData = {
        ...locationData.formFields,
        codigo_invitacion: pieza.codigo_invitacion ? pieza.codigo_invitacion : ""
      }
      //Seteamos las variables de estado
      setChanges(locationData.changes)
      setFormFields(newData)
      setFormErrorsText(formErrorsText)
      setFormErrors(locationData.formErrors)
      setPiezaSeleccionada(pieza)
      setPiezas(arrayPiezas)
      setdominiosLoad({...dominiosLoadRef.current, get_data: true, get_propietario: true}) 
    }

    //funcion al dar clic al boton aceptar
    const handleSubmit = async e => {  
      e.preventDefault()
      if (!validateFields()) {
        return       
      }
      if(!piezaSeleccionada?.id_pieza){
        showModal?.current?.content?.info(
          null, //accion que tomara 
          `${translation[language]?.msg_error_seleccionar_pieza ? translation[language]?.msg_error_seleccionar_pieza : "Por favor, selecciona la pieza de Arte que quieres transferir."}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return
      }
      confirmTrans()
    }

    //Funciones que se utilizan cuando viene desde un ADM

    //con esto obtenemos la info del propietario
    const getPropietario = () => {
      let obj = {
        id_propietario: locationData?.fk_propietario,
      };
      const newConfig = {...config, params: obj}
      api.get(`/get_propietarios`, newConfig).then(response => {
        let f = response.data.propietarios[0]
        let newObj = {
          ...formFieldsRef.current,
          nombre: f.nombre ? f.nombre : "",
          apellidos: f.apellidos ? f.apellidos : "",
          cod_celular: (f.celular && f?.celular.split('-')[0]) ? f?.celular.split('-')[0].replace("+", "") : "",
          celular: (f.celular && f?.celular.split('-')[1]) ? f?.celular.split('-')[1] : "",
          email: f.email ? f.email : "",
        }
        setFormFields(JSON.parse(JSON.stringify(newObj)));
        setdominiosLoad({...dominiosLoadRef.current, get_propietario: true})   
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          default:
            break;
        }
      })
    }

    //Con esta funcion obtenemos las artes de un propietario
    const getPiezas = () => {
      let obj = {
        fk_propietario: locationData?.fk_propietario,
        id_propietario_pieza: locationData?.id_propietario_pieza ? locationData?.id_propietario_pieza : null,
        indice_idioma: parseInt(language),
        boo_pagination: false,
        vigencia: true,
      };
      const newConfig = {...config, params: obj}
      api.get(`/get_propietario_piezas`, newConfig).then(response => {
        //ordenamos la data
        response.data[0].sort(function(a, b) {
          let textA = a.nombre_arte.toUpperCase();
          let textB = b.nombre_arte.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        let pieza = response.data[0].length > 1 ? {} : response.data[0][0]
        setFormFields({...formFieldsRef.current, codigo_invitacion: pieza?.codigo_invitacion ? pieza?.codigo_invitacion : ""});
        setPiezaSeleccionada(pieza)
        setPiezas(response.data[0])
        setdominiosLoad({...dominiosLoadRef.current, get_data: true}) 
      }).catch(err => {
        switch (err?.response?.status) {
          case 403:
            showModal?.current?.content?.info(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break;
          default:
            break;
        }
      })
    }

    //con esta función actualizamos un propietario
    const putPropietario = () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...')
      let f = formFields
      let fp = piezaSeleccionada
      
      let newObj = {
        id_propietario: locationData?.fk_propietario,
        nombre: f.nombre,
        apellidos: f.apellidos,
        celular: `+${f.cod_celular}-${f.celular}`,
        email: f.email,
        codigo_invitacion: f.codigo_invitacion,
        id_propietario_pieza: fp.id_propietario_pieza,
      }
      api.put("/put_propietario", newObj, config).then(response => {
        postEmail()
      }).catch(error => { 
        switch (error?.response?.status) { 
          case 403:
          case 405:
            showModal?.current?.content?.authentication(
              1, //accion que tomara 
              `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break
          case 409:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_correo_ya_registrado ? translation[language]?.msg_correo_ya_registrado : "El correo electrónico ya se encuentra registrado, por favor intente nuevamente con otro."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break 
          case 420:
            showModal?.current?.content?.info(
              null, //accion que tomara 
              `${translation[language]?.msg_error_codigo_inv ? translation[language]?.msg_error_codigo_inv : "El código de invitación ya se encuentra registrado. Por favor, ingresa uno diferente."}`, //mensaje principal
              null, //mensaje secundario
              false, //indica si hay o no boton de cancelar
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
              `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
            );
            break 
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      })
    }

    //para enviar el correo
    const postEmail = async () => {
      showModal?.current?.content?.loading(translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...');
      let f = formFields
      let fp = piezaSeleccionada

      const email = [f.email];
      const logo  = variablesEnv?.REACT_APP_LOGO_URL
      const subject = `${translation[language]?.titulo_transferencia_arte ? translation[language]?.titulo_transferencia_arte : "Transferencia de Arte"}`;
      const origin = "transfer";
      let mensaje = returnStringHTML(f, fp)
      //Función que envía el correo
      let result = await sendEmail(email, subject, null, null, origin, mensaje, logo)
      if(result.response){
        setAcceptAction(1)
        showModal?.current?.content?.success(
          3, //accion que tomara 
          `${translation[language]?.msg_pieza_arte_transferida ? translation[language]?.msg_pieza_arte_transferida : "¡La pieza de Arte se transfirió con éxito!"}`, //mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        ); 
      }else{
        switch (result.status) {
          default:
            showModal?.current?.content?.errorDataBase(
              null, //accion que tomara 
              `${translation[language]?.msg_error_base_datos ? translation[language]?.msg_error_base_datos : "Error al conectar con la base de datos."}`, //mensaje,
              `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
            );
            break;
        }
      }
    }

    //Con esta funcion seleccionamos o desmarcamos una pieza
    const handleSelectItem = (e, obj) => {
      let newObj = e.target.checked ? obj : null
      let cod = newObj?.codigo_invitacion ? newObj?.codigo_invitacion : ""
      setFormFields(JSON.parse(JSON.stringify({...formFields, codigo_invitacion: cod})));
      setPiezaSeleccionada(newObj)
    }

    //Validacion
    useEffect(()=> {
      window.scrollTo(0, 0);
      if(!user){
        showModal?.current?.content?.authentication(
          1, //accion que tomara 
          `${translation[language]?.msg_autorizacion_requerida ? translation[language]?.msg_autorizacion_requerida : "Autorización requerida, por favor inicie sesión."}`, // mensaje principal
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
        );
        return;
      }
      if(user && user?.grupo_actor === 1 && !locationData){
        showModal?.current?.content?.info(
          5, //accion que tomara 
          `${translation[language]?.msg_seleccionar_pieza ? translation[language]?.msg_seleccionar_pieza : "Seleccione una pieza para transferir"}`, // mensaje principal
          null, //mensaje secundario
          false, //indica si hay o no boton de cancelar
          `${translation[language]?.boton_aceptar ? translation[language]?.boton_aceptar : "Aceptar"}`, //texto de boton de aceptar
          `${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}` //texto de boton de cancelar
        );
        return;
      }
      //accion 1 significa que viene del CRUD piezas, accion 2 significa que viene del CRUD propietarios. Accion null significa que es un propietario
      let action = locationData?.action ? locationData?.action : null
      //cargamos la data que ya tenia previamente + el nuevo arte seleccionado
      if(locationData && !action){
        loadData()
      //flujo cuando es ADM
      }else if(locationData && action){
        getPiezas()
        getPropietario()
      }else{
        setdominiosLoad({...dominiosLoadRef.current, get_data: true, get_propietario: true}) 
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 


    //con este efecto iniciamos el CU cuando todo esta cargado
    useEffect(() => {
      if (Object.values(dominiosLoadRef.current).every(value => value === true)) {
          setLoadingData(false)
      }
    },[dominiosLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    //Si hay al menos un error en el formulario llamamos nuevamente a la funcion que coloca los errores para traducirlos
    useEffect(()=> {
      if(Object.values(formErrors).some(value => value)){
        validateFields()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]) 

    return (
            <Container> 
              <ModalContent
                ref={showModal}
                acceptAction={handleAcceptAction}
                // modalContentInside={textdivContent}
              />
                <Screen>
                  <CContainer className='px-4'> 
                    <CForm 
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <FormularioTransferirArte
                        formFields={formFields}
                        formErrors={formErrors}
                        formErrorsText={formErrorsText}
                        handleChange={handleChange}
                        loadingData={loadingData}
                        goToMisArtes={goToMisArtes}
                        discardChanges={discardChanges}
                        piezas={piezas}
                        handleSelectItem={handleSelectItem}
                        locationData={locationData}
                        piezaSeleccionada={piezaSeleccionada}
                        //traducciones
                        translation={translation}
                        language={language}                      
                      />
                    </CForm>                              
                  </CContainer>
                </Screen>
            </Container>
    )
}


const Container = styled.div`
  margin: 50px auto;
`
const Screen = styled.div`
`