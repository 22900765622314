import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CFormFeedback,
  CButton
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  CInput, 
  Button, 
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'


export default function CardContent({
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  user,
  openModalHelp,
  //traducciones
  translation={},
  language="",
  changeLanguage,
  idiomas=[],
}) {

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-4">
                    <Header
                      title={`${translation[language]?.registro_artes ? translation[language]?.registro_artes : 'Registro de Artes'}`}
                      isBack={false}
                      isLogo={true}
                      showDropLanguage={true}
                      changeLanguage={changeLanguage}
                      language={language}
                      idiomas={idiomas}
                    />   
                    <CRow className="font-size-12 justify-content-center">
                    <CCol lg={9}>
                      <div 
                          className="d-flex primary-text-color font-size-12" 
                        >
                        <img
                          src={infoIcon}
                          alt="pin"
                          width="16"
                          height="16"
                          className="me-2 mt-1"
                        />
                        <div>         
                          {translation[language]?.hint_registro_artes ? translation[language]?.hint_registro_artes : 'Para continuar con el registro o consultar tus Artes, ingresa el Código de Arte ubicado en el sello holográfico en la parte posterior de tu obra y el Código de Invitación ubicado en la tarjeta que acompaña tu pieza.'}  <CButton 
                              color="link" 
                              className="primary-text-color text-underline p-0"
                              onClick={openModalHelp}
                            >
                              {translation[language]?.ayuda_registro_artes ? translation[language]?.ayuda_registro_artes : 'Ayuda'}
                            </CButton>
                          </div>
                          
                      </div> 
                    </CCol>
                    </CRow>
                    <CRow className="d-flex justify-content-center py-4 font-size-12">
                      <CCol lg={7} xl={6}>
                        <CRow className="mb-0">
                          <CCol md={6} className="mt-3">
                            <CFormLabel 
                              className="d-block font-size-default primary-text-color font-size-12" 
                            >
                              {translation[language]?.codigo_arte ? translation[language]?.codigo_arte : 'Código del Arte'}
                            </CFormLabel>
                            <CInput
                              size="sm"
                              type="text"
                              name="codigo_pieza"
                              value={formFields.codigo_pieza}
                              invalid={formErrors.codigo_pieza}
                              onChange={handleChange}
                              onInput={(e) => e.target.value = e.target.value.replace(/ /g, "")}
                              className="font-size-12"
                            />
                            <CFormFeedback invalid>{formErrorsText.codigo_pieza}</CFormFeedback> 
                          </CCol>
                          <CCol md={6} className="mt-3">
                            <CFormLabel 
                              className="d-block font-size-default primary-text-color font-size-12" 
                            >
                              {translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : 'Código de invitación'}
                            </CFormLabel>
                            <CInput
                              size="sm"
                              type="text"
                              name="codigo_invitacion"
                              value={formFields.codigo_invitacion}
                              invalid={formErrors.codigo_invitacion}
                              onChange={handleChange}
                              onInput={(e) => e.target.value = e.target.value.replace(/ /g, "")}
                              className="font-size-12"
                            />
                            <CFormFeedback invalid>{formErrorsText.codigo_invitacion}</CFormFeedback>
                          </CCol>
                          {!user && (
                          <CCol md={12} className="mt-3">
                            <CFormLabel 
                              className="d-block font-size-default primary-text-color font-size-12" 
                            >
                              {translation[language]?.correo ? translation[language]?.correo : 'Correo'}
                            </CFormLabel>
                            <CInput
                              size="sm"
                              type="text"
                              name="email"
                              value={formFields.email}
                              invalid={formErrors.email}
                              onChange={handleChange}
                              className="font-size-12"
                            />
                            <CFormFeedback invalid>{formErrorsText.email}</CFormFeedback>
                          </CCol>
                          )}
                        </CRow>
                        <br/>
                        <hr className='mb-0 primary-color'/>
                        <div className="mt-4 d-flex justify-content-start">
                          <Button 
                            type="submit"
                          >
                            {translation[language]?.boton_ingresar ? translation[language]?.boton_ingresar : 'Ingresar'}
                          </Button>                    
                        </div>
                      </CCol>
                    </CRow>
                  </CContainer>
                </CCardBody>  
              </Card>
             
            </Content>
    )
}
const Content = styled.div`
`
