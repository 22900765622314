import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CFormLabel,
  CFormFeedback,
  CInputGroup,
  CTooltip,
  CSpinner,
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  CInput, 
  Button, 
  ButtonVariant,
  InputGroupText,
  FormCheck
} from '../../hooks/styled'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Header from "../../hooks/Header";
import infoIcon from '../../icons/Icon ionic-md-information-circle-outline.svg'
import plusIcon from '../../icons/Icon material-add.svg';

export default function FormularioTransferirArte({
  formFields,
  formErrors,
  formErrorsText,
  handleChange,
  loadingData,
  goToMisArtes,
  discardChanges,
  handleSelectItem,
  piezaSeleccionada,
  piezas,
  locationData,
  //traducciones
  translation={},
  language="",
}) {

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-4">
                    <Header
                      title={`${translation[language]?.transferir_arte ? translation[language]?.transferir_arte : 'Transferir Arte'}`}
                      isBack={locationData?.admin ? true : false}
                      handleBack={discardChanges}
                    />   
                    {loadingData ? (
                      <CRow>
                        <CCol xs={12} className={`d-flex align-items-center mb-5 mt-3 justify-content-center`}>
                          <div className="text-center">
                            <CSpinner size="sm"/>
                              <CSpinner size="sm" variant="grow"/>
                              <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                          </div>                       
                        </CCol>
                      </CRow>
                    ) : (
                      <>
                        <CRow className="font-size-12">
                          <CCol lg={12}>
                            <div 
                                className="d-flex primary-text-color font-size-12" 
                              >
                              <img
                                src={infoIcon}
                                alt="pin"
                                width="16"
                                height="16"
                                className="me-2 mt-1"
                              />
                                {!locationData?.admin ? ` ${translation[language]?.hint_transferir_arte ? translation[language]?.hint_transferir_arte : 'Selecciona la pieza de Arte que deseas transferir e ingresa los datos del nuevo Propietario.'}` : 
                                (piezas.length > 1 ? ` ${translation[language]?.hint_transferir_arte2 ? translation[language]?.hint_transferir_arte2 : 'Selecciona la pieza de Arte para enviar la invitación al correo del nuevo Propietario.'}`
                                : ` ${translation[language]?.hint_transferir_arte3 ? translation[language]?.hint_transferir_arte3 : 'Valida o actualiza los datos del nuevo Propietario para enviar la invitación.'}`
                                )}                            
                            </div> 
                          </CCol>
                        </CRow>
                        <CRow className="d-flex justify-content-center py-4 font-size-12">
                          <CCol lg={7} xl={7}>
                            <CCol md={12} className="mt-3">
                              <CRow>
                                <CCol md={6} className="d-md-flex align-items-center">
                                  <CFormLabel 
                                    className="my-0 me-3 mb-2 mb-md-0 font-size-default primary-text-color font-size-12" 
                                  >
                                    {/* {`${piezas.length > 0 ? 'Pieza' : 'Piezas'}`} de Arte {locationData?.admin ? '' : 'a transferir'} */}
                                    {!locationData?.admin ? (
                                      piezas.length > 1 ? ` ${translation[language]?.piezas_artes_transferir ? translation[language]?.piezas_artes_transferir : 'Piezas de Arte a transferir'}`
                                      : ` ${translation[language]?.pieza_artes_transferir ? translation[language]?.pieza_artes_transferir : 'Pieza de Arte a transferir'}`                                      
                                    ) : 
                                    (piezas.length > 1 ? ` ${translation[language]?.piezas_arte ? translation[language]?.piezas_arte : 'Piezas de Arte'}`
                                    : ` ${translation[language]?.pieza_arte ? translation[language]?.pieza_arte : 'Pieza de Arte'}`
                                    )} 
                                  </CFormLabel> 
                                </CCol>
                                {!locationData?.admin && (
                                  <CCol md={6} className="d-md-flex align-items-center justify-content-md-end">
                                    <CTooltip content={`${piezas.length > 0 ? `${translation[language]?.hint_boton_cambiar_pieza ? translation[language]?.hint_boton_cambiar_pieza : 'Haga clic para cambiar la pieza de Arte a transferir'}` : `${translation[language]?.hint_boton_seleccionar_pieza ? translation[language]?.hint_boton_seleccionar_pieza : 'Haga clic para seleccionar la pieza de Arte a transferir'}`}`}>
                                      <Button 
                                        type="button"
                                        className="font-size-12" 
                                        onClick={goToMisArtes}
                                      >
                                        {`${piezas.length > 0 ? `${translation[language]?.boton_cambiar_pieza ? translation[language]?.boton_cambiar_pieza : 'Cambiar pieza'}` : `${translation[language]?.boton_seleccionar_pieza ? translation[language]?.boton_seleccionar_pieza : 'Seleccionar pieza'}`}`}
                                      </Button> 
                                    </CTooltip>                               
                                  </CCol>
                                )}
                              </CRow>                      
                            </CCol>
                            {piezas.length > 0 && (
                              <>
                                <hr className='mb-1 primary-color'/>
                                <div className="d-flex flex-wrap">
                                  {piezas?.map((item, i) => {
                                    return (
                                      <div key={i} className="mt-3 container-pic me-4">
                                        <div className="border border-color">
                                          {piezas.length > 1 && (
                                            <>
                                              <div className="text-end me-1 mb-1">
                                                <FormCheck 
                                                  className="" 
                                                  label=""
                                                  name="select"
                                                  checked={piezaSeleccionada?.id_pieza === item.id_pieza}
                                                  onChange={(e) => handleSelectItem(e, item)}
                                                /> 
                                              </div>
                                            </>
                                          )}
                                          <div className={`${item.imagen_arte.length > 0 ? 'box3 big3' : 'box5 big5'}`}>
                                            <LazyLoadImage
                                              alt={"pic"}
                                              effect="blur" 
                                              className='foto'
                                              src={item?.imagen_arte?.length > 0 ? item?.imagen_arte : "/sin-imagen.png"}
                                              placeholderSrc={item?.imagen_arte?.length > 0  ? item?.imagen_arte : "/sin-imagen.png"}
                                            /> 
                                          </div> 
                                        </div>   
                                        <div className="mt-1 fw-bold font-size-11">{item?.nombre_arte ? item?.nombre_arte : ''} - {item.codigo_pieza ? item.codigo_pieza : ""}</div>                      
                                      </div> 
                                    );
                                  })}
                                </div>
                              </>
                            )}                       
                            <hr className='mb-1 primary-color'/>
                            <CRow className="mb-0">
                              <CCol md={12} className="mt-3">
                                <CFormLabel 
                                  className="fw-bold d-block font-size-default primary-text-color font-size-12" 
                                >   
                                {`${translation[language]?.datos_nuevo_propietario ? translation[language]?.datos_nuevo_propietario : "Datos del nuevo Propietario"}`}
                                </CFormLabel>
                              </CCol>
                              <CCol md={6} className="mt-2">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.nombres ? translation[language]?.nombres : "Nombres"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="nombre"
                                  value={formFields.nombre}
                                  invalid={formErrors.nombre}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.nombre}</CFormFeedback> 
                              </CCol>
                              <CCol md={6} className="mt-2">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.apellidos ? translation[language]?.apellidos : "Apellidos"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="apellidos"
                                  value={formFields.apellidos}
                                  invalid={formErrors.apellidos}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.apellidos}</CFormFeedback> 
                              </CCol>
                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block font-size-default primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.correo ? translation[language]?.correo : "Correo"}`}
                                </CFormLabel>
                                <CInput
                                  size="sm"
                                  type="text"
                                  name="email"
                                  value={formFields.email}
                                  invalid={formErrors.email}
                                  onChange={handleChange}
                                  className="font-size-12"
                                />
                                <CFormFeedback invalid>{formErrorsText.email}</CFormFeedback> 
                              </CCol>
                              <CCol md={6} className="mt-3">
                                <CFormLabel 
                                  className="d-block primary-text-color font-size-12" 
                                >
                                  {`${translation[language]?.celular ? translation[language]?.celular : "Celular"}`}
                                </CFormLabel>
                                <CRow xs={{ gutter: 2 }}>
                                  <CCol xs="6" sm="4" md="5" lg="4">
                                    <CInputGroup className="has-validation">
                                      <InputGroupText className={`n-bg-default border-end-0 padding-input-small ${formErrors.cod_celular ? 'invalid-border-color' : 'border-color-gray'}`}>
                                        <img
                                          src={plusIcon}
                                          alt="+"
                                          width="12"
                                          height="12"
                                        />
                                      </InputGroupText>
                                      <CInput
                                        type="text" 
                                        name="cod_celular" 
                                        size="sm"
                                        onInput={(e) => e.target.value = e.target.value.slice(0, 3)}
                                        onChange={handleChange}
                                        value={formFields.cod_celular} 
                                        invalid={formErrors.cod_celular}  
                                        className="icon-padding-error font-size-12" 
                                      />
                                      <CFormFeedback invalid>{formErrorsText.cod_celular}</CFormFeedback> 
                                    </CInputGroup>                                               
                                  </CCol>
                                  <CCol xs="6" sm="8" md="7" lg="8">
                                    <CInput 
                                      type="text" 
                                      name="celular"
                                      size="sm"
                                      value={formFields.celular} 
                                      onChange={handleChange}
                                      invalid={formErrors.celular} 
                                      onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                                      className="icon-padding-error font-size-12"
                                    />  
                                    <CFormFeedback invalid>{formErrorsText.celular}</CFormFeedback>                                                  
                                  </CCol>                                                             
                                </CRow>
                                <div 
                                    className="mt-2 d-flex primary-text-color font-size-11" 
                                  >
                                  <img
                                    src={infoIcon}
                                    alt="pin"
                                    width="16"
                                    height="16"
                                    className="me-2 mt-1"
                                  />
                                    {`${translation[language]?.hint_celular ? translation[language]?.hint_celular : "Introduce el código de área y el número celular."}`}
                                </div>
                              </CCol>
                              {locationData?.admin && (
                                <CCol md={6} className="mt-1">
                                  <CFormLabel 
                                    className="d-block font-size-default primary-text-color font-size-12" 
                                  >
                                    
                                    {`${translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : "Código de invitación"}`}
                                  </CFormLabel>
                                  <CInput
                                    size="sm"
                                    type="text"
                                    name="codigo_invitacion"
                                    value={formFields.codigo_invitacion}
                                    invalid={formErrors.codigo_invitacion}
                                    onChange={handleChange}
                                    disabled={!formFields.codigo_invitacion ? true : false}
                                    className="font-size-12"
                                  />
                                  <CFormFeedback invalid>{formErrorsText.codigo_invitacion}</CFormFeedback> 
                                </CCol>
                              )}
                            </CRow>
                            <br/>
                            <hr className='mb-0 primary-color'/>
                              <div className="mt-4 d-sm-flex justify-content-end">
                                <ButtonVariant
                                  className="me-3 mb-3 mb-sm-0"
                                  variant={'outline'}
                                  onClick={discardChanges}
                                >
                                  {`${translation[language]?.boton_cancelar ? translation[language]?.boton_cancelar : "Cancelar"}`}
                                </ButtonVariant>
                                <Button 
                                  type="submit"
                                  className="mb-3 mb-sm-0"
                                >
                                  {!locationData?.admin ? `${`${translation[language]?.boton_transferir ? translation[language]?.boton_transferir : "Transferir"}`}` : `${`${translation[language]?.enviar_invitacion ? translation[language]?.enviar_invitacion : "Enviar Invitación"}`}`}
                                </Button>                    
                              </div>
                          </CCol>
                        </CRow>
                      </>
                    )}
                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
  .container-pic {
    max-width: 150px;
    min-width: 150px;
  }
  .form-control:disabled {
    background-color: #F7F5EF;
    border-color: var(--primary-color);
    opacity: 1;
  }
`
