import React, {useState} from 'react'
import styled from 'styled-components'
import iconGoUp from '../icons/Ir arriba.svg';

export default function ScrollArrow() {
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400){
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400){
        setShowScroll(false)
      }
    };
    
    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    window.addEventListener('scroll', checkScrollTop)    

    return (
        <Content>
          <div 
              className="scroll-top justify-content-center"
              onClick={scrollTop} 
              style={{display: showScroll ? 'flex' : 'none'}}
          >
            <img
              alt="icon" 
              src={iconGoUp} 
              width="17" 
              height="17" 
              className="m-auto"
            /> 
            {/* <svg 
                viewBox="0 0 32 32" 
                className="scroll-icon"
                aria-hidden="true" 
            >
                <path d={"M15.997 13.374l-7.081 7.081L7 18.54l8.997-8.998 9.003 9-1.916 1.916z"}/>
            </svg> */}
          </div>
        </Content>
    )
}
const Content = styled.div`
.scroll-top {
    position: fixed;
    background:  var(--primary-color);
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    z-index: 11;
    cursor: pointer;
    transition: background 0.5s;
}
.scroll-icon {
    fill: currentColor;
    margin: auto;
    width: 1.7em;
    height: 1.7em;
}
`