import React from "react";
import {
  CRow,
  CCol,
} from '@coreui/react'
import styled from 'styled-components'

export default function UltimasPiezasAgregadasRows({
  item,
  index,
  total,
  handleClickShowBidi,
  //traducciones
  translation={},
  language="",
}) {


    //con esto devolvemos el nombre del propietario
    const returnNombrePropietario = (obj) => {
      let newObj = obj.find( newItem => newItem.vigencia) ? obj.find( newItem => newItem.vigencia) : null
      let name = newObj ? `${newObj?.nombre_propietario ? newObj?.nombre_propietario : ''} ${newObj?.apellido_propietario ? newObj?.apellido_propietario : ''}` : ''
      return name;
    }

    return (
            <Content>
              <div className={`bg-custom d-lg-none`}> 
                  <CRow className="py-2 px-2 px-sm-3 d-flex align-items-center">               
                      <CCol xs="12">
                      {`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}: {item.codigo_pieza ? item.codigo_pieza : ""}
                      </CCol>
                  </CRow>
              </div> 
              <CRow className="py-2 px-2 px-sm-3 d-flex align-items-center">
                <CCol lg={2} className="d-none d-lg-block">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.codigo_pieza ? translation[language]?.codigo_pieza : "Código de pieza"}`}: </span>
                  {item.codigo_pieza ? item.codigo_pieza : ""}
                </CCol>
                <CCol lg={2} className="px-lg-0">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.codigo_invitacion ? translation[language]?.codigo_invitacion : "Código de invitación"}`}: </span>
                  {item.codigo_invitacion ? item.codigo_invitacion : ""}
                </CCol> 
                <CCol lg={1} className="d-block">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.numero_pieza ? translation[language]?.numero_pieza : "Número de pieza"}`}: </span>
                  {item.numero_pieza ? item.numero_pieza : ""}
                </CCol> 
                <CCol lg={3} className="d-block">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.url ? translation[language]?.url : "URL"}`}: </span>
                  {/* <a href={`${item.url ? item.url : ""}`} className="primary-text-color">{item.url ? item.url : ""}</a> */}
                  <span 
                    className=""
                  >
                    {item.url ? item.url : ""}
                  </span>                
                </CCol>             
                <CCol lg={1}>
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.bidi ? translation[language]?.bidi : "BIDI"}`}: </span>
                  <span 
                    className="text-decoration-underline cursor-pointer"
                    onClick={()=> handleClickShowBidi(item)}
                  >
                    
                    {`${translation[language]?.bidi_ver ? translation[language]?.bidi_ver : "Ver"}`}
                  </span>
                </CCol> 
                <CCol lg={3}>
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.propietario ? translation[language]?.propietario : "Propietario"}`}: </span>
                  {item?.propietario_pieza?.length > 0 ? returnNombrePropietario(item.propietario_pieza) : ""}
                </CCol>      
              </CRow>
              {index+1 !== total && (
                  <hr className="mt-0 mb-0 primary-color"/>
              )}     
            </Content>
    )
}
const Content = styled.div`
`
