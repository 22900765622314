import React from "react";
import Select from 'react-select'

export default function CustomSelect({
  options,
  nombre,
  placeholder,
  isSearchable=false,
  handleChange,
  valor,
  error,
  styles,
  optionLabel="label",
  optionValue="value",
  noOptionsMessage="No options",
}) {

    // const [newValue, setnewValue] = useState(valor)

    const onSelectChange = value => {
      let target = {
        target:{
          value: value[optionValue],
          name: nombre,
        }
      }
      // setnewValue(value[optionValue])    
      handleChange(target)
    };

    //estilos
    const customSelectStyles = {
      placeholder: (provided) => {
        return {
            ...provided,
            color: `var(--primary-color)`,
        }
      },
      option: (provided, state) => ({
          ...provided,
          color: state.isSelected
          ? 'white'
          : state.isFocused
          ? 'white'
          : `var(--primary-color)`,
          fontSize: styles?.fontSize ? styles?.fontSize : `var(--font-size)`,
          backgroundColor: state.isSelected
          ? '#beb083'
          : state.isFocused
          ? '#beb083'
          : null,
          "&:active": {
            backgroundColor: '#beb083',
          }
      }),
      control: (provided, state) => ({
          ...provided,
          fontSize: styles?.fontSize ? styles?.fontSize : `var(--font-size)`,
          width: styles?.width ? styles?.width : '100%',
          margin: '0px',
          height: 'calc(1.5em + .5rem + 2px)',
          minHeight: 'calc(1.5em + .5rem + 2px)',
          lineHeight: '1',
          borderColor: error ? `var(--error-color)` : `var(--primary-color)`,
          borderRadius: '0px',
          boxShadow: 'none',
          '&:hover': {
              borderColor: error ? `var(--error-color)` : `var(--primary-color)`,
          }
      }),
      singleValue: provided => ({
          ...provided,
          color: `var(--primary-color)`
      }),
      valueContainer: provided => ({
          ...provided,
          height: 'calc(1.5em + .5rem + 2px)',
      }),
      indicatorSeparator: state => ({
          display: 'none',
      }),
      indicatorsContainer: provided => ({
          ...provided,
          height: 'calc(1.5em + .5rem + 2px)',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        "svg": {
          fill: `var(--primary-color)`,
        }
      }),
    };

    return (
            <Select 
              styles={customSelectStyles}
              getOptionLabel={option => option[optionLabel]}
              getOptionValue={option => option[optionValue]}
              noOptionsMessage={() => `${noOptionsMessage}`}
              options={options} 
              name={nombre}
              placeholder={placeholder}
              isSearchable={isSearchable}
              onChange={onSelectChange}
              value={options.filter((x) => x[optionValue] === valor)}                                           
            />           
    );
}
