import React from "react";
import {
  CCardBody,
  CContainer,
  CRow,
  CCol,
  CCardHeader,
  CSpinner,
} from '@coreui/react'
import styled from 'styled-components'
import {
  Card, 
  Card2,
  Button,
} from '../../../hooks/styled'
import Header from "../../../hooks/Header";
import Pagination from "../../../hooks/Paginacion";
import ArtistListRows from './ArtistListRows';
import infoIcon from '../../../icons/Icon ionic-md-information-circle-outline.svg'

export default function CardContent({
  data,
  dataConfig,
  getData,
  loadingData,
  setDataConfig,
  goToAgregarItem,
  handleChangeFiltros,
  goToEditarItem,
  handleBack,
  locationData,
  handleSelectItem,
  handleClickSelect,
  paises,
  itemsSelected,
  handleDeleteItem,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat,
}) {

    //con esto retornamos las filas
    const RenderData = (registro) => {
      return (
        <>
          {registro.map((item, i) => {
            return (
              <ArtistListRows
                key={i}
                item={item}
                total={registro.length}
                index={i}
                goToEditarItem={goToEditarItem}
                handleDeleteItem={handleDeleteItem}
                locationData={locationData}
                handleSelectItem={handleSelectItem}
                paises={paises}
                itemsSelected={itemsSelected}
                //traducciones
                translation={translation}
                language={language}   
                returnLanguageDateFormat={returnLanguageDateFormat}  
              />
            );
          })}
        </>
      );
    };

    return (
            <Content>
              <Card className="shadow border-0"> 
                <CCardBody className="p-0 shadow-sm">               
                  <CContainer className="px-2 px-sm-4">
                    <Header
                      title={`${translation[language]?.titulo_administrar_artistas ? translation[language]?.titulo_administrar_artistas : "Administrar Artistas"}`}
                      isBack={locationData?.from ? true : false}
                      handleBack={handleBack}
                      isSearchAndFilter={true}
                      isFilter={false}
                      dataConfig={dataConfig}
                      setDataConfig={setDataConfig}
                      handleChangeFiltros={handleChangeFiltros}
                      placeholderSearch={`${translation[language]?.search_artistas ? translation[language]?.search_artistas : "Buscar Artistas"}`}
                      isButton={locationData?.from ? false : true}
                      buttonName={`${translation[language]?.boton_agg_artista ? translation[language]?.boton_agg_artista : "+ Artista"}`}
                      ButtonFunction={goToAgregarItem}
                      showValuesText={`${translation[language]?.mostrar ? translation[language]?.mostrar : "Mostrar"}`}
                      filterByText={`${translation[language]?.filtrar_por ? translation[language]?.filtrar_por : "Filtrar por"}`}
                      selectText={`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                      noOptionsMessage={`${translation[language]?.sin_opciones ? translation[language]?.sin_opciones : "Sin resultados"}`}
                    /> 
                    <Card2 className="border-0 pb-4">
                      <CCardHeader className="border-bottom-0 bg-color rounded-0 font-size-12 text-white d-none d-lg-block">
                        <CRow className="py-2">
                          {locationData?.selection && (
                            <div className="col-lg-0-5"></div>
                          )}
                          <CCol lg={3} className="">{`${translation[language]?.artista ? translation[language]?.artista : 'Artista'}`}</CCol>
                          <CCol lg={2}>{`${translation[language]?.seudonimo ? translation[language]?.seudonimo : "Seudónimo"}`}</CCol>
                          <CCol lg={2} className="px-2">{`${translation[language]?.pais_nacimiento ? translation[language]?.pais_nacimiento : "País de nacimiento"}`}</CCol>
                          <CCol lg={2} className="px-1">{`${translation[language]?.ciudad_nacimiento ? translation[language]?.ciudad_nacimiento : "Ciudad de nacimiento"}`}</CCol>
                          <CCol lg={2} className="px-2">{`${translation[language]?.fecha_nacimiento ? translation[language]?.fecha_nacimiento : "Fecha de nacimiento"}`}</CCol>
                          <div className={`${locationData?.selection ? 'col-lg-0-5' : 'col-lg-1'}`}></div>
                        </CRow>
                      </CCardHeader>
                      <CCardBody className="py-0 px-0 border border-color font-size-12">
                        {data.length > 0 && !loadingData && paises.length > 0 ?  
                        (
                          <>{RenderData(data)}</>
                        ) 
                        : 
                        (
                            <CContainer className="text-start even ps-4 mb-5">  
                              <CRow>               
                                <CCol xs={12} className={`d-flex align-items-center mb-3 mt-3 ${loadingData && "justify-content-center"}`}>
                                  {loadingData || paises.length < 0 || data.length < 0 ? 
                                    (
                                      <div className="text-center">
                                        <CSpinner size="sm"/>
                                          <CSpinner size="sm" variant="grow"/>
                                          <div>{translation[language]?.msg_cargando ? translation[language]?.msg_cargando : 'Cargando...'}</div>
                                      </div>
                                    )
                                  :
                                    (
                                      <>
                                        <div className="me-2">
                                          <img
                                            src={infoIcon}
                                            alt=""
                                            width="18"
                                          />
                                        </div>
                                        <span 
                                            className={`font-size-14`}
                                        >
                                          {dataConfig?.searchTerm === "" ? `${translation[language]?.msg_error_artistas ? translation[language]?.msg_error_artistas : 'Aún no existen artistas registrados.'}` : `${translation[language]?.msg_error_resultados ? translation[language]?.msg_error_resultados : 'No se encontraron resultados para la búsqueda.'}`}
                                        </span>     
                                      </>
                                    )
                                  }                              
                                </CCol>
                              </CRow>                              
                            </CContainer>
                        )
                        }
                      </CCardBody>
                    </Card2>
                    {(data.length > 0 && !loadingData) && (
                      <Pagination
                        getData={getData} // funcion para actualizar la data
                        dataConfig={dataConfig} // data que almacena toda la configuracion de busqueda, paginacion y filtrado
                        setDataConfig={setDataConfig}
                        ifGetData={true} // indica si obtenemos la data con endpoint
                        //traducciones
                        translation={translation}
                        language={language}
                      />
                    )}
                    {(data.length > 0 && !loadingData && locationData?.selection) && (
                      <>
                        <hr className='mb-0 primary-color'/>
                        <CRow className="d-flex align-items-center">
                          <CCol md={6} className="d-flex align-items-center">
                              <img
                                src={infoIcon}
                                alt=""
                                width="18"
                              />
                            <div
                                className={`ms-2 font-size-12`}
                            >
                              {`${translation[language]?.hint_seleccionar_artistas ? translation[language]?.hint_seleccionar_artistas : "Seleccione los Artistas involucrados en la creación"}`}
                            </div>     
                          </CCol>  
                          <CCol md={6} className="d-flex align-items-center justify-content-md-end mb-3">
                            <div className="mt-4 ">
                              <Button 
                              type="button"
                              onClick={()=> handleClickSelect(false)}
                              >
                                {`${translation[language]?.seleccionar ? translation[language]?.seleccionar : "Seleccionar"}`}
                              </Button>                    
                            </div>                                   
                          </CCol>                       
                        </CRow>
                      </>
                    )}

                  </CContainer>
                </CCardBody>  
              </Card>  
            </Content>
    )
}
const Content = styled.div`
`
