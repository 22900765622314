import React from "react";
import {
  CRow,
  CCol,
  CTooltip
} from '@coreui/react'
import styled from 'styled-components'
import pencilIcon from '../../../icons/pencil.svg'
import borrarIcon from '../../../icons/Icono borrar - dorado.svg'
import {
  FormCheck
} from '../../../hooks/styled'
import { globalFunctions } from "../../../hooks/globalFunctions";

export default function ArtistListRows({
  item,
  index,
  total,
  goToEditarItem,
  locationData,
  handleSelectItem,
  handleDeleteItem,
  paises,
  itemsSelected,
  //traducciones
  translation={},
  language="",
  returnLanguageDateFormat,
}) {

    const { 
      findObjFromArray,
      propertyTypeOf
    } = globalFunctions();

    const getDefaultChecked = () => {
      let indice = itemsSelected.length > 0 ? itemsSelected.findIndex( s => s.id_artista === item.id_artista) : -1
      let boo = indice >=0 ? true : false
      return boo;
    }

    return (
            <Content>
              <div className={`bg-color d-lg-none`}> 
                  <CRow className="py-2 px-2 px-sm-3">               
                      <CCol className="text-white" xs="12">
                        {item.nombre_apellido ? item.nombre_apellido : ''}
                          
                      </CCol>
                  </CRow>
              </div> 
              <CRow className="py-2 px-2 px-sm-3">
                {locationData?.selection && (
                  <div className="col-lg-0-5">
                    <FormCheck 
                      className="" 
                      label=""
                      name="remember_me"
                      defaultChecked={getDefaultChecked()} 
                      onChange={(e) => handleSelectItem(e, item)}
                    />                    
                  </div>
                )}
                <CCol lg={3} className="ps-0 d-none d-lg-inline-flex">             
                  <div className="ms-2">{item.nombre_apellido ? item.nombre_apellido : ''}</div>
                </CCol>
                <CCol lg={2}>
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.seudonimo ? translation[language]?.seudonimo : "Seudónimo"}`}: </span>
                  {item.seudonimo ? `${item.seudonimo}` : ''}
                </CCol>
                <CCol lg={2} className="px-lg-2">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.pais_nacimiento ? translation[language]?.pais_nacimiento : "País de nacimiento"}`}: </span>
                  {item.pais_nacimiento ? (findObjFromArray(paises, "indice", item.pais_nacimiento)[propertyTypeOf(paises.length === 0 ? 'undefined' : paises[0][`pais_idioma_${language}`]) === 'Undefined' ? `pais_idioma_1` : `pais_idioma_${language}`]) : ''}
                </CCol>
                <CCol lg={2} className="px-lg-1">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.ciudad_nacimiento ? translation[language]?.ciudad_nacimiento : "Ciudad de nacimiento"}`}: </span>
                  {item.ciudad_nacimiento ? item.ciudad_nacimiento : ''}
                </CCol>
                <CCol lg={2} className="px-lg-2">
                  <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.fecha_nacimiento ? translation[language]?.fecha_nacimiento : "Fecha de nacimiento"}`}: </span>
                  {item.fecha_nacimiento ? returnLanguageDateFormat(item.fecha_nacimiento, "P") : ""}
                </CCol>        
                {!locationData?.selection && (
                  <div className ={`${locationData?.selection ? 'd-flex col-lg-0-5 ps-lg-0 ' : 'd-lg-flex col-lg-1 justify-content-lg-end'}`}>
                    <div className="me-0 mb-2 mb-lg-0 me-lg-2">
                      <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}: </span>
                      <CTooltip content={`${translation[language]?.hint_editar ? translation[language]?.hint_editar : "Editar"}`}>
                        <img 
                          className='icon-border' 
                          src={pencilIcon}  
                          alt="icon"
                          width="25px"
                          height="25px"
                          onClick={()=> goToEditarItem(item)}
                        />
                      </CTooltip>
                    </div>
                    <div>
                      <span className={`d-inline-flex d-lg-none me-1`}>{`${translation[language]?.hint_eliminar ? translation[language]?.hint_eliminar : "Eliminar"}`}: </span>
                      <CTooltip content={`${translation[language]?.hint_eliminar ? translation[language]?.hint_eliminar : "Eliminar"}`}>
                        <img 
                          className='icon-border' 
                          src={borrarIcon}  
                          alt="icon"
                          width="25px"
                          height="25px"
                          onClick={()=> handleDeleteItem(item.id_artista)}
                        />
                      </CTooltip>
                    </div>
                  </div>
                )}
              </CRow>
              {index+1 !== total && (
                  <hr className="mt-0 mb-0 primary-color"/>
              )}     
            </Content>
    )
}
const Content = styled.div`
`
