import React from 'react'
import styled from 'styled-components'

export default function Portal() {
    return (
            <Content> 
            </Content>
    )
}


const Content = styled.div`
`